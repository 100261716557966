import apiConfig from '@api/core/config';
import request from '@api/core/request';
import {
  AddressRequest,
  CreatePublicAccountRequest,
  CreatePublicAccountSuccess,
  FCMToken,
  NewMessageTicketRequest,
  NewTicketRequest,
  Ticket,
  TicketMessage,
  UpdateTicketMessage,
  UpdateTicketRequest,
  User,
  UserRequest,
} from '@api/models/UserModel';

export class UserService {
  /**
   * Returns and updates the current user's profile. Supports GET and PUT.
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns User
   * @throws ApiError
   */
  public static userRetrieve(forceUpdate?: boolean): Promise<User> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/user/',
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Returns and updates the current user's profile. Supports GET and PUT.
   * @param requestBody
   * @returns User
   * @throws ApiError
   */
  public static userUpdate(requestBody: UserRequest): Promise<User> {
    return request(apiConfig, {
      method: 'PUT',
      url: '/apiarist/api/user/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Saves the client's FCM token. The request must include the timestamp of the moment the token was created or refreshed.
   * The timestamp can be eighter an integer timestamp or a floating-point timestamp, or a date-time in YYYY-MM-DDTHH:MM:SS format.
   * The request must also inlcude a unique ID of the client's device. On the backend, the record is identified by the client ID, therefore,
   * for a given ID, the token and the timestamp can be updated via this endpoint.
   * @param requestBody
   * @returns FCMToken
   * @throws ApiError
   */
  public static userFcmTokenCreate(requestBody: FCMToken): Promise<FCMToken> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/user/fcm-token/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  public static createPublicProfile(requestBody: CreatePublicAccountRequest): Promise<CreatePublicAccountSuccess> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/user/public-profiles/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  public static publicProfileList(forceUpdate?: boolean): Promise<Array<User>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/user/public-profiles/',
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  public static deletePublicProfile(username: string): Promise<string> {
    return request(apiConfig, {
      method: 'DELETE',
      url: '/apiarist/api/user/public-profiles/{username}/',
      path: {
        username,
      },
    });
  }

  public static userAddress(forceUpdate?: boolean): Promise<AddressRequest> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/user/address/',
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  public static updateUserAddress(requestBody: AddressRequest): Promise<AddressRequest> {
    return request(apiConfig, {
      method: 'PUT',
      url: '/apiarist/api/user/address/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  public static userTickets(forceUpdate?: boolean): Promise<Array<Ticket>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/user/tickets/',
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  public static createUserTicket(requestBody: NewTicketRequest): Promise<Ticket> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/user/tickets/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  public static userTicket(id: number, forceUpdate?: boolean): Promise<Ticket> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/user/tickets/{id}/',
      path: {
        id,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  public static updateUserTicket(id: number, requestBody?: UpdateTicketRequest): Promise<Ticket> {
    return request(apiConfig, {
      method: 'PUT',
      url: '/apiarist/api/user/tickets/{id}/',
      path: {
        id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  public static userTicketMessages(id: number, forceUpdate?: boolean): Promise<Array<TicketMessage>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/user/tickets/{id}/messages/',
      path: {
        id,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  public static createUserTicketMessage(id: number, requestBody?: NewMessageTicketRequest): Promise<TicketMessage> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/user/tickets/{id}/messages/',
      path: {
        id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  public static userTicketMessage(id: number, msgId: number, forceUpdate?: boolean): Promise<TicketMessage> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/user/tickets/{id}/messages/{msg_id}/',
      path: {
        id,
        msg_id: msgId,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  public static updateUserTicketMessage(
    id: number,
    msgId: number,
    requestBody?: UpdateTicketMessage,
  ): Promise<UpdateTicketMessage> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/user/tickets/{id}/messages/{msg_id}/',
      path: {
        id,
        msg_id: msgId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}

export default UserService;
