import { useLocation } from 'react-router';

const withLocation = (Component: any) => {
  return (props: any) => {
    const location = useLocation();
    return (
      <Component
        location={location}
        {...props}
      />
    );
  };
};

export default withLocation;
