import type {
  TokenObtainPair,
  TokenObtainPairRequest,
  TokenRefresh,
  TokenRefreshRequest,
  TokenVerifyRequest,
} from '@api/models/TokenModel';
import apiConfig from '@api/core/config';
import request from '@api/core/request';

export class TokenService {
  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   * @param requestBody
   * @returns Access and refresh tokens
   * @throws ApiError
   */
  public static tokenCreate(requestBody: TokenObtainPairRequest): Promise<TokenObtainPair> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/token/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   * @param requestBody
   * @returns Access token
   * @throws ApiError
   */
  public static tokenRefreshCreate(requestBody: TokenRefreshRequest): Promise<TokenRefresh> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/token/refresh/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Takes a token and indicates if it is valid.  This view provides no
   * information about a token"s fitness for a particular use.
   * @param requestBody
   * @throws ApiError
   */
  public static tokenVerifyCreate(requestBody: TokenVerifyRequest): Promise<void> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/token/verify/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}

export default TokenService;
