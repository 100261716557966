import { SignIn, SignInRequest, SignInVerifyRequest, ResetPasswordRequest, Username } from '@api/models/SignInModel';
import { TokenObtainPair, TokenVerifyRequest } from '@api/models/TokenModel';
import apiConfig from '@api/core/config';
import request from '@api/core/request';

export class SigninService {
  /**
   * Allows new users to sign to the system. It is NOT protected by the authentization. Supports POST.
   * @param requestBody
   * @returns User credentials
   * @throws ApiError
   */
  public static signinCreate(requestBody: SignInRequest): Promise<SignIn> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/signin/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Verifies a sign-in token. If valid, returns a new set of access and refresh tokens for the verified user.
   * @param requestBody
   * @returns TokenObtainPair The token was verified. A new set of access and refresh tokens were generated for the user.
   * @throws ApiError
   */
  public static verifySignin(requestBody: SignInVerifyRequest): Promise<TokenObtainPair> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/verify-signin/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `The token was not found/has expired/has already been used.`,
      },
    });
  }

  /**
   * Verifies a password reset token.
   * @param requestBody
   * @returns any The token was verified.
   * @throws ApiError
   */
  public static verifyResetPaswd(requestBody: TokenVerifyRequest): Promise<any> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/verify-reset-paswd/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `The token was not found/has expired/has already been used.`,
      },
    });
  }

  /**
   * Sends an email with password reset token to the provided username - email address.
   * @param requestBody
   * @returns any The token was sent.
   * @throws ApiError
   */
  public static sendResetPaswd(requestBody: Username): Promise<any> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/send-reset-passwd/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `The user does not exist.`,
      },
    });
  }

  /**
   * Sets a new password for the provided user, if and only if the token is still valid.
   * @param requestBody
   * @returns ResetPasswordRequest The user\'s password was set to the given value.
   * @throws ApiError
   */
  public static resetPaswd(requestBody: ResetPasswordRequest): Promise<ResetPasswordRequest> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/reset-passwd/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid request.`,
        404: `The user does not exist or the token is invalid/does not exist.`,
      },
    });
  }
}

export default SigninService;
