import { DeviceComplete } from '@api/models/DeviceModel';
import { Location } from '@api/models/LocationModel';
import { User } from '@api/models/UserModel';
import { Capacitor } from '@capacitor/core';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import Table from '@components/Table';
import { TableColumn } from '@components/Table/typeDefs';
import { AlertInput, IonAlert, IonFab, IonFabButton, IonFabList, IonIcon } from '@ionic/react';
import { SensorEnum } from '@lib/data/Device/typeDefs';
import BaseUtils from '@util/BaseUtils';
import { downloadOutline as DownloadIcon, ellipsisVertical as EllipsisVerticalIcon } from 'ionicons/icons';
import moment from 'moment';
import * as React from 'react';
import * as XLSX from 'xlsx';
import styles from './styles.module.css';

interface TableTabProps {
  hive: DeviceComplete;
  location: Location;
  sensorTelemetry: any;
  user: User;
}
interface TableTabState {
  exportAlertOpen: boolean;
}

const cols: TableColumn[] = [
  { name: 'datetime', fieldName: 'Datum a čas', size: '3' },
  { name: SensorEnum.HIVE_WEIGHT.toString(), fieldName: 'Hmotnost [kg]', textAlign: 'right' },
  { name: SensorEnum.HIVE_TEMPERATURE.toString(), fieldName: 'Teplota v úlu [°C]', textAlign: 'right' },
  { name: SensorEnum.HIVE_HUMIDITY.toString(), fieldName: 'Vlhkost v úlu [%]', textAlign: 'right' },
  { name: SensorEnum.LOCATION_TEMPERATURE.toString(), fieldName: 'Teplota na stanovišti [°C]', textAlign: 'right' },
];

const exportFormatInputs: AlertInput[] = [
  {
    label: 'CSV',
    type: 'radio',
    value: 'csv',
    checked: true,
  },
  {
    label: 'Excel',
    type: 'radio',
    value: 'excel',
  },
];

export class TableTab extends React.Component<TableTabProps, TableTabState> {
  constructor(props: TableTabProps) {
    super(props);

    this.handleExportClick = this.handleExportClick.bind(this);

    this.state = {
      exportAlertOpen: false,
    };
  }

  getFilename(mimeType: string) {
    let hiveName = this.props.hive.frontend_settings?.custom_name || '';
    let locationName = this.props.location.name || '';
    hiveName = BaseUtils.normalizeString(hiveName).replace(/\s+/g, '-').toLowerCase();
    locationName = BaseUtils.normalizeString(locationName).replace(/\s+/g, '-').toLowerCase();
    const datetime = moment().format('DD-MM-YYYY_HH-mm-ss');

    let filename = `data_${datetime}`;

    if (hiveName.length > 0) {
      filename += `_${hiveName}`;
    }

    if (locationName.length > 0) {
      filename += `_${locationName}`;
    }

    filename += `.${mimeType}`;
    return filename;
  }

  getExportData() {
    const [tableCols, tableRows] = this.getTableConfiguration();
    const data: any = [];

    // Add headers
    data.push(tableCols.map((col) => col.fieldName));

    // Add data
    data.push(
      ...tableRows.map((row) =>
        tableCols.map((col) => {
          const val = row[col.name];

          if (isNaN(Number(val))) {
            return val;
          }

          return val.replace('.', ',');
        }),
      ),
    );

    return data;
  }

  exportCSV() {
    const data = this.getExportData();
    const filename = this.getFilename('csv');
    const csvContent = data.map((e: any) => e.join(';')).join('\n');

    if (Capacitor.getPlatform() !== 'web') {
      Filesystem.writeFile({
        path: filename,
        data: csvContent,
        directory: Directory.Cache,
        encoding: Encoding.UTF8,
      }).then((savedFile) => {
        Share.share({ url: savedFile.uri });
      });
    } else {
      const path = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csvContent)}`;
      const link = document.createElement('a');
      link.download = filename;
      link.target = '_blank';
      link.href = path;
      link.click();
    }
  }

  exportExcel() {
    const data = this.getExportData();
    const filename = this.getFilename('xlsx');

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, data);
    XLSX.utils.book_append_sheet(wb, ws, 'Tabulka');

    if (Capacitor.getPlatform() === 'web') {
      XLSX.writeFile(wb, filename);
    }
  }

  handleExportClick() {
    this.setState({ exportAlertOpen: true });
  }

  getDatetime(timestamp: number) {
    return moment(timestamp).format('DD.MM.YYYY HH:mm');
  }

  getSensorValue(sensorNumber: number, timestamp: number) {
    const sensorDataIndex = this.props.sensorTelemetry[sensorNumber].findIndex((point: any) => point.x === timestamp);

    if (sensorDataIndex < 0) {
      return '-';
    }

    const sensorData = this.props.sensorTelemetry[sensorNumber][sensorDataIndex];
    return sensorData.y.toFixed(1);
  }

  getTableConfiguration(): [cols: TableColumn[], rows: Record<string, any>[]] {
    const showSensors = [
      SensorEnum.HIVE_WEIGHT.toString(),
      SensorEnum.HIVE_HUMIDITY.toString(),
      SensorEnum.HIVE_TEMPERATURE.toString(),
      SensorEnum.LOCATION_TEMPERATURE.toString(),
    ];
    const enabledSensors: string[] = [];
    const rows: Record<string, any>[] = [];
    const frontendSettings = this.props.user.frontend_settings || {};
    const hiddenSensors = Array.isArray(frontendSettings.hidden_sensors) ? frontendSettings.hidden_sensors : [];

    if (Object.keys(this.props.sensorTelemetry).length <= 0) {
      return [cols, rows];
    }

    let maxPoints = 0;
    let maxKey;

    for (const sensorNumber of showSensors) {
      if (hiddenSensors.indexOf(parseInt(sensorNumber)) >= 0) {
        continue;
      }

      if (
        this.props.hive.isGroupItem &&
        [SensorEnum.HIVE_TEMPERATURE, SensorEnum.HIVE_HUMIDITY].indexOf(parseInt(sensorNumber)) >= 0
      ) {
        continue;
      }

      const sensorData = this.props.sensorTelemetry[sensorNumber];

      if (!sensorData) {
        continue;
      }

      if (sensorData.length > 0) {
        enabledSensors.push(sensorNumber);
      }

      if (sensorData.length > maxPoints) {
        maxPoints = sensorData.length;
        maxKey = sensorNumber;
      }
    }

    for (let i = 0; i < maxPoints; i++) {
      const timestamp = this.props.sensorTelemetry[maxKey as string][i].x;

      const row: Record<string, any> = {
        datetime: this.getDatetime(timestamp),
      };

      for (const enabledSensor of enabledSensors) {
        row[enabledSensor] = this.getSensorValue(parseInt(enabledSensor), timestamp);
      }

      rows.push(row);
    }

    const filteredCols = cols.filter((col) => col.name === 'datetime' || enabledSensors.indexOf(col.name) >= 0);
    return [filteredCols, rows.reverse()];
  }

  renderFabs() {
    return (
      <IonFab
        className={styles.fabButton}
        slot="fixed"
        vertical="bottom"
        horizontal="end"
      >
        <IonFabButton>
          <IonIcon icon={EllipsisVerticalIcon}></IonIcon>
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton
            onClick={this.handleExportClick}
            data-desc="Exportovat data"
          >
            <IonIcon
              color="dark"
              icon={DownloadIcon}
            ></IonIcon>
          </IonFabButton>
        </IonFabList>
      </IonFab>
    );
  }

  renderExportAlert() {
    return (
      <IonAlert
        isOpen={this.state.exportAlertOpen}
        header="Export dat"
        message="Vyberte formát"
        inputs={
          Capacitor.getPlatform() === 'web'
            ? exportFormatInputs
            : exportFormatInputs.filter((input) => input.value !== 'excel')
        }
        onDidDismiss={() => this.setState({ exportAlertOpen: false })}
        buttons={[
          {
            text: 'Zrušit',
            role: 'cancel',
          },
          {
            text: 'Pokračovat',
            role: 'confirm',
            handler: (value) => {
              if (value === 'csv') {
                this.exportCSV();
                return;
              }

              this.exportExcel();
            },
          },
        ]}
      />
    );
  }

  render() {
    const [tableCols, tableRows] = this.getTableConfiguration();

    return (
      <div className={styles.tableTab}>
        <div className={styles.tableContainer}>
          <Table
            className={styles.table}
            cols={tableCols}
            rows={tableRows}
          />
        </div>
        {this.renderFabs()}
        {this.renderExportAlert()}
      </div>
    );
  }
}

export default TableTab;
