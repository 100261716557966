import UserService from '@api/services/UserService';
import { Device } from '@capacitor/device';
import { ActionPerformed, PushNotifications } from '@capacitor/push-notifications';
import moment from 'moment';

const removeListeners = async () => {
  await PushNotifications.removeAllListeners();
};

const addListeners = async () => {
  await PushNotifications.addListener('registration', async (token) => {
    const data = {
      token: token.value,
      client_id: (await Device.getId()).identifier,
      timestamp: moment().toISOString(),
    };

    await UserService.userFcmTokenCreate(data);
  });

  await PushNotifications.addListener('registrationError', (err) => {
    // console.log('Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', (notification) => {
    // console.log('Push notification received: ', notification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
    // console.log('Push notification action performed', notification.actionId, notification.inputValue);
  });
};

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    // throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
};

export { addListeners, removeListeners, registerNotifications };
