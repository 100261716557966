import { DeviceComplete } from '@api/models/DeviceModel';
import { Location } from '@api/models/LocationModel';
import HiveShape from '@components/HiveShape';
import Icon from '@components/Icon';
import { IonCol, IonRow, IonText } from '@ionic/react';
import getConnectedDevices from '@lib/data/Device/getConnectedDevices';
import getSensorValue, { getHiveBatteryValue, getHiveLocationValue } from '@lib/data/Device/getSensorValue';
import { INACTIVE_SENSOR_COLOR, SensorEnum } from '@lib/data/Device/typeDefs';
import HiveSvg from '@pages/Hive/img/hive';
import HiveGroupSvg from '@pages/Hive/img/hive_group';
import StateList from '@pages/Hive/LeftSidebar/StateList';
import moment from 'moment';
import * as React from 'react';
import styles from './styles.module.css';

interface LeftSidebarProps {
  hive: DeviceComplete;
  devices: DeviceComplete[];
  location: Location;
  telemetry: any;
  onStateUpdate?: () => void;
}

interface LeftSidebarState {}

export class LeftSidebar extends React.Component<LeftSidebarProps, LeftSidebarState> {
  getUpdateDate() {
    let timestamp = 0;

    for (const item of this.props.telemetry) {
      if (item[0] > timestamp) {
        timestamp = item[0];
      }
    }

    if (timestamp <= 0) {
      return;
    }

    return moment(timestamp).format('DD.MM.YYYY - HH:mm');
  }

  renderHiveInfo() {
    const hiveName = this.props.hive.frontend_settings?.custom_name || '';
    const hiveColor = this.props.hive.frontend_settings?.color || '#fff';
    const locationName = this.props.location.name || '';
    const connectedDevices = getConnectedDevices(this.props.hive, this.props.devices);

    return (
      <div
        className={styles.content}
        style={{
          borderLeft: `6px solid ${hiveColor}`,
        }}
      >
        <IonRow>
          <IonCol className={styles.hiveInfoRow}>
            <HiveSvg />
            <IonText className={styles.hiveInfoTitle}>{hiveName}</IonText>
            {connectedDevices.map((device, index) => (
              <Icon
                key={index}
                iconName={device.icon as any}
                size={18}
                color={device.color}
              />
            ))}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className={styles.hiveInfoRow}>
            <HiveGroupSvg />
            <IonText className={styles.hiveInfoTitle}>{locationName}</IonText>
          </IonCol>
        </IonRow>
      </div>
    );
  }

  renderSensor(number: SensorEnum, noValue: boolean = false) {
    let sensor = getSensorValue(number, this.props.telemetry, this.props.hive.sensors || []);
    let textValue: React.ReactNode = sensor.formatValue;

    if (number === SensorEnum.HIVE_WEIGHT) {
      const weightGain = getSensorValue(SensorEnum.WEIGHT_GAIN, this.props.telemetry, this.props.hive.sensors || []);
      const weightGain2 = this.props.hive.weightGain;

      if (weightGain)
        textValue = (
          <React.Fragment>
            {textValue}{' '}
            <span style={{ fontSize: '14px' }}>
              (<span style={{ color: weightGain.color }}>{weightGain.formatValue}</span>
              {weightGain2 && <span style={{ color: weightGain2.color }}>{`, ${weightGain2.formatValue}`}</span>})
            </span>
          </React.Fragment>
        );
    }

    if (number === SensorEnum.BATTERY_VOLTAGE) {
      sensor = getHiveBatteryValue(this.props.hive, this.props.devices);
    }

    if (number === SensorEnum.MOVEMENT) {
      sensor = getHiveLocationValue(this.props.hive);
      textValue = sensor.formatValue;
    }

    if (this.props.hive.isGroupItem && [SensorEnum.HIVE_TEMPERATURE, SensorEnum.HIVE_HUMIDITY].indexOf(number) >= 0) {
      sensor.color = INACTIVE_SENSOR_COLOR;
      textValue = sensor.unit ? `- ${sensor.unit}` : '-';
    }

    return (
      <div className={styles.sensorBlock}>
        <Icon
          iconName={sensor.icon as any}
          color={sensor.color}
          size={20}
        />
        {!noValue && <IonText className={styles.sensorValue}>{textValue}</IonText>}
      </div>
    );
  }

  renderHiveValues() {
    return (
      <div className={`${styles.content} ion-hide-md-up`}>
        <IonRow className="ion-align-items-center">
          <IonCol>
            <IonText className={styles.updateTitle}>Datum aktualizace</IonText>
          </IonCol>
          <IonCol size="auto">
            <IonText className={styles.updateValue}>{this.getUpdateDate() || ''}</IonText>
          </IonCol>
        </IonRow>
        <IonRow className="ion-align-items-center">
          <IonCol size="4">{this.renderSensor(SensorEnum.HIVE_TEMPERATURE)}</IonCol>
          <IonCol size="8">{this.renderSensor(SensorEnum.HIVE_WEIGHT)}</IonCol>
        </IonRow>
        <IonRow className="ion-align-items-center">
          <IonCol size="4">{this.renderSensor(SensorEnum.HIVE_HUMIDITY)}</IonCol>
          <IonCol size="4">{this.renderSensor(SensorEnum.MOVEMENT)}</IonCol>
          <IonCol size="4">{this.renderSensor(SensorEnum.BATTERY_VOLTAGE, true)}</IonCol>
        </IonRow>
      </div>
    );
  }

  renderHiveShape() {
    return (
      <div className={`${styles.shapeContent} ion-hide-md-down`}>
        <HiveShape
          hive={this.props.hive}
          devices={this.props.devices}
          telemetry={this.props.telemetry}
          {...(this.props.hive.isGroupItem && {
            inactiveSensors: [SensorEnum.HIVE_TEMPERATURE, SensorEnum.HIVE_HUMIDITY],
          })}
        />
      </div>
    );
  }

  renderStateList() {
    return (
      <div className={`${styles.content}`}>
        <StateList
          hive={this.props.hive}
          onStateUpdate={this.props.onStateUpdate}
          updateDate={this.getUpdateDate()}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={styles.leftSidebar}>
        {this.renderHiveInfo()}
        {this.renderHiveValues()}
        {this.renderHiveShape()}
        {this.renderStateList()}
      </div>
    );
  }
}

export default LeftSidebar;
