import { useHistory } from 'react-router';

const withHistory = (Component: any) => {
  return (props: any) => {
    const history = useHistory();
    return (
      <Component
        history={history}
        {...props}
      />
    );
  };
};

export default withHistory;
