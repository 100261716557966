import * as React from 'react';
import styles from './styles.module.css';

interface HiveAttachmentProps {
  size: number;
  amount: number;
}

interface HiveAttachmentState {}

const DEFAULT_HEIGHT = 30;
const DEFAULT_WIDTH = 16;

class HiveAttachment extends React.Component<HiveAttachmentProps, HiveAttachmentState> {
  static defaultProps: HiveAttachmentProps = {
    size: 1,
    amount: 0,
  };

  render() {
    const size = this.props.size > 0 ? this.props.size : 1;

    return (
      <div className={styles.hiveAttachmentContainer}>
        {Array(this.props.amount)
          .fill(0)
          .map((_, index) => (
            <div
              key={index}
              className={styles.hiveAttachment}
              style={{
                height: DEFAULT_HEIGHT * size,
                width: DEFAULT_WIDTH * size,
              }}
            ></div>
          ))}
      </div>
    );
  }
}

export default HiveAttachment;
