import TokenService from '@api/services/TokenService';
import UserService from '@api/services/UserService';
import { Capacitor } from '@capacitor/core';
import Logo from '@components/Header/logo.svg';
import withHistory from '@components/HOC/withHistory';
import withLocation from '@components/HOC/withLocation';
import Loading from '@components/Loading';
import TextField from '@components/TextField';
import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import * as pushNotifications from '@native/pushNotifications';
import { storageSet } from '@src/storage';
import store from '@src/store';
import NotificationUtil from '@util/Notification';
import Validation from '@util/Validation';
import { HttpStatusCode } from 'axios';
import { History, Location } from 'history';
import React from 'react';
import styles from '../styles.module.css';

export interface LoginProps {
  location: Location;
  history: History;
}
export interface LoginState {
  loading: boolean;
  formLoading: boolean;
  formData: Record<string, any>;
  formErrors: Record<string, string>;
}

const initialData = {
  username: '',
  password: '',
};

export class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleChangeFormData = this.handleChangeFormData.bind(this);

    this.state = {
      loading: false,
      formLoading: false,
      formData: initialData,
      formErrors: {},
    };
  }

  async componentDidMount() {
    const search = new URLSearchParams(this.props.location.search);
    const token = search.get('id');

    if (token === null) {
      return;
    }

    // Auto login for public users
    this.setState(
      {
        formData: {
          username: token,
          password: token,
        },
        loading: true,
      },
      async () => {
        await this.handleLoginClick(true);
      },
    );
  }

  validateLogin() {
    const formErrors: Record<string, string> = {};

    Validation.required(this.state.formData.username, 'username', formErrors);
    Validation.required(this.state.formData.password, 'password', formErrors);

    if (!formErrors.username) {
      Validation.email(this.state.formData.username, 'username', formErrors);
    }

    this.setState({
      formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  }

  async handleLoginClick(isPublicLogin: boolean = false) {
    if (!this.validateLogin()) {
      return;
    }

    if (this.state.formLoading) {
      return;
    }

    this.setState({
      formLoading: true,
    });

    try {
      const res: any = await TokenService.tokenCreate({
        username: this.state.formData.username,
        password: this.state.formData.password,
      });

      if (res.status === HttpStatusCode.Unauthorized) {
        if (isPublicLogin) {
          this.setState({
            formData: initialData,
            loading: false,
          });

          NotificationUtil.error('Veřejný účet neexistuje');
        } else {
          this.setState({
            formLoading: false,
          });
          NotificationUtil.error('Špatný email nebo heslo');
        }

        return;
      }

      const user = await UserService.userRetrieve();
      const isPublic = user.profile_type === 'Public';

      // Set to storage
      await storageSet('refreshToken', res.refresh);
      await storageSet('accessToken', res.access);
      await storageSet('isPublic', isPublic);

      // Set to store
      store.dispatch({
        type: 'set',
        payload: {
          refreshToken: res.refresh,
          accessToken: res.access,
          isPublic: isPublic,
        },
      });

      if (Capacitor.isPluginAvailable('PushNotifications')) {
        await pushNotifications.addListeners();
      }

      this.props.history.push('/home');
    } catch (e) {
      this.setState({
        formLoading: false,
      });

      NotificationUtil.error('Neočekávaná chyba během přihlášení');
    }
  }

  handleChangeFormData(e: any) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  renderLoginForm() {
    return (
      <React.Fragment>
        <IonRow>
          <IonCol>
            <TextField
              label="Email"
              placeholder="example@seznam.cz"
              labelPlacement="start"
              name="username"
              required
              onIonInput={this.handleChangeFormData}
              value={this.state.formData.username}
              error={this.state.formErrors.username}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <TextField
              label="Heslo"
              placeholder="Zadejte heslo"
              labelPlacement="start"
              name="password"
              type="password"
              required
              onIonInput={this.handleChangeFormData}
              value={this.state.formData.password}
              error={this.state.formErrors.password}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton
              expand="block"
              disabled={this.state.formLoading}
              onClick={() => this.handleLoginClick()}
            >
              Přihlásit se
            </IonButton>
          </IonCol>
        </IonRow>
      </React.Fragment>
    );
  }

  renderFooter() {
    return (
      <IonRow>
        <IonCol>
          <div className={styles.loginFooter}>
            <div
              className={styles.loginFooterItem}
              onClick={() => {
                this.props.history.push('/reset-passwd');
              }}
            >
              Změnit heslo
            </div>
            <div
              className={styles.loginFooterItem}
              onClick={() => {
                this.props.history.push('/sign-in');
              }}
            >
              Registrace
            </div>
          </div>
        </IonCol>
      </IonRow>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loading text="Probíhá přihlášení..." />;
    }

    return (
      <IonPage>
        <IonContent>
          <div className={styles.authContainer}>
            <div className={styles.authBox}>
              <div className={styles.logo}>
                <img
                  alt="app_logo"
                  src={Logo}
                />
              </div>
              <div className={styles.content}>
                <IonGrid>
                  {this.renderLoginForm()}
                  {this.renderFooter()}
                </IonGrid>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withLocation(withHistory(Login));
