import TextField from '@components/TextField';
import { IonCol, IonRow } from '@ionic/react';
import React from 'react';

const inputs = [
  {
    key: 'days',
    title: 'Dny',
  },
  {
    key: 'hours',
    title: 'Hodiny',
  },
];

interface TimePickerProps {
  data: Record<string, number | null>;
  onChange?: (data: Record<string, number | null>) => void;
}

interface TimePickerState {}

class TimePicker extends React.Component<TimePickerProps, TimePickerState> {
  constructor(props: TimePickerProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(e: any) {
    let num: number | null = Number.parseInt(e.target.value);

    if (isNaN(num)) {
      num = null;
    }

    const newData: Record<string, number | null> = {
      ...this.props.data,
      [e.target.name]: num,
    };

    if (this.props.onChange) {
      this.props.onChange(newData);
    }
  }

  render() {
    return (
      <IonRow className="ion-padding-top">
        {inputs.map((input, index) => (
          <IonCol
            key={index}
            size="12"
            sizeLg="6"
          >
            <TextField
              label={input.title}
              name={input.key}
              onIonInput={this.onChange}
              value={this.props.data[input.key] !== null ? this.props.data[input.key] : ''}
            />
          </IonCol>
        ))}
      </IonRow>
    );
  }
}

export default TimePicker;
