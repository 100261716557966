import { Capacitor } from '@capacitor/core';
import withHistory from '@components/HOC/withHistory';
import NotificationComponent from '@components/Notification';
import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import Login from '@pages/Auth/Login';
import ResetPassword from '@pages/Auth/ResetPassword';
import Signin from '@pages/Auth/Signin';
import HiveDetail from '@pages/Hive';
import { History } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import Home from '../../pages/Home';
import MobileMenu from '../../pages/MobileMenu';
import Profile from '../../pages/Profile';
import { createStorage, storageGet, storageKeys, storageSet } from '../../storage';
import store from '../../store';
import Loading from '../Loading';
import Router from '../Router';
import SideMenu from '../SideMenu';
import TabMenu from '../TabMenu';
import { RouteItem } from '../typeDefs';

const authRoutes: Array<RouteItem> = [
  {
    path: '/home',
    component: <Home />,
  },
  {
    path: '/hive/:uuid',
    component: <HiveDetail />,
  },
  {
    path: '/profile',
    component: <Profile />,
  },
  {
    path: '/menu',
    component: <MobileMenu />,
    mobileOnly: true,
  },
  {
    path: '/',
    redirect: '/home', // /home
  },
];

const guestRoutes: Array<RouteItem> = [
  {
    path: '/reset-passwd',
    component: <ResetPassword />,
  },
  {
    path: '/sign-in',
    component: <Signin />,
  },
  {
    path: '/login',
    component: <Login />,
  },
  {
    path: '/public_login',
    component: <Login />,
  },
];

export interface LayoutProps {
  accessToken?: string;
  refreshToken?: string;
  history?: History;
}

export interface LayoutState {
  mounted: boolean;
}

class Layout extends React.Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props);

    this.state = {
      mounted: false,
    };
  }

  async componentDidMount() {
    await createStorage('apivcelardb');
    const k = await storageKeys();

    if (k.length > 0) {
      store.dispatch({
        type: 'set',
        payload: {
          graph_interval: await storageGet('graph_interval_last'),
          graph_interval_last: await storageGet('graph_interval_last'),
          graph_custom_interval: await storageGet('graph_custom_interval'),
          graph_is_custom_last: await storageGet('graph_is_custom_last'),
          graph_interval_from: await storageGet('graph_interval_from'),
          graph_agg_func: 'none',
          graph_mode: 'realtime',
          graph_time: false,
          graph_time_start: null,
          graph_time_end: null,
          logged_in: await storageGet('logged_in'),
          refreshToken: await storageGet('refreshToken'),
          accessToken: await storageGet('accessToken'),
          devicesCache: await storageGet('devicesCache'),
          isPublic: await storageGet('isPublic'),
          showScales: await storageGet('showScales'),
        },
      });

      this.setState({
        mounted: true,
      });

      return;
    }

    await storageSet('graph_interval', 1000 * 60 * 60 * 24 * 7);
    await storageSet('graph_interval_last', 1000 * 60 * 60 * 24 * 7);
    await storageSet('graph_custom_interval', null);
    await storageSet('graph_is_custom_last', false);
    await storageSet('graph_interval_from', null);
    await storageSet('graph_agg_func', 'none');
    await storageSet('graph_mode', 'realtime');
    await storageSet('graph_time', false);
    await storageSet('graph_time_start', null);
    await storageSet('graph_time_end', null);
    await storageSet('logged_in', false);
    await storageSet('refreshToken', null);
    await storageSet('accessToken', null);
    await storageSet('devicesCache', null);
    await storageSet('isPublic', false);
    await storageSet('showScales', false);

    this.setState({
      mounted: true,
    });
  }

  isAuthorized() {
    return this.props.accessToken && this.props.refreshToken;
  }

  renderGuest(component: JSX.Element | undefined, props: Record<string, any>) {
    return !this.isAuthorized() ? component : <Home />;
  }

  renderAuth(component: JSX.Element | undefined, props: Record<string, any>) {
    return this.isAuthorized() ? component : <Login />;
  }

  render() {
    if (!this.state.mounted) {
      return <Loading />;
    }

    const r: Array<RouteItem> = [];

    for (const guestRoute of guestRoutes) {
      if (guestRoute.redirect) {
        r.push(guestRoute);
        continue;
      }

      guestRoute.render = (props: Record<string, any>) => this.renderGuest(guestRoute.component, props);
      r.push(guestRoute);
    }

    for (const authRoute of authRoutes) {
      if (authRoute.redirect) {
        r.push(authRoute);
        continue;
      }

      authRoute.render = (props: Record<string, any>) => this.renderAuth(authRoute.component, props);
      r.push(authRoute);
    }

    return (
      <React.Fragment>
        {Capacitor.getPlatform() !== 'web' ? (
          <React.Fragment>
            <TabMenu routes={r} />
          </React.Fragment>
        ) : (
          <IonSplitPane
            contentId="main"
            when="lg"
            disabled={true}
          >
            {this.isAuthorized() && <SideMenu />}
            <IonRouterOutlet id="main">
              <Router routes={r} />
            </IonRouterOutlet>
          </IonSplitPane>
        )}
        <NotificationComponent />
      </React.Fragment>
    );
  }
}

export default withHistory(
  connect((state: any) => {
    return {
      accessToken: state.accessToken,
      refreshToken: state.refreshToken,
    };
  })(Layout),
);
