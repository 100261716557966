import { DeviceComplete } from '@api/models/DeviceModel';
import HiveAttachment from '@components/HiveAttachment';
import { IonCol, IonRow, IonText } from '@ionic/react';
import getConnectedDevices from '@lib/data/Device/getConnectedDevices';
import getSensorValue, { getHiveBatteryValue, getHiveLocationValue } from '@lib/data/Device/getSensorValue';
import { DeviceTelemetry, INACTIVE_SENSOR_COLOR, SensorEnum } from '@lib/data/Device/typeDefs';
import Icon from '@src/components/Icon';
import * as React from 'react';
import styles from './styles.module.css';

interface HiveItemMobileProps {
  hive: DeviceComplete;
  devices: DeviceComplete[];
  telemetry: DeviceTelemetry;
  inactiveSensors: SensorEnum[];
}

interface HiveItemMobileState {}

export class HiveItemMobile extends React.Component<HiveItemMobileProps, HiveItemMobileState> {
  static defaultProps = {
    inactiveSensors: [],
  };

  renderSensor(number: SensorEnum, noValue: boolean = false) {
    let sensor = getSensorValue(number, this.props.telemetry, this.props.hive.sensors || []);
    let textValue: React.ReactNode = sensor.formatValue;

    if (number === SensorEnum.HIVE_WEIGHT) {
      const weightGain = getSensorValue(SensorEnum.WEIGHT_GAIN, this.props.telemetry, this.props.hive.sensors || []);
      const weightGain2 = this.props.hive.weightGain;

      if (weightGain)
        textValue = (
          <React.Fragment>
            {textValue}{' '}
            <div style={{ fontSize: '14px' }}>
              (<span style={{ color: weightGain.color }}>{weightGain.formatValue}</span>
              {weightGain2 && <span style={{ color: weightGain2.color }}>{`, ${weightGain2.formatValue}`}</span>})
            </div>
          </React.Fragment>
        );
    }

    if (number === SensorEnum.BATTERY_VOLTAGE) {
      sensor = getHiveBatteryValue(this.props.hive, this.props.devices);
    }

    if (number === SensorEnum.MOVEMENT) {
      sensor = getHiveLocationValue(this.props.hive);
      textValue = sensor.formatValue;
    }

    if (this.props.inactiveSensors.indexOf(number) >= 0) {
      sensor.color = INACTIVE_SENSOR_COLOR;
      textValue = sensor.unit ? `- ${sensor.unit}` : '-';
    }

    return (
      <div className={styles.sensorBlock}>
        <Icon
          iconName={sensor.icon as any}
          color={sensor.color}
          size={20}
        />
        {!noValue && <IonText className={styles.sensorValue}>{textValue}</IonText>}
      </div>
    );
  }

  render() {
    const hiveName = this.props.hive.frontend_settings?.custom_name || '';
    const hiveAttachments = this.props.hive.hive_state.attachments || 0;
    const connectedDevices = getConnectedDevices(this.props.hive, this.props.devices);

    return (
      <React.Fragment>
        <IonRow className="ion-align-items-center">
          <IonCol className={styles.itemNameBlock}>
            <IonText className={styles.itemName}>{hiveName}</IonText>
            {connectedDevices.map((device, index) => (
              <Icon
                key={index}
                iconName={device.icon as any}
                size={14}
                color={device.color}
              />
            ))}
          </IonCol>
          <IonCol size="auto">
            <HiveAttachment
              size={0.5}
              amount={hiveAttachments}
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-align-items-center">
          <IonCol size="6">{this.renderSensor(SensorEnum.HIVE_WEIGHT)}</IonCol>
          <IonCol
            size="4"
            className={styles.stackedSensors}
          >
            {this.renderSensor(SensorEnum.HIVE_TEMPERATURE)}
            {this.renderSensor(SensorEnum.HIVE_HUMIDITY)}
          </IonCol>
          <IonCol
            size="2"
            className={styles.stackedSensors}
          >
            {this.renderSensor(SensorEnum.MOVEMENT, true)}
            {this.renderSensor(SensorEnum.BATTERY_VOLTAGE, true)}
          </IonCol>
        </IonRow>
      </React.Fragment>
    );
  }
}

export default HiveItemMobile;
