import * as React from 'react';
import styles from './styles.module.css';

interface HiveFooterProps extends React.PropsWithChildren {}
interface HiveFooterState {}

export class HiveFooter extends React.Component<HiveFooterProps, HiveFooterState> {
  render() {
    return (
      <div className={styles.hiveFooter}>
        <div className={styles.hiveFooterLeft}></div>
        <div className={styles.hiveFooterContent}>{this.props.children}</div>
        <div className={styles.hiveFooterRight}></div>
      </div>
    );
  }
}

export default HiveFooter;
