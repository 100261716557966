import DeviceService from '@api/services/DeviceService';
import moment from 'moment';
import { GetTelemetryOpts, GetTelemetryResult } from './typeDefs';

export const getTelemetry = async (opts: GetTelemetryOpts) => {
  if (!opts.uuid || !opts.startTime || !opts.endTime) {
    return;
  }

  let startTime = moment(opts.startTime);
  let endTime = moment(opts.endTime);

  if (endTime.isBefore(startTime)) {
    return;
  }

  let maxRequestTime = (opts.maxRequestTime || 14) * 24 * 60 * 60; // 14 days in seconds by default
  let t = startTime.clone();
  let tDuration = moment.duration(endTime.diff(startTime)).asSeconds();
  let interval = tDuration > maxRequestTime ? maxRequestTime : tDuration;
  let i = 0;

  while (1) {
    const result: GetTelemetryResult = {};
    const from = t.toISOString();
    const to = t.add(interval, 'seconds').toISOString();
    const data = await DeviceService.devicesTelemetryList4(from, to, opts.uuid);
    let filledSensors: any = {};

    for (let i = 0; i < data.length; i++) {
      const [timestamp, sensor, val] = data[i];

      if (val !== 0) {
        filledSensors[sensor] = true;
      }

      result[sensor] = result[sensor] || [];
      result[sensor].push({ x: timestamp, y: val }); // timestamp in ms
    }

    // Remove sensors with only zero values
    for (const sensorNumber in result) {
      if (!filledSensors[sensorNumber]) {
        result[sensorNumber] = [];
      }
    }

    const diff = moment.duration(endTime.diff(t)).asSeconds();

    if (opts.cb) {
      opts.cb(result, { isLast: diff <= 0, index: i, uuid: opts.uuid });
    }

    i++;

    if (diff <= 0) {
      break;
    } else if (diff < interval) {
      interval = diff;
    }
  }
};

export default getTelemetry;
