import { IonToast } from '@ionic/react';
import { Notification as NotificationType } from '@src/store';
import NotificationUtil from '@util/Notification';
import {
  alertCircleOutline as AlertIcon,
  checkmarkCircleOutline as CheckmarkIcon,
  closeOutline as CloseIcon,
  syncOutline as SyncIcon,
} from 'ionicons/icons';
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.css';

interface NotificationProps {
  notification: NotificationType | null;
}
interface NotificationState {
  notification: NotificationType | null;
  open: boolean;
}

export class Notification extends React.Component<NotificationProps, NotificationState> {
  private notificationTimeout: any;

  constructor(props: NotificationProps) {
    super(props);

    this.handleNotificationDismiss = this.handleNotificationDismiss.bind(this);

    this.state = {
      open: false,
      notification: null,
    };
  }

  compareNotification(a: NotificationType | null, b: NotificationType | null) {
    if (a && b) {
      return a.timestamp === b.timestamp;
    }

    return a === b;
  }

  closeNotification() {
    this.setState({
      open: false,
    });
  }

  setNotification() {
    // Close notification
    if (this.props.notification === null) {
      this.closeNotification();
      return;
    }

    // Clear previous notification timeout
    clearTimeout(this.notificationTimeout);

    // Set new notification
    this.setState({
      open: true,
      notification: this.props.notification,
    });

    // Set timeout for closing notification, if type is not "loading"
    if (this.props.notification.type !== 'loading') {
      this.notificationTimeout = setTimeout(() => {
        this.closeNotification();
      }, 5000);
    }
  }

  componentDidUpdate(prevProps: NotificationProps) {
    if (!this.compareNotification(prevProps.notification, this.props.notification)) {
      this.setNotification();
    }
  }

  handleNotificationDismiss() {
    NotificationUtil.remove();
    this.setState({
      notification: null,
    });
  }

  getNotificationColor() {
    if (!this.state.notification) {
      return;
    }

    switch (this.state.notification?.type) {
      case 'success':
        return 'success';
      case 'error':
        return 'danger';
      case 'loading':
        return 'dark';
    }
  }

  getNotificationIcon() {
    if (!this.state.notification) {
      return;
    }

    switch (this.state.notification?.type) {
      case 'success':
        return CheckmarkIcon;
      case 'error':
        return AlertIcon;
      case 'loading':
        return SyncIcon;
    }
  }

  getNotificationButtons() {
    if (!this.state.notification) {
      return;
    }

    if (this.state.notification.type !== 'loading') {
      return [
        {
          icon: CloseIcon,
          role: 'cancel',
          handler: () => {},
        },
      ];
    }
  }

  render() {
    if (!this.state.notification) {
      return;
    }

    return (
      <IonToast
        isOpen={this.state.open}
        color={this.getNotificationColor()}
        icon={this.getNotificationIcon()}
        message={this.state.notification.message}
        className={`${styles.toast} ${this.state.notification.type === 'loading' ? styles.loadingToast : ''}`}
        onDidDismiss={this.handleNotificationDismiss}
        buttons={this.getNotificationButtons()}
      />
    );
  }
}

export default connect((state: any) => {
  return {
    notification: state.notification,
  };
})(Notification);
