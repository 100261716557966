import { DeviceComplete, Sensors } from '@src/api/models/DeviceModel';
import moment from 'moment';
import {
  BatteryLevel,
  BatteryLevelColor,
  BatteryLevelIcon,
  DeviceTelemetry,
  INACTIVE_SENSOR_COLOR,
  LocationLevelColor,
  LocationLevelText,
  NO_SENSOR_VALUE,
  SensorColor,
  SensorEnum,
  SensorIcon,
  SensorTelemetry,
  SensorValue,
  UNDEFINED_SENSOR_COLOR,
  WeightGainColor,
} from './typeDefs';

const getWeightGainColor = (weightGain: number) => {
  if (weightGain > 0) {
    return WeightGainColor.GAIN;
  } else if (weightGain < 0) {
    return WeightGainColor.LOSS;
  } else {
    return WeightGainColor.NEUTRAL;
  }
};

const getBatteryLevel = (voltage: number) => {
  if (voltage >= 3.16) {
    return BatteryLevel.FULL;
  } else if (voltage >= 3.14) {
    return BatteryLevel.HALF;
  } else {
    return BatteryLevel.EMPTY;
  }
};

export const getHiveLocationValue = (hive: DeviceComplete): SensorValue => {
  const telemetry: DeviceTelemetry = [];

  if (
    hive.status &&
    hive.status.tracking &&
    hive.status.tracking.geofencing &&
    typeof hive.status.tracking.geofencing.max_breach_level === 'number'
  ) {
    telemetry.push([moment().unix() * 1000, SensorEnum.MOVEMENT, hive.status.tracking.geofencing.max_breach_level]);
  }

  return getSensorValue(SensorEnum.MOVEMENT, telemetry, hive.sensors || []);
};

export const getHiveBatteryValue = (hive: DeviceComplete, devices: DeviceComplete[]) => {
  const telemetry: DeviceTelemetry = [];

  if (hive.consist_of && hive.consist_of.length > 0) {
    for (const connectedDeviceUUID of hive.consist_of) {
      const connectedDeviceIndex = devices.findIndex((device) => device.uuid === connectedDeviceUUID);

      if (connectedDeviceIndex < 0) {
        continue;
      }

      const connectedDevice = devices[connectedDeviceIndex];

      if (!connectedDevice.status || !connectedDevice.status.power) {
        continue;
      }

      const deviceVoltage = connectedDevice.status.power.battery;

      if (telemetry.length > 0 && deviceVoltage > telemetry[0][2]) {
        continue;
      }

      const sensorTelemetry: SensorTelemetry = [moment().unix() * 1000, SensorEnum.BATTERY_VOLTAGE, deviceVoltage];

      if (telemetry.length > 0) {
        telemetry.push(sensorTelemetry);
      } else {
        telemetry[0] = sensorTelemetry;
      }
    }
  }

  return getSensorValue(SensorEnum.BATTERY_VOLTAGE, telemetry, hive.sensors || []);
};

export const getDeviceBatteryValue = (device: DeviceComplete): SensorValue => {
  const telemetry: DeviceTelemetry = [];

  if (device.status && device.status.power) {
    telemetry.push([moment().unix() * 1000, SensorEnum.BATTERY_VOLTAGE, device.status.power.battery]);
  }

  return getSensorValue(SensorEnum.BATTERY_VOLTAGE, telemetry, device.sensors || []);
};

export const getSensorValue = (number: SensorEnum, telemetry: DeviceTelemetry, sensorList: Sensors[]): SensorValue => {
  const result: SensorValue = {
    value: null,
    fixedValue: null,
    formatValue: `-`,
    unit: null,
    color: INACTIVE_SENSOR_COLOR,
    icon: NO_SENSOR_VALUE,
    active: false,
    outdated: false,
  };

  const sensorIndex = sensorList.findIndex((item: Sensors) => item.number === number);

  if (sensorIndex >= 0) {
    const sensor = sensorList[sensorIndex];
    result.unit = sensor.unit;
    result.formatValue = `- ${sensor.unit}`;
    result.active = sensor.active;

    // Set sensor icon
    const sensorIcon = SensorIcon[number];
    if (sensorIcon) {
      result.icon = sensorIcon;
    }
  }

  const sensorTelemetryIndex = telemetry.findIndex((item: SensorTelemetry) => item.length >= 3 && item[1] === number);

  if (sensorTelemetryIndex >= 0) {
    const sensorTelemetry = telemetry[sensorTelemetryIndex];
    result.value = sensorTelemetry[2];
    result.fixedValue = result.value.toFixed(1).replace(/^-([.0]*)$/, '$1'); // Avoid negative zero with replace
    result.formatValue = result.unit ? `${result.fixedValue} ${result.unit}` : result.fixedValue;

    // Set sensor color
    if (result.active) {
      result.color = SensorColor[number] || UNDEFINED_SENSOR_COLOR;
    }

    if (number === SensorEnum.BATTERY_VOLTAGE) {
      result.batteryLevel = getBatteryLevel(result.value);
      result.color = BatteryLevelColor[result.batteryLevel as BatteryLevel];
      result.icon = BatteryLevelIcon[result.batteryLevel as BatteryLevel];
    }

    if (number === SensorEnum.WEIGHT_GAIN) {
      result.color = getWeightGainColor(Number.parseFloat(result.fixedValue));
      const weightGainValue = (result.value <= 0 ? '' : '+') + result.fixedValue;
      result.formatValue = result.unit ? `${weightGainValue} ${result.unit}` : weightGainValue;
    }

    if (number === SensorEnum.MOVEMENT) {
      result.formatValue = LocationLevelText[result.value];
      result.color = LocationLevelColor[result.value];
    }

    const timestamp = moment(sensorTelemetry[0]);
    const currentTimestamp = moment();

    if (currentTimestamp.diff(timestamp, 'hours') > 24) {
      result.color = INACTIVE_SENSOR_COLOR;
      result.outdated = true;

      if (currentTimestamp.diff(timestamp, 'days') > 3) {
        result.value = null;
        result.fixedValue = null;
        result.formatValue = result.unit ? `- ${result.unit}` : `-`;
      }
    }
  }

  return result;
};

export default getSensorValue;
