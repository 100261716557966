import { DeviceComplete } from '@api/models/DeviceModel';
import { ACTIVE_CONNECTED_DEVICE_COLOR, ConnectedDevice, ConnectedDeviceIcon, DeviceType } from './typeDefs';

export const getConnectedDevices = (hive: DeviceComplete, devices: DeviceComplete[]) => {
  const connectedDevices: ConnectedDevice[] = [];

  if (!hive.consist_of || hive.consist_of.length <= 0) {
    return connectedDevices;
  }

  for (const connectedDeviceUUID of hive.consist_of) {
    const connectedDeviceIndex = devices.findIndex((device) => device.uuid === connectedDeviceUUID);

    if (connectedDeviceIndex < 0) {
      continue;
    }

    const connectedDevice = devices[connectedDeviceIndex];

    if (connectedDevice.type !== DeviceType.SCALE && connectedDevice.type !== DeviceType.TRACKER) {
      continue;
    }

    connectedDevices.push({
      icon: ConnectedDeviceIcon[connectedDevice.type],
      color: ACTIVE_CONNECTED_DEVICE_COLOR,
    });
  }

  return connectedDevices;
};

export default getConnectedDevices;
