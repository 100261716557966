import config from '@config';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import moment from 'moment';
import 'moment/min/locales';
import React from 'react';
import Layout from './components/Layout';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import './theme/variables.css';

import cache from '@lib/cache';

setupIonicReact();
moment.locale(config.locale);

class App extends React.PureComponent {
  async componentDidMount() {
    await cache.init();
  }

  render() {
    return (
      <IonApp>
        <IonReactRouter>
          <Layout />
        </IonReactRouter>
      </IonApp>
    );
  }
}

export default App;
