import React from 'react';

class Hive extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32px"
        height="32px"
        x="0"
        y="0"
        version="1"
        viewBox="0 0 660 404"
        xmlSpace="preserve"
      >
        <path d="M199.583 399.054l-5.819-7.026-.572-103.023-.57-115.141h-11.074c-7.955 0-12.567-1.175-16.377-4.17-7.026-5.53-4.048-15.16.35-21.871C181.741 123.065 307.887 8.5 325.198 2.015c2.44-.915 6.588-1.123 9.218-.464 2.629.66 42.545 34.782 88.702 75.83l73.825 69.579 5.048 8.362c0 13.018-6.767 18.541-22.718 18.541h-12.535v216.983l-5.093 8.116c-7.026 7.027-12.404 6.87-19.648-.379-5.203-5.204-5.466-6.501-5.466-26.96v-21.487H225.005v20.9c0 20.33-.164 21.06-6.061 26.957-7.596 7.599-11.314 7.98-19.361 1.061z"></path>
        <path
          fill="#fff"
          d="M362.533 244.374L362.533 173.863 256.77 173.863 151.007 173.863 151.007 244.374 151.007 319.93 256.77 319.93 362.533 319.93z"
          transform="translate(74)"
        ></path>
        <path
          fill="#fff"
          d="M440.68 137.733c0-.482-24.809-22.978-55.129-49.988l-55.133-49.114-5.527 5.064c-3.04 2.785-26.99 24.028-53.223 47.208-26.234 23.179-49.064 43.396-50.734 44.926-2.543 2.331 15.048 2.783 108.356 2.783 61.266-.001 111.39-.396 111.39-.879z"
        ></path>
        <path d="M322.794 288.06c-12.715-2.507-25.876-12.819-31.797-24.917-3.181-6.499-3.35-7.455-3.35-18.85 0-10.94.255-12.549 2.896-18.192 5.779-12.337 15.249-20.743 27.739-24.623 21.654-6.731 44.27 3.556 54.137 24.623 2.644 5.644 2.899 7.252 2.899 18.192 0 11.395-.17 12.351-3.352 18.85-8.971 18.335-29.572 28.771-49.172 24.917z"></path>
        <path
          fill="#fff"
          d="M338.007 262.973c6.683-2.392 12.104-9.236 13.107-16.553.641-4.678-1.323-11.254-4.51-15.101-7.756-9.361-23.065-9.361-30.821 0-10.729 12.951-1.427 32.669 15.411 32.669 2.182.001 5.247-.458 6.813-1.015z"
        ></path>
      </svg>
    );
  }
}

export default Hive;
