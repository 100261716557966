import DateModal from '@components/DateModal';
import NavButton from '@components/NavButton';
import TextField from '@components/TextField';
import TimePicker from '@components/TimePicker';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { storageSet } from '@src/storage';
import store from '@src/store';
import { calendarOutline as CalendarIcon, timeOutline } from 'ionicons/icons';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import Logo from './logo.svg';
import styles from './styles.module.css';

type IonDateTimeProps = React.ComponentProps<typeof IonDatetime>;

interface HeaderProps {
  title?: string;
  customButton?: {
    icon: string;
    onClick: any;
  };
  graph_interval?: number;
  graph_interval_last?: number;
  graph_custom_interval?: any;
  graph_interval_from?: string | null;
  graph_mode: string;
  graph_time: boolean;
  graph_time_start: string | null;
  graph_time_end: string | null;
  graph_agg_func: string;
  fullscreen: boolean;
}

interface HeaderState {
  historyModalOpen: boolean;
  selectedGraphInterval: any;
  selectedAggFunc: any;
  graph_time_start?: any;
  graph_time_start_formatted?: any;
  graph_time_end?: any;
  graph_time_end_formatted?: any;
  graph_time?: any;
  graph_interval?: any;
  graph_custom_interval?: any;
  graph_interval_from?: any;
  graph_interval_from_formatted?: any;
  graph_mode?: any;
  dateFromModalOpen: boolean;
  dateToModalOpen: boolean;
  realtimeDateFromModalOpen: boolean;
}

const historyModalTime: any = [
  { text: '8 hodin', value: 1000 * 60 * 60 * 8 },
  { text: '12 hodin', value: 1000 * 60 * 60 * 12 },
  { text: 'den', value: 1000 * 60 * 60 * 24 },
  { text: 'týden', value: 1000 * 60 * 60 * 24 * 7 },
  { text: '2 týdny', value: 1000 * 60 * 60 * 24 * 14 },
  { text: 'měsíc', value: 1000 * 60 * 60 * 24 * 30 },
];

class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);

    this.handleModalConfirm = this.handleModalConfirm.bind(this);
    this.handleModalDismiss = this.handleModalDismiss.bind(this);
    this.handleHistoryModalOpen = this.handleHistoryModalOpen.bind(this);
    this.handleGraphIntervalChange = this.handleGraphIntervalChange.bind(this);
    this.handleDateFromChange = this.handleDateFromChange.bind(this);
    this.handleDateToChange = this.handleDateToChange.bind(this);
    this.onNavClick = this.onNavClick.bind(this);
    this.onTimeClick = this.onTimeClick.bind(this);
    this.isDateFromEnabled = this.isDateFromEnabled.bind(this);
    this.isDateToEnabled = this.isDateToEnabled.bind(this);
    this.isIntervalDateFromEnabled = this.isIntervalDateFromEnabled.bind(this);
    this.handleChangeCustomInterval = this.handleChangeCustomInterval.bind(this);
    this.handleIntervalFromChange = this.handleIntervalFromChange.bind(this);

    this.state = {
      historyModalOpen: false,
      selectedGraphInterval: undefined,
      selectedAggFunc: undefined,
      graph_custom_interval: null,
      graph_interval_from: null,
      dateFromModalOpen: false,
      dateToModalOpen: false,
      realtimeDateFromModalOpen: false,
    };
  }

  isIntervalDateFromEnabled(dateString: string) {
    const date = moment(dateString);
    const now = moment().endOf('day');
    return date.isBefore(now);
  }

  isDateFromEnabled(dateString: string) {
    const date = moment(dateString);
    const now = moment().endOf('day');
    const dateTo = moment(this.state.graph_time_end);
    return date.isBefore(dateTo) && date.isBefore(now);
  }

  isDateToEnabled(dateString: string) {
    const date = moment(dateString);
    const now = moment().endOf('day');
    const dateFrom = moment(this.state.graph_time_start);
    return date.isAfter(dateFrom) && date.isBefore(now);
  }

  async onNavClick(e: any, key: any) {
    this.setState({
      graph_mode: key,
    });
  }

  handleChangeCustomInterval(data: Record<string, number | null>) {
    this.setState({
      graph_custom_interval: data,
    });
  }

  handleHistoryModalOpen() {
    const currentTime = moment();
    let endTime = currentTime;
    let startTime = currentTime.clone().subtract('7', 'days').subtract('1', 'minute');
    const valueFormat = 'YYYY-MM-DDTHH:mm:ss';
    const outputFormat = 'DD.MM.YYYY HH:mm';

    if (this.props.graph_time_start && this.props.graph_time_end) {
      startTime = moment(this.props.graph_time_start);
      endTime = moment(this.props.graph_time_end);
    }

    this.setState({
      historyModalOpen: true,
      graph_interval: this.props.graph_interval,
      graph_custom_interval: this.props.graph_custom_interval,
      graph_interval_from: this.props.graph_interval_from,
      graph_mode: this.props.graph_mode,
      graph_time: this.props.graph_time,
      graph_time_start: startTime.format(valueFormat),
      graph_time_start_formatted: startTime.format(outputFormat),
      graph_time_end: endTime.format(valueFormat),
      graph_time_end_formatted: endTime.format(outputFormat),
    });
  }

  handleModalDismiss() {
    this.setState({
      historyModalOpen: false,
    });
  }

  async handleModalConfirm() {
    this.setState({
      historyModalOpen: false,
    });

    const data: any = {
      graph_interval: this.state.graph_interval,
      graph_interval_last: this.state.graph_interval,
      graph_custom_interval: this.state.graph_custom_interval,
      graph_interval_from: this.state.graph_interval_from,
      graph_is_custom_last: true,
      graph_mode: this.state.graph_mode,
      graph_time: this.state.graph_time,
      graph_time_start: this.state.graph_time_start,
      graph_time_end: this.state.graph_time_end,
    };

    const customInterval = this.state.graph_custom_interval;

    if (customInterval !== null) {
      let duration = 0;
      for (const k in customInterval) {
        duration += moment.duration(customInterval[k], k as any).asMilliseconds();
      }

      if (duration <= 0) {
        duration += moment.duration(7, 'days').asMilliseconds();
        data.graph_custom_interval = {
          ...customInterval,
          days: 7,
        };
      }

      data.graph_interval = duration;
      data.graph_interval_last = duration;
      data.graph_is_custom_last = true;
    }

    store.dispatch({
      type: 'set',
      payload: data,
    });

    for (const dataKey in data) {
      await storageSet(dataKey, data[dataKey]);
    }
  }

  async handleGraphIntervalChange(e: any) {
    const newState: any = {
      graph_interval: e.target.value,
    };

    this.setState(newState);
  }

  handleIntervalFromChange(e: any) {
    let val = e.target.value;

    this.setState({
      graph_interval_from: val,
      graph_interval_from_formatted: moment(val).format('DD.MM.YYYY HH:mm'),
    });
  }

  handleDateFromChange(e: any) {
    let val = e.target.value;

    if (moment(val).isSameOrAfter(this.state.graph_time_end, 'minutes')) {
      val = this.state.graph_time_start;
    }

    this.setState({
      graph_time_start: val,
      graph_time_start_formatted: moment(val).format('DD.MM.YYYY HH:mm'),
    });
  }

  handleDateToChange(e: any) {
    let val = e.target.value;

    if (moment(val).isSameOrBefore(this.state.graph_time_start, 'minutes')) {
      val = this.state.graph_time_end;
    }

    this.setState({
      graph_time_end: val,
      graph_time_end_formatted: moment(val).format('DD.MM.YYYY HH:mm'),
    });
  }

  renderRealtime() {
    return (
      <React.Fragment>
        {historyModalTime.map((t: any, index: number) => (
          <IonButton
            key={index}
            size="small"
            color={
              this.state.graph_interval === t.value &&
              this.state.graph_custom_interval === null &&
              this.state.graph_interval_from === null
                ? 'primary'
                : 'light'
            }
            onClick={() => {
              this.setState({ graph_interval: t.value, graph_custom_interval: null, graph_interval_from: null });
            }}
          >
            {t.text}
          </IonButton>
        ))}
        <IonButton
          size="small"
          color={this.state.graph_interval_from !== null ? 'primary' : 'light'}
          onClick={() => {
            const val = moment().subtract(7, 'days');

            this.setState({
              graph_custom_interval: null,
              graph_interval_from: val.format('YYYY-MM-DDTHH:mm:ss'),
              graph_interval_from_formatted: val.format('DD.MM.YYYY HH:mm'),
            });
          }}
        >
          Od
        </IonButton>
        <IonButton
          size="small"
          color={this.state.graph_custom_interval !== null ? 'primary' : 'light'}
          onClick={() => {
            this.setState({
              graph_custom_interval: {
                days: null,
                hours: null,
                minutes: null,
                seconds: null,
              },
              graph_interval_from: null,
            });
          }}
        >
          Vlastní
        </IonButton>
        {this.state.graph_custom_interval !== null && (
          <TimePicker
            data={this.state.graph_custom_interval}
            onChange={this.handleChangeCustomInterval}
          />
        )}
        {this.state.graph_interval_from !== null && (
          <IonRow className="ion-padding-top">
            <IonCol>
              <TextField
                name="dateFrom"
                label="Datum a čas"
                value={this.state.graph_interval_from_formatted}
                required
              >
                <IonButton
                  fill="clear"
                  slot="end"
                  color="dark"
                  className="ion-align-self-center"
                  onClick={() => {
                    this.setState({
                      realtimeDateFromModalOpen: true,
                    });
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    icon={CalendarIcon}
                    style={{
                      fontSize: '24px',
                    }}
                  />
                </IonButton>
              </TextField>
            </IonCol>
          </IonRow>
        )}

        <DateModal
          open={this.state.realtimeDateFromModalOpen}
          onWillDismiss={() => {
            this.setState({
              realtimeDateFromModalOpen: false,
            });
          }}
          datetimeProps={{
            isDateEnabled: this.isIntervalDateFromEnabled,
            value: this.state.graph_interval_from,
            onIonChange: this.handleIntervalFromChange,
          }}
        />
      </React.Fragment>
    );
  }

  async onTimeClick(e: any) {
    this.setState({
      graph_time: !this.state.graph_time,
    });
  }

  renderHistory() {
    return (
      <React.Fragment>
        <IonRow>
          <IonCol size="12">
            <TextField
              label="Začátek"
              name="dateFrom"
              value={this.state.graph_time_start_formatted}
              required
              readonly
            >
              <IonButton
                fill="clear"
                slot="end"
                color="dark"
                className="ion-align-self-center"
                onClick={() => {
                  this.setState({
                    dateFromModalOpen: true,
                  });
                }}
              >
                <IonIcon
                  slot="icon-only"
                  icon={CalendarIcon}
                  style={{
                    fontSize: '24px',
                  }}
                />
              </IonButton>
            </TextField>
          </IonCol>
          <IonCol size="12">
            <TextField
              label="Konec"
              name="dateTo"
              value={this.state.graph_time_end_formatted}
              required
              readonly
            >
              <IonButton
                fill="clear"
                slot="end"
                color="dark"
                className="ion-align-self-center"
                onClick={() => {
                  this.setState({
                    dateToModalOpen: true,
                  });
                }}
              >
                <IonIcon
                  slot="icon-only"
                  icon={CalendarIcon}
                  style={{
                    fontSize: '24px',
                  }}
                />
              </IonButton>
            </TextField>
          </IonCol>
        </IonRow>

        <DateModal
          open={this.state.dateFromModalOpen}
          onWillDismiss={() => {
            this.setState({
              dateFromModalOpen: false,
            });
          }}
          datetimeProps={{
            isDateEnabled: this.isDateFromEnabled,
            value: this.state.graph_time_start,
            onIonChange: this.handleDateFromChange,
          }}
        />

        <DateModal
          open={this.state.dateToModalOpen}
          onWillDismiss={() => {
            this.setState({
              dateToModalOpen: false,
            });
          }}
          datetimeProps={{
            isDateEnabled: this.isDateToEnabled,
            value: this.state.graph_time_end,
            onIonChange: this.handleDateToChange,
          }}
        />
      </React.Fragment>
    );
  }

  renderModalContent() {
    if (this.state.graph_mode === 'realtime') {
      return this.renderRealtime();
    }

    return this.renderHistory();
  }

  render() {
    var sIntervalText = '';

    if (this.props.graph_mode === 'history') {
      const formatStr = 'DD.MM.YYYY HH:mm:ss';
      const startTime = this.props.graph_time_start ? moment(this.props.graph_time_start).format(formatStr) : '-';
      const endTime = this.props.graph_time_end ? moment(this.props.graph_time_end).format(formatStr) : '-';
      sIntervalText = `od ${startTime} do ${endTime}`;
    } else if (this.props.graph_custom_interval !== null) {
      const customInterval = this.props.graph_custom_interval;
      for (const k in customInterval) {
        if (customInterval[k] === null) {
          continue;
        }

        sIntervalText += `${customInterval[k]}${k.charAt(0).toLowerCase()} `;
      }
    } else if (this.props.graph_interval_from !== null) {
      const startTime = moment(this.props.graph_interval_from).format('DD.MM.YYYY HH:mm:ss');
      sIntervalText = `od ${startTime}`;
    } else {
      const sInterval = historyModalTime.findIndex((item: any) => item.value === this.props.graph_interval);

      if (sInterval !== -1) {
        sIntervalText = historyModalTime[sInterval].text;
      }
    }

    return (
      <IonHeader style={this.props.fullscreen ? { display: 'none' } : undefined}>
        <IonToolbar>
          <IonButtons slot="start">
            {this.props.customButton ? (
              <IonButton
                fill="clear"
                onClick={this.props.customButton.onClick}
              >
                <IonIcon
                  slot="icon-only"
                  icon={this.props.customButton.icon}
                ></IonIcon>
              </IonButton>
            ) : (
              <IonMenuButton />
            )}
          </IonButtons>
          {this.props.title && <IonTitle>{this.props.title}</IonTitle>}
          <IonButton
            className={styles.historyModalButton}
            slot="end"
            fill="clear"
            onClick={this.handleHistoryModalOpen}
          >
            <IonIcon
              slot="start"
              icon={timeOutline}
            />
            <IonText className={styles.historyBtnText}>
              - {this.props.graph_mode === 'realtime' ? 'V realném čase' : 'Historie'} - {sIntervalText}
            </IonText>
          </IonButton>
          <IonIcon
            className={styles.headerLogo}
            slot="end"
            style={{ backgroundColor: '#fffae5', width: '298px', height: '70px' }}
            src={Logo}
          ></IonIcon>

          <IonModal
            onDidDismiss={this.handleModalDismiss}
            isOpen={this.state.historyModalOpen}
          >
            <IonHeader>
              <IonToolbar className="modal-toolbar">
                <IonButtons slot="start">
                  <IonButton onClick={this.handleModalDismiss}>Zrušit</IonButton>
                </IonButtons>
                <IonTitle>Nastavení grafu</IonTitle>
                <IonButtons slot="end">
                  <IonButton
                    strong={true}
                    onClick={this.handleModalConfirm}
                  >
                    OK
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="modal-content">
              <IonRow>
                <IonCol style={{ margin: 0, padding: 0 }}>
                  <NavButton
                    btnKey={'realtime'}
                    text="V realném čase"
                    onClick={this.onNavClick}
                    value={this.state.graph_mode}
                  />
                </IonCol>
                <IonCol style={{ margin: 0, padding: 0 }}>
                  <NavButton
                    btnKey={'history'}
                    text="Historie"
                    onClick={this.onNavClick}
                    value={this.state.graph_mode}
                  />
                </IonCol>
              </IonRow>
              <div className="ion-padding">{this.renderModalContent()}</div>
            </IonContent>
          </IonModal>
        </IonToolbar>
      </IonHeader>
    );
  }
}

export default connect((state: any) => {
  return {
    graph_interval: state.graph_interval,
    graph_interval_last: state.graph_interval_last,
    graph_custom_interval: state.graph_custom_interval,
    graph_interval_from: state.graph_interval_from,
    graph_mode: state.graph_mode,
    graph_time: state.graph_time,
    graph_time_start: state.graph_time_start,
    graph_time_end: state.graph_time_end,
    graph_agg_func: state.graph_agg_func,
    fullscreen: state.fullscreen,
  };
})(Header);
