import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';
import * as React from 'react';
import { Virtuoso } from 'react-virtuoso';
import styles from './styles.module.css';
import { TableColumn } from './typeDefs';

interface TableProps {
  cols: TableColumn[];
  rows: Record<string, any>[];
  className?: string;
  rowHeight: number;
}

interface TableState {}

class Table extends React.Component<TableProps, TableState> {
  static defaultProps = {
    rows: [],
    rowHeight: 40,
  };

  constructor(props: TableProps) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
  }

  renderRow(row: Record<string, any>, index: number) {
    return (
      <div
        key={index}
        className={styles.tableRowContainer}
        style={{ ...(typeof this.props.rowHeight === 'number' && { height: `${this.props.rowHeight}px` }) }}
      >
        <IonRow
          className={`${styles.tableRow} ${index % 2 === 0 && styles.tableRowEven} ion-align-items-center ion-nowrap`}
          key={index}
        >
          {this.props.cols.map((col, colIndex) => (
            <IonCol
              key={colIndex}
              size={col.size}
              className={`ion-text-${col.textAlign || 'left'}`}
            >
              <IonText>{row[col.name]}</IonText>
            </IonCol>
          ))}
        </IonRow>
      </div>
    );
  }

  renderHeaderRow() {
    return (
      <IonRow
        className={`${styles.tableHeaderRow} ion-align-items-center ion-nowrap`}
        style={{ ...(typeof this.props.rowHeight === 'number' && { minHeight: `${this.props.rowHeight}px` }) }}
      >
        {this.props.cols.map((col, index) => (
          <IonCol
            key={index}
            size={col.size}
            className={`ion-text-${col.textAlign || 'left'}`}
          >
            <IonText>{col.fieldName}</IonText>
          </IonCol>
        ))}
      </IonRow>
    );
  }

  render() {
    return (
      <IonGrid className={`${styles.table} ${this.props.className || ''}`}>
        {this.renderHeaderRow()}
        {this.props.rows.length > 0 ? (
          <Virtuoso
            className={styles.virtuosoContainer}
            totalCount={this.props.rows.length}
            itemContent={(index) => {
              return this.renderRow(this.props.rows[index], index);
            }}
          ></Virtuoso>
        ) : (
          <div className={styles.noData}>
            <IonText>Nejsou k dispozici žádná data</IonText>
          </div>
        )}
      </IonGrid>
    );
  }
}

export default Table;
