import validator from 'validator';

export class Validation {
  static required(value: string | number | undefined | null, key: string, errors: Record<string, string>) {
    let val = value || '';

    if (typeof val === 'number') {
      val = val.toString();
    }

    if (val.trim().length <= 0) {
      errors[key] = 'Prázdné pole';
    }
  }

  static email(value: string, key: string, errors: Record<string, string>) {
    if (!validator.isEmail(value)) {
      errors[key] = 'Email není ve správném formátu';
    }
  }
}

export default Validation;
