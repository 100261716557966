import {
  AssignDeviceByPasswdRequest,
  AssignDeviceRequest,
  CreateHiveRequest,
  DeviceComplete,
} from '@api/models/DeviceModel';
import { Location, LocationRequest } from '@api/models/LocationModel';
import DeviceService from '@api/services/DeviceService';
import LocationService from '@api/services/LocationService';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import LocationList from '@components/LocationList';
import { CustomButton } from '@components/LocationList/typeDefs';
import NavButton from '@components/NavButton';
import Select from '@components/Select';
import { SelectOption } from '@components/Select/typeDefs';
import TextField from '@components/TextField';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import getWeightGain from '@lib/data/Device/getWeightGain';
import { DeviceType } from '@lib/data/Device/typeDefs';
import HiveSvg from '@pages/Hive/img/hive';
import HiveGroupSvg from '@pages/Hive/img/hive_group';
import { StoreState } from '@src/store';
import NotificationUtil from '@util/Notification';
import Validation from '@util/Validation';
import { HttpStatusCode } from 'axios';
import { History } from 'history';
import {
  addOutline,
  closeOutline,
  createOutline,
  ellipse as ellipseIcon,
  locateOutline,
  mapOutline,
  syncOutline,
} from 'ionicons/icons';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import { CirclePicker, ColorResult } from 'react-color';
import { CircleMarker, MapContainer, Marker, TileLayer } from 'react-leaflet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Header from '../../components/Header';
import withHistory from '../../components/HOC/withHistory';
import Icon from '../../components/Icon';
import styles from './styles.module.css';

interface HomeProps {
  history: History;
  isPublic: boolean;
}

interface HomeState {
  devices: DeviceComplete[];
  locations: Location[];
  latestTelemetry: any;
  createModalOpen: boolean;
  createModalMode: string;
  createModalTab: string;
  mapGeolocation: any;
  mapPosLatitude: string | null;
  mapPosLongitude: string | null;
  openColorDialog: boolean;
  mapEditMode: boolean;
  locationTabDisabled: boolean;
  formData: Record<string, any>;
  formErrors: Record<string, string>;
  loading: boolean;
  search: string;
  alert: React.ComponentProps<typeof IonAlert>;
  assignLocationList: Location[] | null;
  assignHiveList: DeviceComplete[] | null;
  assignDeviceList: DeviceComplete[] | null;
}

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const pageTitle = 'Přehled';

const circlePickerColors = [
  '#ff6900',
  '#fcb900',
  '#7bdcb5',
  '#00d084',
  '#8ed1fc',
  '#0693e3',
  '#abb8c3',
  '#eb144c',
  '#f78da7',
  '#9900ef',
];

class Home extends React.Component<HomeProps, HomeState> {
  private mapRef: L.Map | null = null;
  private refreshInterval?: number;

  constructor(props: HomeProps) {
    super(props);

    this.onHiveClick = this.onHiveClick.bind(this);
    this.onSearchValueChange = this.onSearchValueChange.bind(this);
    this.handleModalDismiss = this.handleModalDismiss.bind(this);
    this.handleModalNavClick = this.handleModalNavClick.bind(this);
    this.handleHiveCreate = this.handleHiveCreate.bind(this);
    this.handleAssignDevice = this.handleAssignDevice.bind(this);
    this.setMapZoom = this.setMapZoom.bind(this);
    this.handleCreateLocationModal = this.handleCreateLocationModal.bind(this);
    this.handleCreateHiveModal = this.handleCreateHiveModal.bind(this);
    this.handleCreateAssignDeviceModal = this.handleCreateAssignDeviceModal.bind(this);
    this.handleChangeFormData = this.handleChangeFormData.bind(this);
    this.handleLocationUpdate = this.handleLocationUpdate.bind(this);
    this.handleLocationDelete = this.handleLocationDelete.bind(this);
    this.handleLocationDeleteConfirm = this.handleLocationDeleteConfirm.bind(this);
    this.setCurrentLocation = this.setCurrentLocation.bind(this);
    this.fetchData = this.fetchData.bind(this);

    this.state = {
      devices: [],
      locations: [],
      createModalOpen: false,
      createModalMode: 'hive',
      createModalTab: 'form',
      locationTabDisabled: false,
      mapGeolocation: null,
      mapPosLatitude: null,
      mapPosLongitude: null,
      openColorDialog: false,
      mapEditMode: false,
      formData: {},
      formErrors: {},
      loading: false,
      latestTelemetry: [],
      search: '',
      alert: {
        isOpen: false,
        onDidDismiss: () => {
          this.setState({
            alert: {
              isOpen: false,
              onDidDismiss: this.state.alert.onDidDismiss,
            },
          });
        },
      },
      assignLocationList: null,
      assignDeviceList: null,
      assignHiveList: null,
    };
  }

  validateHive() {
    const formErrors: Record<string, string> = {};

    Validation.required(this.state.formData.name, 'name', formErrors);
    Validation.required(this.state.formData.location, 'location', formErrors);

    this.setState({
      formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  }

  async handleHiveCreate() {
    if (!this.validateHive()) {
      return;
    }

    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const newHive: CreateHiveRequest = {
      name: this.state.formData.name,
      color: this.state.formData.color,
    };

    if (this.state.formData.location !== -1) {
      newHive.location = this.state.formData.location;
    }

    if (this.state.mapPosLatitude !== null && this.state.mapPosLongitude !== null) {
      newHive.coordinates = {
        latitude: Number.parseFloat(this.state.mapPosLatitude as string),
        longitude: Number.parseFloat(this.state.mapPosLongitude as string),
      };
    }

    try {
      await DeviceService.createHive(newHive);
      NotificationUtil.success(`Úl "${this.state.formData.name}" byl úspěšně vytvořen`);

      // Update devices
      const devices = await DeviceService.devicesListComplete(true);
      this.fetchWeightGain(devices);

      this.setState({
        devices,
      });

      this.handleModalDismiss();
    } catch (e) {
      this.setState({
        loading: false,
      });

      NotificationUtil.error('Neočekávaná chyba při ukládání dat');
    }
  }

  validateLocation() {
    const formErrors: Record<string, string> = {};

    Validation.required(this.state.formData.name, 'name', formErrors);
    Validation.required(this.state.mapPosLatitude, 'mapPosLatitude', formErrors);
    Validation.required(this.state.mapPosLongitude, 'mapPosLongitude', formErrors);

    this.setState({
      formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  }

  async handleLocationUpdate() {
    if (!this.validateLocation()) {
      return;
    }

    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const newLocation: LocationRequest = {
      name: this.state.formData.name,
      description: this.state.formData.description,
      coordinates: {
        latitude: Number.parseFloat(this.state.mapPosLatitude as string),
        longitude: Number.parseFloat(this.state.mapPosLongitude as string),
      },
    };

    try {
      if (this.state.formData.id) {
        await LocationService.locationsUpdate(this.state.formData.id, newLocation);
      } else {
        await LocationService.locationsCreate(newLocation);
      }

      NotificationUtil.success(
        `Stanoviště "${this.state.formData.name}" bylo úspěšně ${this.state.formData.id ? 'upraveno' : 'vytvořeno'}`,
      );

      // Update locations
      const locations = await this.getLocations(true);
      this.setState({
        locations,
      });

      this.handleModalDismiss();
    } catch (e: any) {
      this.setState({
        loading: false,
      });

      NotificationUtil.error('Neočekávaná chyba při ukládání dat');
    }
  }

  validateAssignDevice() {
    const formErrors: Record<string, string> = {};

    Validation.required(this.state.formData.location, 'location', formErrors);
    Validation.required(this.state.formData.hive, 'hive', formErrors);
    Validation.required(this.state.mapPosLatitude, 'mapPosLatitude', formErrors);
    Validation.required(this.state.mapPosLongitude, 'mapPosLongitude', formErrors);

    if (this.state.formData.assignType === 'password') {
      Validation.required(this.state.formData.password, 'password', formErrors);
    } else {
      Validation.required(this.state.formData.device, 'device', formErrors);
    }

    this.setState({
      formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  }

  async handleAssignDevice() {
    if (!this.validateAssignDevice()) {
      return;
    }

    if (this.state.formData.assignType === 'password') {
      await this.assignDeviceByPassword();
    } else {
      await this.assignDeviceByUUID();
    }
  }

  async assignDeviceByPassword() {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const assignDeviceRequest: AssignDeviceByPasswdRequest = {
      assign_password: this.state.formData.password,
      coordinates: {
        latitude: Number.parseFloat(this.state.mapPosLatitude as string),
        longitude: Number.parseFloat(this.state.mapPosLongitude as string),
      },
    };

    try {
      await DeviceService.assignDevicePasswdCreate(this.state.formData.hive, assignDeviceRequest);
      NotificationUtil.success(`Zařízení bylo úspěšně přiřazeno`);

      // Update devices and telemetry
      const devices = await DeviceService.devicesListComplete(true);
      const latestTelemetry = await DeviceService.devicesListLatestTelemetry(true);
      this.fetchWeightGain(devices);

      this.setState({
        devices,
        latestTelemetry,
      });

      this.handleModalDismiss();
    } catch (e: any) {
      this.setState({
        loading: false,
      });

      let errMsg = 'Neočekávaná chyba při ukládání dat';

      if (e.response.status === HttpStatusCode.PreconditionFailed) {
        const errorCode = e.response.body.errorCode;

        switch (errorCode) {
          case 2:
            errMsg = 'Nelze přiřadit zařízení daného typu';
            break;
          case 3:
            errMsg = 'Úl už má přiřazené zařízení daného typu';
            break;
          case 4:
            errMsg = 'Zařizení už je přiřazené k jinému úlu';
            break;
        }
      }

      if (e.response.status === HttpStatusCode.NotFound) {
        errMsg = 'Zařízení s tímto heslem nebylo nalezeno';
      }

      NotificationUtil.error(errMsg);
    }
  }

  async assignDeviceByUUID() {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const assignDeviceRequest: AssignDeviceRequest = {
      uuid: this.state.formData.device,
      coordinates: {
        latitude: Number.parseFloat(this.state.mapPosLatitude as string),
        longitude: Number.parseFloat(this.state.mapPosLongitude as string),
      },
    };

    try {
      await DeviceService.assignDeviceCreate(this.state.formData.hive, assignDeviceRequest);
      NotificationUtil.success(`Zařízení bylo úspěšně přiřazeno`);

      // Update devices and telemetry
      const devices = await DeviceService.devicesListComplete(true);
      const latestTelemetry = await DeviceService.devicesListLatestTelemetry(true);
      this.fetchWeightGain(devices);

      this.setState({
        devices,
        latestTelemetry,
      });

      this.handleModalDismiss();
    } catch (e: any) {
      this.setState({
        loading: false,
      });

      NotificationUtil.error('Neočekávaná chyba při ukládání dat');
    }
  }

  handleModalDismiss() {
    this.setState({
      createModalOpen: false,
      createModalTab: 'form',
      mapPosLatitude: null,
      mapPosLongitude: null,
      locationTabDisabled: false,
      formData: {},
      formErrors: {},
      loading: false,
      assignLocationList: null,
      assignDeviceList: null,
      assignHiveList: null,
    });
  }

  closeAlert() {
    this.setState({
      alert: {
        isOpen: false,
        onDidDismiss: this.state.alert.onDidDismiss,
      },
    });
  }

  async handleLocationDelete(data: Location) {
    this.closeAlert();

    try {
      await LocationService.locationsDestroy(data.id);
      NotificationUtil.success(`Stanoviště "${data.name}" bylo úspěšně smazáno`);

      // Update locations
      const locations = await this.getLocations(true);
      this.setState({
        locations,
      });
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při mazání stanoviště');
    }
  }

  handleLocationDeleteConfirm(data: Location) {
    this.setState({
      alert: {
        ...this.state.alert,
        isOpen: true,
        header: 'Mazání stanoviště',
        subHeader: data.name || '',
        message: 'Opravdu si přejete smazat stanoviště?',
        buttons: [
          {
            text: 'Ne',
            role: 'cancel',
          },
          {
            text: 'Ano',
            role: 'confirm',
            handler: async () => {
              await this.handleLocationDelete(data);
            },
          },
        ],
      },
    });
  }

  handleCreateLocationModal(data?: Location) {
    const formData: Record<string, any> = {
      name: '',
      description: '',
    };

    let longitude: string | null = null;
    let latitude: string | null = null;

    if (data) {
      formData.id = data.id;
      formData.name = data.name;
      formData.description = data.description;

      if (data.coordinates) {
        longitude = data.coordinates.longitude.toString();
        latitude = data.coordinates.latitude.toString();
      }
    }

    this.setState({
      mapPosLatitude: latitude,
      mapPosLongitude: longitude,
      formData,
      createModalOpen: true,
      createModalMode: 'location',
    });

    this.setCurrentLocation();
  }

  handleCreateHiveModal() {
    const formData: Record<string, any> = {
      name: '',
      color: circlePickerColors[0],
      location: -1,
    };

    this.setState({
      mapPosLatitude: null,
      mapPosLongitude: null,
      formData,
      createModalOpen: true,
      createModalMode: 'hive',
    });

    this.setCurrentLocation();
  }

  handleCreateAssignDeviceModal() {
    const formData: Record<string, any> = {
      location: null,
      hive: null,
      assignType: 'password',
      password: '',
      device: null,
    };

    this.setState({
      mapPosLatitude: null,
      mapPosLongitude: null,
      assignLocationList: this.getAssignLocationList(),
      formData,
      createModalOpen: true,
      createModalMode: 'assign_device',
      locationTabDisabled: true,
    });
  }

  setCurrentLocation(forceZoom: boolean = false) {
    Geolocation.getCurrentPosition()
      .then((pos) => {
        const lat = pos.coords.latitude ? pos.coords.latitude.toString() : null;
        const lon = pos.coords.longitude ? pos.coords.longitude.toString() : null;

        this.setState({
          mapGeolocation: lat !== null && lon !== null ? [lat, lon] : null,
        });

        if (forceZoom || this.state.mapPosLatitude === null || this.state.mapPosLongitude === null) {
          this.setMapZoom(pos.coords.latitude, pos.coords.longitude, 17);
        }
      })
      .catch((e) => {});
  }

  handleChangeFormData(e: any) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  onHiveClick(hive: DeviceComplete) {
    this.props.history.push(`/hive/${hive.uuid}`);
  }

  componentDidMount() {
    this.fetchData();

    // Refresh data every 10 minutes
    this.refreshInterval = window.setInterval(() => this.fetchData({ forceUpdate: true }), 1000 * 60 * 10);
  }

  componentWillUnmount() {
    window.clearInterval(this.refreshInterval);
  }

  async onSearchValueChange(e: Event) {
    const target = e.target as HTMLIonSearchbarElement;

    this.setState({
      search: target?.value || '',
    });
  }

  async getLocations(force: boolean = false) {
    const locations = await LocationService.locationsList(force);

    // Add location for hives without any location
    locations.push({
      id: -1,
      type: '',
      name: 'Bez stanoviště',
    });

    return locations;
  }

  async fetchWeightGain(devices: DeviceComplete[]) {
    return;
    for (const device of devices) {
      device.weightGain = await getWeightGain(device);
    }

    this.setState({
      devices,
    });
  }

  async fetchData({ forceUpdate = false, showSuccessMessage = false } = {}) {
    try {
      const devices = await DeviceService.devicesListComplete(forceUpdate);
      const latestTelemetry = await DeviceService.devicesListLatestTelemetry(forceUpdate);
      const locations = await this.getLocations(forceUpdate);
      this.fetchWeightGain(devices);

      this.setState({
        locations,
        latestTelemetry,
        devices,
      });

      if (showSuccessMessage) {
        NotificationUtil.success('Aktualizace dat proběhla úspěšně');
      }
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při načítání dat');
    }
  }

  handleModalNavClick(e: any, key: any) {
    this.setState({
      createModalTab: key,
      mapEditMode: false,
    });
  }

  setMapZoom(lat: number, lon: number, zoom?: number) {
    if (this.mapRef) {
      this.mapRef.setView([lat, lon], zoom || this.mapRef.getZoom());
    }
  }

  getHiveList() {
    return this.state.devices.filter((device: DeviceComplete) => !!device.composite);
  }

  getAssignLocationList() {
    return this.state.locations.filter(
      (location) =>
        // Check if location has at least one hive with 0 or 1 device
        this.getHiveList().findIndex(
          (hive) => (hive.location || -1) === location.id && (!hive.consist_of || hive.consist_of.length < 2),
        ) >= 0,
    );
  }

  getAssignHiveList(locationId: number) {
    return this.getHiveList().filter(
      (hive) =>
        // Check if hive is on selected location and has 0 or 1 device
        (hive.location || -1) === locationId && (!hive.consist_of || hive.consist_of.length < 2),
    );
  }

  getAssignDeviceList(hiveUUID: string) {
    const deviceList: DeviceComplete[] = [];
    const selectedHiveIndex = this.getHiveList().findIndex((hive) => hive.uuid === hiveUUID);

    // Check if selected hive exists
    if (selectedHiveIndex < 0) {
      return deviceList;
    }

    const selectedHive = this.getHiveList()[selectedHiveIndex];

    // Get all assigned device types
    const assignedDeviceTypes: number[] = [];

    if (selectedHive.consist_of) {
      for (const assignedDeviceUUID of selectedHive.consist_of) {
        const assignedDeviceIndex = this.state.devices.findIndex((device) => device.uuid === assignedDeviceUUID);

        if (assignedDeviceIndex < 0) {
          continue;
        }

        const assignedDevice = this.state.devices[assignedDeviceIndex];

        if (assignedDeviceTypes.indexOf(assignedDevice.type) < 0) {
          assignedDeviceTypes.push(assignedDevice.type);
        }
      }
    }

    // Get all devices availavble for assignment
    for (const device of this.state.devices) {
      // Skip composite devices (hives)
      if (!!device.composite) {
        continue;
      }

      // Check if device is not already assigned to selected hive
      if (selectedHive.consist_of && selectedHive.consist_of.indexOf(device.uuid) > 0) {
        continue;
      }

      // Check if selected hive does not have device of the same type
      if (assignedDeviceTypes.indexOf(device.type) >= 0) {
        continue;
      }

      // For "scale" device type, check if device is not already assigned to hive in other location
      if (
        device.type === DeviceType.SCALE &&
        this.getHiveList().findIndex(
          (hive) =>
            (hive.location || -1) !== selectedHive.location &&
            hive.consist_of &&
            hive.consist_of.indexOf(device.uuid) >= 0,
        ) >= 0
      ) {
        continue;
      }

      // For other device types, check if device is not already assigned to another hive
      if (
        device.type !== DeviceType.SCALE &&
        this.getHiveList().findIndex((hive) => hive.consist_of && hive.consist_of.indexOf(device.uuid) >= 0) >= 0
      ) {
        continue;
      }

      deviceList.push(device);
    }

    return deviceList;
  }

  getAssignLocationOptions() {
    const options: SelectOption[] = [];

    if (this.state.assignLocationList === null) {
      return options;
    }

    return this.state.assignLocationList.map((location) => ({
      value: location.id,
      text: location.name || '',
    }));
  }

  getAssignHiveOptions() {
    const options: SelectOption[] = [];

    if (this.state.assignHiveList === null) {
      return options;
    }

    return this.state.assignHiveList.map((hive) => ({
      value: hive.uuid,
      text: hive.frontend_settings?.custom_name || '',
    }));
  }

  getAssignDeviceOptions() {
    const options: SelectOption[] = [];

    if (this.state.assignDeviceList === null) {
      return options;
    }

    return this.state.assignDeviceList.map((device) => ({
      value: device.uuid,
      text: device.name,
    }));
  }

  getAssignTypeOptions() {
    const options: SelectOption[] = [
      {
        value: 'password',
        text: 'Podle hesla',
      },
    ];

    if (this.state.assignDeviceList && this.state.assignDeviceList.length > 0) {
      options.push({
        value: 'list',
        text: 'Vyběr ze seznamu',
      });
    }

    return options;
  }

  getLocationCustomButtons() {
    const customButtons: CustomButton[] = [];

    if (this.props.isPublic) {
      return customButtons;
    }

    // Edit button
    customButtons.push({
      size: 'small',
      render: (location: Location) => {
        return location.id > 0;
      },
      onClick: this.handleCreateLocationModal,
      children: <Icon iconName="Pencil" />,
    });

    // Delete button
    customButtons.push({
      size: 'small',
      color: 'danger',
      render: (location: Location, hiveList: DeviceComplete[]) => {
        return location.id > 0 && hiveList.length <= 0;
      },
      onClick: this.handleLocationDeleteConfirm,
      children: <Icon iconName="Trash" />,
    });

    return customButtons;
  }

  renderFabs() {
    if (this.props.isPublic) {
      return;
    }

    const hiveWithoutDevicesIndex = this.getHiveList().findIndex(
      (hive) => !hive.consist_of || hive.consist_of.length < 2,
    );

    return (
      <IonFab
        slot="fixed"
        vertical="bottom"
        horizontal="end"
      >
        <IonFabButton>
          <IonIcon icon={addOutline}></IonIcon>
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton
            onClick={this.handleCreateHiveModal}
            data-desc="Vytvorit novy ul"
          >
            <HiveSvg />
          </IonFabButton>
          <IonFabButton
            onClick={() => this.handleCreateLocationModal()}
            data-desc="Vytvořit nové stanoviště"
          >
            <HiveGroupSvg />
          </IonFabButton>
          {hiveWithoutDevicesIndex >= 0 && (
            <IonFabButton
              onClick={this.handleCreateAssignDeviceModal}
              data-desc="Připojit nové zařízení"
            >
              <Icon
                iconName="Kettlebell"
                size={20}
              />
            </IonFabButton>
          )}
          <IonFabButton
            onClick={async () => {
              NotificationUtil.loading('Probíhá aktualizace dat...');
              await this.fetchData({ forceUpdate: true, showSuccessMessage: true });
            }}
            data-desc="Aktualizovat data"
          >
            <IonIcon
              icon={syncOutline}
              color="dark"
              style={{ fontSize: '20px' }}
            />
          </IonFabButton>
        </IonFabList>
      </IonFab>
    );
  }

  renderAssignDeviceForm() {
    return (
      <div className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <Select
                label="Vyberte stanoviště"
                name="location"
                required
                value={this.state.formData.location}
                error={this.state.formErrors.location}
                options={this.getAssignLocationOptions()}
                onIonChange={(e) => {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      location: e.target.value,
                      hive: null,
                      device: null,
                      assignType: 'password',
                    },
                    assignHiveList: this.getAssignHiveList(e.target.value),
                    assignDeviceList: null,
                    mapPosLatitude: null,
                    mapPosLongitude: null,
                    locationTabDisabled: true,
                  });
                }}
              />
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <Select
                label="Vyberte úl"
                name="hive"
                required
                disabled={!this.state.formData.location}
                value={this.state.formData.hive}
                error={this.state.formErrors.hive}
                options={this.getAssignHiveOptions()}
                onIonChange={(e) => {
                  const selectedLocationIndex = this.state.locations.findIndex(
                    (location) => location.id === this.state.formData.location,
                  );
                  const selectedHiveIndex = this.getHiveList().findIndex((hive) => hive.uuid === e.target.value);
                  let longitude: string | null = null;
                  let latitude: string | null = null;

                  // Try to set coordinates from hive
                  if (selectedHiveIndex >= 0) {
                    const selectedHive = this.getHiveList()[selectedHiveIndex];

                    if (selectedHive.coordinates) {
                      latitude = selectedHive.coordinates.latitude.toString();
                      longitude = selectedHive.coordinates.longitude.toString();
                    }
                  }

                  // Try to set coordinates from location
                  if (latitude === null && longitude === null && selectedLocationIndex >= 0) {
                    const selectedLocation = this.state.locations[selectedLocationIndex];

                    if (selectedLocation.coordinates) {
                      latitude = selectedLocation.coordinates.latitude.toString();
                      longitude = selectedLocation.coordinates.longitude.toString();
                    }
                  }

                  const locationTabDisabled = latitude !== null;

                  this.setState({
                    formData: {
                      ...this.state.formData,
                      hive: e.target.value,
                      device: null,
                      assignType: 'password',
                    },
                    assignDeviceList: this.getAssignDeviceList(e.target.value),
                    mapPosLatitude: latitude,
                    mapPosLongitude: longitude,
                    locationTabDisabled,
                  });

                  if (!locationTabDisabled) {
                    this.setCurrentLocation();
                  }
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Pozice - šířka"
                labelPlacement="stacked"
                disabled={!this.state.formData.hive}
                readonly
                required
                value={this.state.mapPosLatitude}
                error={this.state.formErrors.mapPosLatitude}
              >
                <IonButton
                  color="dark"
                  fill="clear"
                  slot="end"
                  className="ion-align-self-center"
                  disabled={!this.state.formData.hive || this.state.locationTabDisabled}
                  onClick={async () => {
                    this.setState({
                      createModalTab: 'map',
                      mapEditMode: true,
                    });
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    style={{ fontSize: '24px' }}
                    icon={mapOutline}
                  />
                </IonButton>
              </TextField>
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Pozice - výška"
                labelPlacement="stacked"
                disabled={!this.state.formData.hive}
                readonly
                required
                value={this.state.mapPosLongitude}
                error={this.state.formErrors.mapPosLongitude}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <Select
                label="Způsob přiřazení"
                name="assignType"
                disabled={!this.state.formData.hive}
                value={this.state.formData.assignType}
                error={this.state.formErrors.assignType}
                options={this.getAssignTypeOptions()}
                onIonChange={this.handleChangeFormData}
              />
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              {this.state.formData.assignType === 'password' ? (
                <TextField
                  label="Heslo"
                  name="password"
                  disabled={!this.state.formData.hive}
                  required
                  onIonInput={this.handleChangeFormData}
                  value={this.state.formData.password}
                  error={this.state.formErrors.password}
                />
              ) : (
                <Select
                  label="Seznam zařízení"
                  name="device"
                  disabled={!this.state.formData.hive}
                  required
                  value={this.state.formData.device}
                  error={this.state.formErrors.device}
                  options={this.getAssignDeviceOptions()}
                  onIonChange={this.handleChangeFormData}
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }

  renderCreateHiveForm() {
    return (
      <div className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Název úlu"
                name="name"
                required
                onIonInput={this.handleChangeFormData}
                value={this.state.formData.name}
                error={this.state.formErrors.name}
              >
                <IonButton
                  fill="clear"
                  slot="end"
                  className="ion-align-self-center"
                  onClick={() => {
                    this.setState({
                      openColorDialog: true,
                    });
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    icon={ellipseIcon}
                    style={{
                      fontSize: '24px',
                      color: this.state.formData.color,
                    }}
                  />
                </IonButton>
              </TextField>
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <Select
                label="Stanoviště"
                name="location"
                required
                value={this.state.formData.location}
                error={this.state.formErrors.location}
                options={this.state.locations.map((location) => ({
                  value: location.id,
                  text: location.name || '',
                }))}
                onIonChange={(e) => {
                  // Set location coordinates if defined
                  const selectedLocationIndex = this.state.locations.findIndex(
                    (location) => location.id === e.target.value,
                  );
                  let longitude: string | null = null;
                  let latitude: string | null = null;

                  if (selectedLocationIndex >= 0) {
                    const selectedLocation = this.state.locations[selectedLocationIndex];

                    if (selectedLocation.coordinates) {
                      latitude = selectedLocation.coordinates.latitude.toString();
                      longitude = selectedLocation.coordinates.longitude.toString();
                    }
                  }

                  this.setState({
                    mapPosLatitude: latitude,
                    mapPosLongitude: longitude,
                  });
                  this.handleChangeFormData(e);
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Pozice - šířka"
                labelPlacement="stacked"
                readonly={true}
                value={this.state.mapPosLatitude}
              >
                <IonButton
                  color="dark"
                  fill="clear"
                  slot="end"
                  className="ion-align-self-center"
                  onClick={async () => {
                    this.setState({
                      createModalTab: 'map',
                      mapEditMode: true,
                    });
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    style={{ fontSize: '24px' }}
                    icon={mapOutline}
                  />
                </IonButton>
              </TextField>
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Pozice - výška"
                labelPlacement="stacked"
                readonly={true}
                value={this.state.mapPosLongitude}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonModal
          isOpen={this.state.openColorDialog}
          onDidDismiss={() => {
            this.setState({
              openColorDialog: false,
            });
          }}
          className={styles.colorDialog}
        >
          <div className={styles.colorPicker}>
            <CirclePicker
              colors={circlePickerColors}
              color={this.state.formData.color}
              onChange={(result: ColorResult) => {
                this.setState({
                  formData: {
                    ...this.state.formData,
                    color: result.hex,
                  },
                  openColorDialog: false,
                });
              }}
            />
          </div>
        </IonModal>
      </div>
    );
  }

  renderUpdateLocationForm() {
    return (
      <div className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Název stanoviště"
                name="name"
                required
                onIonInput={this.handleChangeFormData}
                value={this.state.formData.name}
                error={this.state.formErrors.name}
              />
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Popis"
                name="description"
                onIonInput={this.handleChangeFormData}
                value={this.state.formData.description}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Pozice - šířka"
                labelPlacement="stacked"
                readonly={true}
                required
                value={this.state.mapPosLatitude}
                error={this.state.formErrors.mapPosLatitude}
              >
                <IonButton
                  color="dark"
                  fill="clear"
                  slot="end"
                  className="ion-align-self-center"
                  onClick={async () => {
                    this.setState({
                      createModalTab: 'map',
                      mapEditMode: true,
                    });
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    style={{ fontSize: '24px' }}
                    icon={mapOutline}
                  />
                </IonButton>
              </TextField>
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Pozice - výška"
                labelPlacement="stacked"
                readonly={true}
                required
                value={this.state.mapPosLongitude}
                error={this.state.formErrors.mapPosLongitude}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }

  renderMap() {
    var geolocationDefined = false;
    var positionDefined = false;
    var position = [49.821094376846396, 15.490835755296175];
    var zoom = 10;

    if (this.state.mapPosLatitude !== null && this.state.mapPosLongitude !== null) {
      positionDefined = true;
      const lat = Number.parseFloat(this.state.mapPosLatitude);
      const lon = Number.parseFloat(this.state.mapPosLongitude);

      if (!isNaN(lat) && !isNaN(lon)) {
        position = [lat, lon];
        zoom = 17;
      }
    }

    if (this.state.mapGeolocation !== null) {
      geolocationDefined = true;

      if (!positionDefined) {
        position = this.state.mapGeolocation;
        zoom = 17;
      }
    }

    return (
      <MapContainer
        center={position as any}
        zoom={zoom}
        className={styles.mapContainer}
        ref={(map) => {
          this.mapRef = map;

          if (map == null) {
            return;
          }

          (this.mapRef as L.Map).on('click', (e) => {
            if ((e.originalEvent.target as any).tagName.toLowerCase() === 'ion-button') {
              return;
            }

            if (!this.state.mapEditMode) {
              return;
            }

            this.setState({
              mapPosLatitude: e.latlng.lat.toString(),
              mapPosLongitude: e.latlng.lng.toString(),
              mapEditMode: false,
            });
          });
        }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {positionDefined && (
          <Marker
            position={position as any}
            icon={DefaultIcon}
          />
        )}

        {geolocationDefined && (
          <CircleMarker
            center={this.state.mapGeolocation as any}
            fillColor="#269aff"
            fillOpacity={1.0}
            color="white"
            stroke={true}
            radius={7}
          />
        )}
        <div className={styles.mapToolbar}>
          <IonButton
            color="light"
            onClick={(e) => {
              this.setState({
                mapEditMode: !this.state.mapEditMode,
              });
            }}
          >
            <IonIcon
              slot="icon-only"
              icon={this.state.mapEditMode ? closeOutline : createOutline}
            ></IonIcon>
          </IonButton>
          <IonButton
            color="light"
            onClick={() => this.setCurrentLocation(true)}
          >
            <IonIcon
              slot="icon-only"
              icon={locateOutline}
            ></IonIcon>
          </IonButton>
        </div>
      </MapContainer>
    );
  }

  renderModalContent() {
    if (this.state.createModalTab === 'form') {
      switch (this.state.createModalMode) {
        case 'hive':
          return this.renderCreateHiveForm();
        case 'location':
          return this.renderUpdateLocationForm();
        case 'assign_device':
          return this.renderAssignDeviceForm();
      }
    }

    return this.renderMap();
  }

  renderModal() {
    let createModalTitle: string = '';
    let createModalHandler: any;

    switch (this.state.createModalMode) {
      case 'hive':
        createModalTitle = 'Nový úl';
        createModalHandler = this.handleHiveCreate;
        break;
      case 'location':
        createModalTitle = this.state.formData.id ? 'Úprava stanoviště' : 'Nové stanoviště';
        createModalHandler = this.handleLocationUpdate;
        break;
      case 'assign_device':
        createModalTitle = 'Přidat zařízení';
        createModalHandler = this.handleAssignDevice;
    }

    return (
      <IonModal
        onDidDismiss={this.handleModalDismiss}
        isOpen={this.state.createModalOpen}
      >
        <IonHeader>
          <IonToolbar className="modal-toolbar">
            <IonButtons slot="start">
              <IonButton onClick={this.handleModalDismiss}>Zrušit</IonButton>
            </IonButtons>
            <IonTitle>{createModalTitle}</IonTitle>
            <IonButtons slot="end">
              <IonButton
                strong={true}
                disabled={this.state.loading}
                onClick={createModalHandler}
              >
                OK
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="modal-content">
          <div className={styles.modalContent}>
            <IonRow>
              <IonCol className="ion-no-padding">
                <NavButton
                  btnKey="form"
                  text="Informace"
                  onClick={this.handleModalNavClick}
                  value={this.state.createModalTab}
                />
              </IonCol>
              <IonCol className="ion-no-padding">
                <NavButton
                  btnKey="map"
                  text="Lokalizace"
                  disabled={this.state.locationTabDisabled}
                  onClick={this.handleModalNavClick}
                  value={this.state.createModalTab}
                />
              </IonCol>
            </IonRow>
            <div className={styles.modalTabContent}>{this.renderModalContent()}</div>
          </div>
        </IonContent>
      </IonModal>
    );
  }

  renderAlert() {
    return <IonAlert {...this.state.alert} />;
  }

  render() {
    return (
      <IonPage>
        <Header title={pageTitle} />
        <IonContent>
          {Capacitor.getPlatform() !== 'web' && (
            <IonRefresher
              slot="fixed"
              onIonRefresh={async (e) => {
                await this.fetchData({ forceUpdate: true, showSuccessMessage: true });
                e.detail.complete();
              }}
            >
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
          )}
          <div className={styles.container}>
            <div className={styles.content}>
              <IonSearchbar
                placeholder="Vyhledat úl podle názvu..."
                debounce={1000}
                onIonInput={this.onSearchValueChange}
                value={this.state.search}
              />
              <div className={styles.locationListContainer}>
                <LocationList
                  devices={this.state.devices}
                  locationList={this.state.locations}
                  hiveList={this.getHiveList()}
                  latestTelemetry={this.state.latestTelemetry}
                  customButtons={this.getLocationCustomButtons()}
                  onHiveClick={this.onHiveClick}
                  search={this.state.search}
                />
              </div>
            </div>
          </div>
        </IonContent>
        {this.renderModal()}
        {this.renderFabs()}
        {this.renderAlert()}
      </IonPage>
    );
  }
}

export default compose(
  withHistory,
  connect((state: StoreState) => ({
    isPublic: state.isPublic,
  })),
)(Home);
