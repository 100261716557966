import { Filesystem } from '@capacitor/filesystem';

const registerFilesystem = async () => {
  let permStatus = await Filesystem.checkPermissions();

  if (permStatus.publicStorage === 'prompt') {
    permStatus = await Filesystem.requestPermissions();
  }

  if (permStatus.publicStorage !== 'granted') {
    throw new Error('User denied permissions!');
  }
};

export { registerFilesystem };
