import { DeviceComplete, DevicePutRequest, HiveStateResponse } from '@api/models/DeviceModel';
import DeviceService from '@api/services/DeviceService';
import DateModal from '@components/DateModal';
import HiveAttachment from '@components/HiveAttachment';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption } from '@ionic/react';
import { StateEnum, StateText } from '@lib/data/Device/typeDefs';
import NotificationUtil from '@util/Notification';
import equal from 'fast-deep-equal';
import { calendarOutline as CalendarIcon, saveOutline as SaveIcon } from 'ionicons/icons';
import moment from 'moment';
import * as React from 'react';
import styles from './styles.module.css';

interface StateListProps {
  hive: DeviceComplete;
  readonly: boolean;
  onStateUpdate?: () => void;
  updateDate?: string;
}

interface StateListState {
  activeItem: StateEnum | null;
  value: any;
  stateData: Record<string, any>;
  loading: boolean;
  swarmingDateModalOpen: boolean;
  swarmingDate: any;
}

const readonlyList: StateEnum[] = [StateEnum.UPDATE_DATE];

const frameMeasureList: string[] = [
  'Langstroth 2/3',
  'Langstroth 3/4',
  'Adamec 39x24',
  'Adamec 39x17',
  'Čechoslovák 37x30',
  'Optimal',
  'Dadant',
];

export class StateList extends React.Component<StateListProps, StateListState> {
  private activeItemRef: HTMLIonListElement | null = null;
  private isSelectOpen: boolean = false;
  private isModalOpen: boolean = false;

  static defaultProps = {
    readonly: false,
  };

  constructor(props: StateListProps) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleItemSave = this.handleItemSave.bind(this);
    this.handleClickOutsideInput = this.handleClickOutsideInput.bind(this);
    this.handleSwarmingDateChange = this.handleSwarmingDateChange.bind(this);

    const currentDate = moment().format('YYYY-MM-DDTHH:mm:ss');

    this.state = {
      activeItem: null,
      value: '',
      stateData: {},
      loading: false,
      swarmingDateModalOpen: false,
      swarmingDate: currentDate,
    };
  }

  setHiveStateData() {
    this.setState({
      stateData: {
        [StateEnum.UPDATE_DATE]: '',
        [StateEnum.ATTACHMENTS]: this.props.hive.hive_state.attachments,
        [StateEnum.FEEDING]: this.props.hive.hive_state.feeding,
        [StateEnum.FRAME_MEASURE]: this.props.hive.frontend_settings?.frame_measure,
        [StateEnum.HARVEST]: this.props.hive.hive_state.harvest,
        [StateEnum.MOTHER]: this.props.hive.hive_state.mother,
        [StateEnum.SWARMING]: this.props.hive.hive_state.swarming,
      },
    });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideInput);
    this.setHiveStateData();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideInput);
  }

  componentDidUpdate(prevProps: StateListProps) {
    if (prevProps.hive.uuid !== this.props.hive.uuid || !equal(prevProps.hive.hive_state, this.props.hive.hive_state)) {
      this.setHiveStateData();
    }
  }

  handleClickOutsideInput(event: MouseEvent) {
    if (
      this.activeItemRef &&
      event.target instanceof Node &&
      !this.activeItemRef.contains(event.target) &&
      !this.isSelectOpen &&
      !this.isModalOpen
    ) {
      this.setState({
        activeItem: null,
        value: '',
      });
    }
  }

  async saveState(key: StateEnum, value: any) {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      if (key === StateEnum.FRAME_MEASURE) {
        const updatedHive: DevicePutRequest = {
          frontend_settings: {
            ...this.props.hive.frontend_settings,
            frame_measure: value,
          },
        };

        await DeviceService.deviceUpdate(this.props.hive.uuid, updatedHive);
      } else {
        const updatedState: HiveStateResponse = {
          ...this.props.hive.hive_state,
          [key]: value,
        };

        await DeviceService.deviceUpdateState(this.props.hive.uuid, updatedState);
      }

      NotificationUtil.success(`Položka "${StateText[key]}" byla úspěšně aktualizována`);

      this.setState({
        stateData: {
          ...this.state.stateData,
          [key]: value,
        },
      });

      if (this.props.onStateUpdate) {
        this.props.onStateUpdate();
      }
    } catch (e) {
      this.setHiveStateData();
      NotificationUtil.error('Neočekávaná chyba při ukládání dat');
    }
  }

  async handleItemSave() {
    if (this.state.activeItem === null) {
      return;
    }

    let value: any = this.state.value;
    let isChanged = false;

    switch (this.state.activeItem) {
      case StateEnum.FRAME_MEASURE:
        const oldFrameMeasure = this.state.stateData.frame_measure || null;

        if (oldFrameMeasure !== value) {
          isChanged = true;
        }

        break;
      case StateEnum.UPDATE_DATE:
        break;
      case StateEnum.SWARMING:
        const date = moment(this.state.value, 'DD.MM.YYYY');

        if (!date.isValid()) {
          break;
        }

        value = date.valueOf() / 1000;

        if (this.state.stateData.swarming !== value) {
          isChanged = true;
        }

        break;
      default:
        const val = Number.parseFloat(this.state.value);

        if (isNaN(val)) {
          break;
        }

        value = val;

        if (this.state.stateData[this.state.activeItem] !== value) {
          isChanged = true;
        }

        break;
    }

    if (isChanged) {
      await this.saveState(this.state.activeItem, value);
    }

    this.setState({
      activeItem: null,
      value: '',
      loading: false,
    });
  }

  handleClick = (key: StateEnum) => {
    if (this.props.readonly) {
      return;
    }

    if (readonlyList.indexOf(key) >= 0) {
      return;
    }

    let val: any = '';
    let swarmingDateValue: any = this.state.swarmingDate;

    switch (key) {
      case StateEnum.FRAME_MEASURE:
        val = this.state.stateData.frame_measure || null;
        break;
      case StateEnum.UPDATE_DATE:
        break;
      case StateEnum.SWARMING:
        const swarmingDate = this.state.stateData.swarming;
        val = '';

        if (typeof swarmingDate === 'number') {
          const swarmingDateObj = moment(swarmingDate * 1000);
          val = swarmingDateObj.format('DD.MM.YYYY');
          swarmingDateValue = swarmingDateObj.format('YYYY-MM-DDTHH:mm:ss');
        }

        break;
      default:
        val = typeof this.state.stateData[key] === 'number' ? this.state.stateData[key] : '';
        break;
    }

    this.setState({
      activeItem: key,
      value: val,
      swarmingDate: swarmingDateValue,
    });
  };

  isDateEnabled(dateString: string) {
    const date = moment(dateString);
    const now = moment().endOf('day');

    if (date.isAfter(now)) {
      return false;
    }

    return true;
  }

  handleSwarmingDateChange(e: any) {
    let val = e.target.value;

    this.setState({
      value: moment(val).format('DD.MM.YYYY'),
      swarmingDate: val,
    });
  }

  getMotherColor(val: number | undefined) {
    if (typeof val === 'undefined' || val < 1000 || val > 9999) {
      return;
    }

    const lastDigit = val % 10;
    let color: string | undefined = undefined;

    switch (lastDigit) {
      case 1:
      case 6:
        color = '#ffffff';
        break;
      case 2:
      case 7:
        color = '#ffff00';
        break;
      case 3:
      case 8:
        color = '#ff0000';
        break;
      case 4:
      case 9:
        color = '#00ff00';
        break;
      case 5:
      case 0:
        color = '#0000ff';
        break;
    }

    return color;
  }

  renderStateValue(key: StateEnum) {
    let value: any = '';

    switch (key) {
      case StateEnum.UPDATE_DATE:
        value = this.props.updateDate || '';
        break;
      case StateEnum.FRAME_MEASURE:
        value = this.state.stateData.frame_measure || '';
        break;
      case 'harvest':
      case 'feeding':
        const val = this.state.stateData[key];

        if (typeof val === 'number' && val !== 0) {
          value = `${val.toFixed(1)} kg`;
        }
        break;
      case 'swarming':
        const swarmingDate = this.state.stateData.swarming;
        value = typeof swarmingDate === 'number' ? moment(swarmingDate * 1000).format('DD.MM.YYYY') : '';
        break;
      case 'attachments':
        const attachments = typeof this.state.stateData.attachments === 'number' ? this.state.stateData.attachments : 0;

        if (attachments > 0) {
          value = (
            <div className={styles.stateValueBlock}>
              <div>{attachments}</div>
              <HiveAttachment
                size={0.5}
                amount={attachments}
              />
            </div>
          );
        }
        break;
      case 'mother':
        const mother = this.state.stateData.mother;

        if (typeof mother === 'number') {
          const motherColor = this.getMotherColor(mother);

          value = (
            <div className={styles.stateValueBlock}>
              <div>{mother}</div>
              {motherColor && (
                <div
                  className={styles.motherCircle}
                  style={{
                    backgroundColor: motherColor,
                  }}
                ></div>
              )}
            </div>
          );
        }
        break;
    }

    return value;
  }

  renderStateInput(key: StateEnum, text: string) {
    return (
      <IonInput
        value={this.state.value}
        onIonInput={(e) => {
          this.setState({
            value: e.target.value,
          });
        }}
        ref={(el) => {
          if (!el) {
            return;
          }

          setTimeout(() => {
            // Focus text
            el.setFocus();
          }, 1);
        }}
        readonly={key === StateEnum.SWARMING}
        className={styles.stateInput}
      >
        <IonLabel
          slot="label"
          className={styles.stateLabel}
        >
          {text}
        </IonLabel>
        {key === StateEnum.SWARMING && (
          <React.Fragment>
            <IonButton
              fill="clear"
              slot="end"
              color="dark"
              className="ion-align-self-center"
              onClick={() => {
                this.isModalOpen = true;
                this.setState({
                  swarmingDateModalOpen: true,
                });
              }}
            >
              <IonIcon
                slot="icon-only"
                icon={CalendarIcon}
                style={{
                  fontSize: '20px',
                }}
              />
            </IonButton>
            <DateModal
              open={this.state.swarmingDateModalOpen}
              onWillDismiss={() => {
                this.isModalOpen = false;
                this.setState({
                  swarmingDateModalOpen: false,
                });
              }}
              datetimeProps={{
                presentation: 'date',
                onIonChange: this.handleSwarmingDateChange,
                isDateEnabled: this.isDateEnabled,
                value: this.state.swarmingDate,
              }}
            />
          </React.Fragment>
        )}
      </IonInput>
    );
  }

  renderStateSelect(key: StateEnum, text: string) {
    return (
      <IonSelect
        onClick={() => {
          this.isSelectOpen = true;
        }}
        onIonDismiss={() => {
          this.isSelectOpen = false;
        }}
        placeholder="Vybrat"
        justify="start"
        className={styles.stateSelect}
        value={this.state.value}
        onIonChange={(e) => {
          this.setState({
            value: e.target.value,
          });
        }}
      >
        <IonLabel
          slot="label"
          className={styles.stateLabel}
        >
          {text}
        </IonLabel>
        {frameMeasureList.map((item, index) => (
          <IonSelectOption
            key={index}
            value={item}
          >
            {item}
          </IonSelectOption>
        ))}
      </IonSelect>
    );
  }

  renderStateItemEdit(key: StateEnum, text: string) {
    return (
      <IonItem
        key={key}
        className={styles.stateItem}
      >
        {key === StateEnum.FRAME_MEASURE ? this.renderStateSelect(key, text) : this.renderStateInput(key, text)}
        <IonButton
          disabled={this.state.loading}
          onClick={this.handleItemSave}
          size="small"
          slot="end"
        >
          <IonIcon icon={SaveIcon}></IonIcon>
        </IonButton>
      </IonItem>
    );
  }

  renderStateItem(key: StateEnum, text: string) {
    return (
      <IonItem
        key={key}
        button={key !== StateEnum.UPDATE_DATE && !this.props.readonly}
        onClick={() => {
          this.handleClick(key);
        }}
        className={key === StateEnum.UPDATE_DATE ? `${styles.stateItem} ion-hide-md-down` : styles.stateItem}
      >
        <IonLabel className={styles.stateLabel}>{text}</IonLabel>
        <div slot="end">
          <div className={styles.stateValue}>{this.renderStateValue(key)}</div>
        </div>
      </IonItem>
    );
  }

  render() {
    return (
      <IonList
        ref={(el) => {
          this.activeItemRef = el;
        }}
        lines="full"
        className={styles.stateList}
      >
        {Object.keys(StateText).map((key) => {
          if (this.state.activeItem === key) {
            return this.renderStateItemEdit(key as StateEnum, StateText[key]);
          }

          return this.renderStateItem(key as StateEnum, StateText[key]);
        })}
      </IonList>
    );
  }
}

export default StateList;
