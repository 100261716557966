import { DeviceComplete } from '@api/models/DeviceModel';
import { User } from '@api/models/UserModel';
import UserService from '@api/services/UserService';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import LineChart from '@components/LineChart';
import { IonFab, IonFabButton, IonFabList, IonIcon } from '@ionic/react';
import { SensorEnum } from '@lib/data/Device/typeDefs';
import { storageSet } from '@src/storage';
import store, { StoreState } from '@src/store';
import BaseUtils from '@util/BaseUtils';
import NotificationUtil from '@util/Notification';
import { Chart as ChartJS, ChartData, ChartEvent, LegendElement, LegendItem } from 'chart.js';
import 'chartjs-adapter-date-fns';
import merge from 'deepmerge';
import {
  analyticsOutline as AnalyticsIcon,
  contractOutline as ContractIcon,
  eyeOffOutline as EyeOffIcon,
  eyeOutline as EyeIcon,
  gridOutline as GridIcon,
  imageOutline as ImageIcon,
  scanOutline as ScanIcon,
  squareOutline as SquareIcon
} from 'ionicons/icons';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.css';

interface ChartTabProps {
  fullscreen?: boolean;
  graph_interval?: number;
  graph_interval_last?: number;
  graph_is_custom_last?: boolean;
  graph_mode?: string;
  graph_time_start?: string | null;
  graph_time_end?: string | null;
  graph_interval_from?: string | null;
  hive: DeviceComplete;
  user: User;
  sensorTelemetry: any;
  sensorTelemetryUpdate: number;
  onChartClick?: (data: any) => void;
  onZoomComplete?: (chart: any) => void;
  showScales?: boolean;
  onUserUpdate?: () => void;
}

interface ChartTabState {
  merge: boolean;
}

const lineOptions = {
  scales: {
    y1: {
      position: 'right' as const,
      ticks: {
        callback: (value: any) => {
          return value + ' °C';
        },
      },
    },
    y2: {
      ticks: {
        callback: (value: any) => {
          return value + ' kg';
        },
      },
    },
    y3: {
      position: 'right' as const,
      ticks: {
        callback: (value: any) => {
          return value + ' %';
        },
      },
    },
  },
};

const lineData: ChartData<'line'> = {
  datasets: [
    {
      label: 'Hmotnost',
      data: [],
      borderColor: 'rgb(63, 0, 163)',
      backgroundColor: 'rgba(63, 0, 163, 0.5)',
      yAxisID: 'y2',
      tension: 0.4,
    },
    {
      label: 'Teplota v úlu',
      data: [],
      borderColor: 'rgb(201, 30, 30)',
      backgroundColor: 'rgba(201, 30, 30, 0.5)',
      yAxisID: 'y1',
      tension: 0.4,
    },
    {
      label: 'Vlhkost v úlu',
      data: [],
      borderColor: 'rgb(36, 39, 255)',
      backgroundColor: 'rgba(36, 39, 255, 0.5)',
      yAxisID: 'y3',
      tension: 0.4,
    },
    {
      label: 'Teplota na stanovišti',
      data: [],
      borderColor: 'rgb(255, 187, 0)',
      backgroundColor: 'rgba(255, 187, 0, 0.5)',
      yAxisID: 'y1',
      tension: 0.4,
    },
  ],
};

const datasetSensors = [
  SensorEnum.HIVE_WEIGHT,
  SensorEnum.HIVE_TEMPERATURE,
  SensorEnum.HIVE_HUMIDITY,
  SensorEnum.LOCATION_TEMPERATURE,
];

export class ChartTab extends React.Component<ChartTabProps, ChartTabState> {
  private chart: ChartJS<'line'> | undefined | null = null;

  constructor(props: ChartTabProps) {
    super(props);

    this.handleChartDoubleClick = this.handleChartDoubleClick.bind(this);
    this.handleViewModeChange = this.handleViewModeChange.bind(this);
    this.handleChartExport = this.handleChartExport.bind(this);
    this.handleFullscreenModeChange = this.handleFullscreenModeChange.bind(this);
    this.handleChartRef = this.handleChartRef.bind(this);
    this.handleChartClick = this.handleChartClick.bind(this);
    this.handleChangeScales = this.handleChangeScales.bind(this);
    this.handleLegendClick = this.handleLegendClick.bind(this);

    this.state = {
      merge: Capacitor.getPlatform() === 'web',
    };
  }

  async handleLegendClick(_e: ChartEvent, _legendItem: LegendItem, legend: LegendElement<'line'>) {
    const frontendSettings = this.props.user.frontend_settings || {};
    const legendItems = legend.legendItems || [];

    try {
      await UserService.userUpdate({
        ...this.props.user,
        frontend_settings: {
          ...frontendSettings,
          hidden_sensors: legendItems
            .filter((item): item is Required<LegendItem> => !!item.hidden && typeof item.datasetIndex !== 'undefined')
            .map((item) => datasetSensors[item.datasetIndex]),
        },
      });

      if (this.props.onUserUpdate) {
        this.props.onUserUpdate();
      }
    } catch (e) {
      NotificationUtil.error('Nepodařilo se uložit nastavení grafu');
    }
  }

  async handleChartDoubleClick() {
    if (this.props.graph_mode === 'realtime') {
      return;
    }

    const data: any = {
      graph_interval: this.props.graph_interval_last,
      graph_agg_func: 'none',
      graph_mode: 'realtime',
      graph_time: false,
      graph_time_start: null,
      graph_time_end: null,
    };

    if (!this.props.graph_is_custom_last) {
      data.graph_custom_interval = null;
    }

    store.dispatch({
      type: 'set',
      payload: data,
    });

    for (const dataKey in data) {
      await storageSet(dataKey, data[dataKey]);
    }
  }

  handleChartClick(ref: any, e: any) {
    const elements = ref.getElementsAtEventForMode(e, 'x', ref.options);

    if (!elements.length) {
      return;
    }

    const chartData = this.getChartData();
    const { datasetIndex, index } = elements[0];
    const d = chartData.datasets[datasetIndex].data[index];

    if (this.props.onChartClick) {
      this.props.onChartClick(d);
    }
  }

  handleViewModeChange() {
    this.setState({
      merge: !this.state.merge,
    });
  }

  async handleChartExport() {
    if (!this.chart) {
      return;
    }

    const path = this.chart.toBase64Image();
    const filename = `graph-${moment().format('DD-MM-YYY_HH-mm')}.png`;

    if (Capacitor.getPlatform() !== 'web') {
      const data = await BaseUtils.stringFromBase64(path);
      await Filesystem.writeFile({
        path: filename,
        data,
        directory: Directory.Cache,
      }).then((savedFile) => {
        Share.share({ url: savedFile.uri });
      });
    } else {
      const link = document.createElement('a');
      link.download = filename;
      link.target = '_blank';
      link.href = path;
      link.click();
    }
  }

  handleChartRef(chart: ChartJS<'line'> | undefined | null) {
    this.chart = chart;
  }

  handleFullscreenModeChange() {
    store.dispatch({
      type: 'set',
      payload: {
        fullscreen: !this.props.fullscreen,
      },
    });
  }

  async handleChangeScales() {
    const showScales = !this.props.showScales;

    store.dispatch({
      type: 'set',
      payload: {
        showScales: showScales,
      },
    });

    await storageSet('showScales', showScales);
  }

  getChartData() {
    const chartData: ChartData<'line'> = {
      datasets: [],
    };

    const frontendSettings = this.props.user.frontend_settings || {};
    const hiddenSensors = Array.isArray(frontendSettings.hidden_sensors) ? frontendSettings.hidden_sensors : [];

    for (let i = 0; i < lineData.datasets.length; i++) {
      const dataset = lineData.datasets[i];
      const sensorNumber = datasetSensors[i];

      chartData.datasets.push({
        ...dataset,
        ...(hiddenSensors.indexOf(datasetSensors[i]) >= 0 && { hidden: true }),
        data:
          !this.props.hive.isGroupItem ||
          [SensorEnum.HIVE_TEMPERATURE, SensorEnum.HIVE_HUMIDITY].indexOf(sensorNumber) < 0
            ? this.props.sensorTelemetry[sensorNumber] || []
            : [],
      })
    }

    return chartData;
  }

  renderFabs() {
    return (
      <IonFab
        className={styles.fabButton}
        slot="fixed"
        vertical="bottom"
        horizontal="end"
      >
        <IonFabButton>
          <IonIcon icon={AnalyticsIcon}></IonIcon>
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton
            onClick={this.handleViewModeChange}
            data-desc={this.state.merge ? 'Rozdělit graf' : 'Spojit grafy'}
          >
            <IonIcon
              color="dark"
              icon={this.state.merge ? GridIcon : SquareIcon}
            ></IonIcon>
          </IonFabButton>
          {this.state.merge && (
            <IonFabButton
              onClick={this.handleChartExport}
              data-desc="Exportovat graf"
            >
              <IonIcon
                color="dark"
                icon={ImageIcon}
              ></IonIcon>
            </IonFabButton>
          )}
          <IonFabButton
            onClick={this.handleFullscreenModeChange}
            data-desc={this.props.fullscreen ? 'Zavřít' : 'Zobrazit na celou obrazovku'}
          >
            <IonIcon
              color="dark"
              icon={this.props.fullscreen ? ContractIcon : ScanIcon}
            ></IonIcon>
          </IonFabButton>
          <IonFabButton
            onClick={this.handleChangeScales}
            data-desc={this.props.showScales ? 'Skrýt osy na grafu' : 'Zobrazit osy na grafu'}
          >
            <IonIcon
              color="dark"
              icon={this.props.showScales ? EyeOffIcon : EyeIcon}
            ></IonIcon>
          </IonFabButton>
        </IonFabList>
      </IonFab>
    );
  }

  renderChart() {
    const currentTime = moment();
    let startTime = this.props.graph_time_start;
    let endTime = this.props.graph_time_end;

    if (this.props.graph_mode !== 'history') {
      endTime = currentTime.toISOString();

      if (this.props.graph_interval_from) {
        startTime = this.props.graph_interval_from;
      } else {
        startTime = currentTime.subtract(this.props.graph_interval!! / 1000, 'seconds').toISOString();
      }
    }

    return (
      <LineChart
        onLineRef={this.handleChartRef}
        options={merge(lineOptions, {
          scales: {
            x: {
              min: startTime || undefined,
              max: endTime || undefined,
            },
          },
        })}
        data={this.getChartData()}
        lastUpdate={this.props.sensorTelemetryUpdate}
        merge={this.state.merge}
        onClick={this.handleChartClick}
        onDoubleClick={this.handleChartDoubleClick}
        onZoomComplete={this.props.onZoomComplete}
        onLegendClick={this.handleLegendClick}
      />
    );
  }

  render() {
    let chartTabClass = styles.chartTab;

    if (this.props.fullscreen) {
      chartTabClass += ` ${styles.chartTabFullscreen}`;
    } else if (!this.state.merge) {
      chartTabClass += ` ${styles.chartTabLarge}`;
    }

    return (
      <div className={chartTabClass}>
        {this.renderChart()}
        {this.renderFabs()}
      </div>
    );
  }
}

export default connect((state: StoreState) => ({
  graph_interval: state.graph_interval,
  graph_interval_last: state.graph_interval_last,
  graph_is_custom_last: state.graph_is_custom_last,
  graph_mode: state.graph_mode,
  graph_time_start: state.graph_time_start,
  graph_time_end: state.graph_time_end,
  graph_interval_from: state.graph_interval_from,
  fullscreen: state.fullscreen,
  showScales: state.showScales,
}))(ChartTab);
