import { DeviceComplete } from '@api/models/DeviceModel';
import HiveAttachment from '@components/HiveAttachment';
import Icon from '@components/Icon';
import { IonCol, IonRow, IonText } from '@ionic/react';
import getConnectedDevices from '@lib/data/Device/getConnectedDevices';
import getSensorValue, { getHiveBatteryValue, getHiveLocationValue } from '@lib/data/Device/getSensorValue';
import { DeviceTelemetry, INACTIVE_SENSOR_COLOR, SensorEnum } from '@lib/data/Device/typeDefs';
import * as React from 'react';
import styles from './styles.module.css';

interface HiveItemProps {
  hive: DeviceComplete;
  devices: DeviceComplete[];
  telemetry: DeviceTelemetry;
  inactiveSensors: SensorEnum[];
}

interface HiveItemState {}

export class HiveItem extends React.Component<HiveItemProps, HiveItemState> {
  static defaultProps = {
    inactiveSensors: [],
  };

  renderSensor(number: SensorEnum, noValue: boolean = false) {
    let sensor = getSensorValue(number, this.props.telemetry, this.props.hive.sensors || []);
    let textValue: React.ReactNode = sensor.formatValue;

    if (number === SensorEnum.HIVE_WEIGHT) {
      const weightGain = getSensorValue(SensorEnum.WEIGHT_GAIN, this.props.telemetry, this.props.hive.sensors || []);
      const weightGain2 = this.props.hive.weightGain;

      if (weightGain)
        textValue = (
          <React.Fragment>
            {textValue}{' '}
            <div style={{ fontSize: '14px' }}>
              (<span style={{ color: weightGain.color }}>{weightGain.formatValue}</span>
              {weightGain2 && <span style={{ color: weightGain2.color }}>{`, ${weightGain2.formatValue}`}</span>})
            </div>
          </React.Fragment>
        );
    }

    if (number === SensorEnum.BATTERY_VOLTAGE) {
      sensor = getHiveBatteryValue(this.props.hive, this.props.devices);
    }

    if (number === SensorEnum.MOVEMENT) {
      sensor = getHiveLocationValue(this.props.hive);
      textValue = sensor.formatValue;
    }

    if (this.props.inactiveSensors.indexOf(number) >= 0) {
      sensor.color = INACTIVE_SENSOR_COLOR;
      textValue = sensor.unit ? `- ${sensor.unit}` : '-';
    }

    return (
      <div className={styles.sensorBlock}>
        <Icon
          iconName={sensor.icon as any}
          color={sensor.color}
          size={26}
        />
        {!noValue && <IonText className={styles.sensorValue}>{textValue}</IonText>}
      </div>
    );
  }

  render() {
    const hiveName = this.props.hive.frontend_settings?.custom_name || '';
    const hiveAttachments = this.props.hive.hive_state.attachments || 0;
    const connectedDevices = getConnectedDevices(this.props.hive, this.props.devices);

    return (
      <IonRow className="ion-align-items-center">
        <IonCol
          size="3"
          className={styles.itemNameBlock}
        >
          <IonText className={styles.itemName}>{hiveName}</IonText>
          {connectedDevices.map((device, index) => (
            <Icon
              key={index}
              iconName={device.icon as any}
              size={14}
              color={device.color}
            />
          ))}
        </IonCol>
        <IonCol size="2">
          <HiveAttachment amount={hiveAttachments} />
        </IonCol>
        <IonCol size="7">
          <IonRow className="ion-align-items-center">
            <IonCol size="4">{this.renderSensor(SensorEnum.HIVE_WEIGHT)}</IonCol>
            <IonCol>{this.renderSensor(SensorEnum.HIVE_TEMPERATURE)}</IonCol>
            <IonCol>{this.renderSensor(SensorEnum.HIVE_HUMIDITY)}</IonCol>
            <IonCol>{this.renderSensor(SensorEnum.MOVEMENT)}</IonCol>
            <IonCol size="auto">{this.renderSensor(SensorEnum.BATTERY_VOLTAGE, true)}</IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    );
  }
}

export default HiveItem;
