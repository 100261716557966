import DeviceService from '@api/services/DeviceService';
import moment from 'moment';

export const getTelemetry = async (opts: any) => {
  let result: any = [];

  if (!opts.uuid || !opts.dateStart || !opts.dateEnd) {
    return result;
  }

  let startTime = moment(opts.dateStart);
  let endTime = moment(opts.dateEnd);

  if (endTime.isBefore(startTime)) {
    return result;
  }

  let maxRequestTime = 1209600; // 14 days in seconds by default
  let t = startTime.clone();
  let tDuration = moment.duration(endTime.diff(startTime)).asSeconds();
  let interval = tDuration > maxRequestTime ? maxRequestTime : tDuration;

  while (1) {
    const from = t.toISOString();
    const to = t.add(interval, 'seconds').toISOString();
    const data = await DeviceService.devicesTelemetryList4(from, to, opts.uuid);
    result = [...result, ...data];

    const diff = moment.duration(endTime.diff(t)).asSeconds();
    if (diff === 0) {
      break;
    } else if (diff < interval) {
      interval = diff;
    }
  }

  return result;
};

export default getTelemetry;
