import * as bootstrapIcons from 'react-bootstrap-icons';

export enum CustomIcons {
  KETTLEBELL = 'Kettlebell',
}

export type CustomIconsType = `${CustomIcons}`;
export type BootstrapIconsType = keyof typeof bootstrapIcons;
export type IconsType = BootstrapIconsType | CustomIconsType;

export const paths: Record<CustomIcons, string | Array<string>> = {
  [CustomIcons.KETTLEBELL]:
    'M 8.0371094,0 C 6.0373469,0 4.0381035,0.01551582 2.8046875,0.446 c -0.5501568,0.19201451 -0.5501568,2.1100324 0,2.3020469 C 4.0381035,3.178531 5,3.2148438 5,3.2148438 V 5 L 3.6601562,5.0996094 C 2.9179748,5.0997732 2.1865095,6.04341 2.0761719,6.7773438 L 1.7773438,13.130859 C 1.5512169,14.641917 2.7943814,15.999714 4.3222656,16 h 7.3554684 c 1.528288,3.25e-4 2.771109,-1.357684 2.544922,-2.869141 L 13.921875,6.6777344 C 13.811594,5.9441753 12.882427,5.1002677 12.140625,5.0996094 L 11,5 V 3.2246094 c 0,0 1.654776,-0.2613205 2.271484,-0.4765625 0.550157,-0.1920146 0.550157,-2.11003239 0,-2.3020469 C 12.038068,0.01551582 10.036872,0 8.0371094,0 Z',
};

export default paths;
