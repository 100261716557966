import { DeviceComplete } from '@api/models/DeviceModel';
import { Location } from '@api/models/LocationModel';
import Icon from '@components/Icon';
import { IonAccordion, IonAccordionGroup, IonButton, IonItem, IonLabel, IonText } from '@ionic/react';
import getSensorValue from '@lib/data/Device/getSensorValue';
import { SensorEnum, SensorValue } from '@lib/data/Device/typeDefs';
import BaseUtils from '@util/BaseUtils';
import * as React from 'react';
import HiveItem from './HiveItem';
import HiveItemMobile from './HiveItemMobile';
import styles from './styles.module.css';
import { CustomButton } from './typeDefs';

interface LocationListProps {
  devices: DeviceComplete[];
  locationList: Location[];
  hiveList: DeviceComplete[];
  latestTelemetry: any[];
  customButtons: CustomButton[];
  onHiveClick?: (hive: DeviceComplete) => void;
  selectedHive?: string;
  search: string;
  showEmpty: boolean;
}

interface LocationListState {
  accordionGroupWidth: number;
  isMounted: boolean;
}

// Breakpoint for mobile devices
const MOBILE_BREAKPOINT = 768;

export class LocationList extends React.Component<LocationListProps, LocationListState> {
  static defaultProps: LocationListProps = {
    devices: [],
    locationList: [],
    hiveList: [],
    latestTelemetry: [],
    customButtons: [],
    search: '',
    showEmpty: true,
  };

  private resizeObserver: ResizeObserver | null = null;
  private accordionGroupRef = React.createRef<HTMLIonAccordionGroupElement>();

  constructor(props: LocationListProps) {
    super(props);

    this.renderLocationItem = this.renderLocationItem.bind(this);
    this.renderHiveItem = this.renderHiveItem.bind(this);
    this.searchHiveByName = this.searchHiveByName.bind(this);

    this.state = {
      accordionGroupWidth: 0,
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setAccordionGroupValue();
    this.watchAccordionGroupResize();

    this.setState({
      isMounted: true,
    });
  }

  componentWillUnmount(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  componentDidUpdate(prevProps: LocationListProps) {
    if (
      this.props.locationList.length !== prevProps.locationList.length ||
      this.props.hiveList.length !== prevProps.hiveList.length ||
      this.props.search !== prevProps.search
    ) {
      this.setAccordionGroupValue();
    }
  }

  watchAccordionGroupResize() {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.setState({
        accordionGroupWidth: entries[0].borderBoxSize[0].inlineSize,
      });
    });

    this.resizeObserver.observe(this.accordionGroupRef.current as Element);
  }

  setAccordionGroupValue() {
    const value = [];

    for (const location of this.props.locationList) {
      const locationHiveList = this.props.hiveList.filter(
        (hive: DeviceComplete) => (hive.location || -1) === location.id && this.searchHiveByName(hive),
      );
      if (locationHiveList.length > 0) {
        value.push(location.id.toString());
      }
    }

    if (this.accordionGroupRef.current) {
      this.accordionGroupRef.current.value = value;
    }
  }

  getHiveTelemetry(hiveId: string) {
    const hiveTelemetryIndex = this.props.latestTelemetry.findIndex((item) => item.length >= 2 && item[0] === hiveId);

    if (hiveTelemetryIndex < 0) {
      return [];
    }

    return this.props.latestTelemetry[hiveTelemetryIndex][1];
  }

  searchHiveByName(hive: DeviceComplete) {
    const hiveName = BaseUtils.normalizeString(hive.frontend_settings?.custom_name || '');
    const searchValue = BaseUtils.normalizeString(this.props.search);
    return hiveName.includes(searchValue);
  }

  renderCustomButton(btn: CustomButton, index: number, location: Location, locationHiveList: DeviceComplete[]) {
    const { render, onClick: btnClickCallback, ...btnProps } = btn;

    if (render && !render(location, locationHiveList)) {
      return;
    }

    return (
      <IonButton
        {...btnProps}
        key={index}
        ref={(el) => {
          if (!el) {
            return;
          }

          // Register element click event
          el.onclick = (e) => {
            // Stop propagation to prevent accordion from opening / closing
            e.stopPropagation();
            if (typeof btnClickCallback !== 'undefined') {
              btnClickCallback(location);
            }
          };
        }}
        size="small"
      />
    );
  }

  groupHiveItems(items: DeviceComplete[]) {
    const groupedItems: Record<string, DeviceComplete[]> = {};

    for (const item of items) {
      let group = 'default';

      if (item.consist_of) {
        for (const connectedId of item.consist_of) {
          if (
            items.findIndex(
              (innerItem) =>
                innerItem.consist_of && innerItem.consist_of.indexOf(connectedId) >= 0 && innerItem.uuid !== item.uuid,
            ) >= 0
          ) {
            group = connectedId;
            break;
          }
        }
      }

      groupedItems[group] = groupedItems[group] || [];
      groupedItems[group].push(item);
    }

    return groupedItems;
  }

  renderHiveItems(items: DeviceComplete[], filteredItems: DeviceComplete[]) {
    const groups = this.groupHiveItems(items);
    const elements: React.ReactNode[] = [];

    for (const groupName in groups) {
      const groupItems = groups[groupName];
      const groupItemsFiltered = groupItems.filter((item) => filteredItems.findIndex((i) => i.uuid === item.uuid) >= 0);

      if (groupName === 'default') {
        groupItemsFiltered.map((hive) => elements.push(this.renderHiveItem(hive)));
        continue;
      }

      elements.push(
        <div
          className={styles.hiveGroup}
          key={groupName}
        >
          <div className={styles.hiveGroupLabel}></div>
          <div className={styles.hiveGroupItems}>
            {groupItemsFiltered.map((hive) => {
              return this.renderHiveItem(hive, true);
            })}
          </div>
        </div>,
      );
    }

    return elements;
  }

  renderHiveItem(hive: DeviceComplete, isGroupItem: boolean = false) {
    const hiveColor = hive.frontend_settings?.color || '#fff';
    const hiveTelemetry = this.getHiveTelemetry(hive.uuid);
    const HiveItemComponent = this.state.accordionGroupWidth <= MOBILE_BREAKPOINT ? HiveItemMobile : HiveItem;

    return (
      <div
        className={`${styles.hiveItem} ${this.props.selectedHive === hive.uuid ? styles.hiveItemSelected : ''}`}
        key={hive.uuid}
        onClick={() => {
          if (this.props.onHiveClick) {
            this.props.onHiveClick(hive);
          }
        }}
        style={{
          borderLeft: `6px solid ${hiveColor}`,
        }}
      >
        <HiveItemComponent
          hive={hive}
          devices={this.props.devices}
          telemetry={hiveTelemetry}
          {...(isGroupItem && { inactiveSensors: [SensorEnum.HIVE_TEMPERATURE, SensorEnum.HIVE_HUMIDITY] })}
        />
      </div>
    );
  }

  renderLocationItem(location: Location) {
    const locationHiveList = this.props.hiveList.filter(
      (hive: DeviceComplete) => (hive.location || -1) === location.id,
    );

    // Do not show location if there are no hives and one of the following conditions is true:
    //   - it is default location
    //   - prop showEmpty is set to false
    if (locationHiveList.length <= 0 && (location.id === -1 || !this.props.showEmpty)) {
      return;
    }

    const filteredHiveList = locationHiveList.filter(this.searchHiveByName);
    let locationTemp: SensorValue | null = null;

    for (const hive of locationHiveList) {
      const hiveTelemetry = this.getHiveTelemetry(hive.uuid);
      locationTemp = getSensorValue(SensorEnum.LOCATION_TEMPERATURE, hiveTelemetry, hive.sensors || []);

      if (locationTemp.value !== null && !locationTemp.outdated) {
        break;
      }
    }

    return (
      <IonAccordion
        key={location.id}
        value={location.id.toString()}
        readonly={filteredHiveList.length <= 0}
        toggleIconSlot="start"
        className={styles.accordionRow}
      >
        <IonItem
          slot="header"
          color="light"
        >
          <IonLabel>
            {location.name} ({locationHiveList.length})
          </IonLabel>
          <div
            slot="end"
            className={styles.accordionRowHeaderEnd}
          >
            {/* Show location temperature only if:
            - locations exists
            - location temperature is available */}
            {location.id !== -1 && !!locationTemp && locationTemp.value !== null && (
              <React.Fragment>
                <Icon
                  iconName={locationTemp.icon as any}
                  size={24}
                  style={{
                    color: locationTemp.color,
                  }}
                />
                <IonText className={styles.locationTempValue}>{locationTemp.formatValue}</IonText>
              </React.Fragment>
            )}
            {this.props.customButtons.map((btn: CustomButton, index: number) => {
              return this.renderCustomButton(btn, index, location, locationHiveList);
            })}
          </div>
        </IonItem>
        <div
          slot="content"
          className={styles.accordionRowContent}
        >
          {this.renderHiveItems(locationHiveList, filteredHiveList)}
        </div>
      </IonAccordion>
    );
  }

  render() {
    return (
      <IonAccordionGroup
        multiple={true}
        ref={this.accordionGroupRef}
      >
        {this.state.isMounted && this.props.locationList.map(this.renderLocationItem)}
      </IonAccordionGroup>
    );
  }
}

export default LocationList;
