import SigninService from '@api/services/SigninService';
import Logo from '@components/Header/logo.svg';
import withHistory from '@components/HOC/withHistory';
import withLocation from '@components/HOC/withLocation';
import Loading from '@components/Loading';
import TextField from '@components/TextField';
import { IonAlert, IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import NotificationUtil from '@util/Notification';
import Validation from '@util/Validation';
import { HttpStatusCode } from 'axios';
import { History, Location } from 'history';
import React from 'react';
import styles from '../styles.module.css';

export interface ResetPasswordProps {
  location: Location;
  history: History;
}

export interface ResetPasswordState {
  alert: Record<string, any>;
  loading: boolean;
  formLoading: boolean;
  formData: Record<string, any>;
  formErrors: Record<string, string>;
  token: string | null;
}

const initialSendResetData = {
  username: '',
};

const initialResetData = {
  username: '',
  password: '',
  passwordConfirm: '',
};

const alertInitialState = {
  open: false,
  header: '',
  message: '',
};

export class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
  constructor(props: ResetPasswordProps) {
    super(props);

    this.handleResetClick = this.handleResetClick.bind(this);
    this.handleSendResetClick = this.handleSendResetClick.bind(this);
    this.handleChangeFormData = this.handleChangeFormData.bind(this);

    this.state = {
      alert: alertInitialState,
      loading: false,
      formLoading: false,
      formData: initialSendResetData,
      formErrors: {},
      token: null,
    };
  }

  async componentDidMount() {
    const search = new URLSearchParams(this.props.location.search);
    const token = search.get('token');

    if (token === null) {
      return;
    }

    this.setState({
      loading: true,
    });

    // Verify reset password
    try {
      const res: any = await SigninService.verifyResetPaswd({
        token,
      });

      if (res.response && res.response.status !== HttpStatusCode.Accepted) {
        this.setState({
          loading: false,
        });

        NotificationUtil.error('Neplatný token pro změnu hesla');
        return;
      }

      this.setState({
        loading: false,
        formData: initialResetData,
        token,
      });
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba během ověření tokenu');
    }
  }

  closeAlert() {
    this.setState({
      alert: alertInitialState,
    });
  }

  validateSendReset() {
    const formErrors: Record<string, string> = {};

    Validation.required(this.state.formData.username, 'username', formErrors);

    if (!formErrors.username) {
      Validation.email(this.state.formData.username, 'username', formErrors);
    }

    this.setState({
      formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  }

  async handleSendResetClick() {
    if (!this.validateSendReset()) {
      return;
    }

    if (this.state.formLoading) {
      return;
    }

    this.setState({
      formLoading: true,
    });

    try {
      const res: any = await SigninService.sendResetPaswd({
        username: this.state.formData.username,
      });

      if (res.status === HttpStatusCode.Accepted) {
        this.setState({
          token: '',
          formLoading: false,
        });

        return;
      }

      if (!res.username) {
        this.setState({
          formLoading: false,
        });

        NotificationUtil.error('Špatný email, zkontrolujte prosím údaje');
        return;
      }

      this.setState({
        alert: {
          open: true,
          header: 'Změna hesla',
          message: 'Pro potvrzení operace klikněte na odkaz v emailu',
        },
        formData: initialSendResetData,
        formLoading: false,
      });
    } catch (e: any) {
      this.setState({
        formLoading: false,
      });

      NotificationUtil.error('Neočekávaná chyba během odesilání dat');
    }
  }

  validateReset() {
    const formErrors: Record<string, string> = {};

    Validation.required(this.state.formData.username, 'username', formErrors);
    Validation.required(this.state.formData.password, 'password', formErrors);
    Validation.required(this.state.formData.passwordConfirm, 'passwordConfirm', formErrors);

    if (!formErrors.username) {
      Validation.email(this.state.formData.username, 'username', formErrors);
    }

    if (!formErrors.password && !formErrors.passwordConfirm) {
      if (this.state.formData.password !== this.state.formData.passwordConfirm) {
        formErrors.passwordConfirm = 'Hesla se neshodují';
      }
    }

    this.setState({
      formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  }

  async handleResetClick() {
    if (!this.validateReset()) {
      return;
    }

    if (this.state.formLoading) {
      return;
    }

    this.setState({
      formLoading: true,
    });

    try {
      const res: any = await SigninService.resetPaswd({
        username: this.state.formData.username,
        password: this.state.formData.password,
        ...(this.state.token && { token: this.state.token }),
      });

      if (!res.username) {
        this.setState({
          formLoading: false,
        });

        NotificationUtil.error('Neplatný token nebo email');
        return;
      }

      this.setState({
        alert: {
          open: true,
          header: 'Úspěšná změna hesla',
          message: '',
          buttons: [
            {
              text: 'Přihlásit se',
              role: 'login',
              handler: () => {
                this.props.history.push('/login');
              },
            },
          ],
        },
        token: null,
        formData: initialSendResetData,
        formLoading: false,
      });
    } catch (e) {
      this.setState({
        formLoading: false,
      });

      NotificationUtil.error('Neočekávaná chyba během změny hesla');
    }
  }

  validateSignin() {
    const formErrors: Record<string, string> = {};

    Validation.required(this.state.formData.username, 'username', formErrors);
    Validation.required(this.state.formData.password, 'password', formErrors);
    Validation.required(this.state.formData.passwordConfirm, 'passwordConfirm', formErrors);

    if (!formErrors.username) {
      Validation.email(this.state.formData.username, 'username', formErrors);
    }

    if (!formErrors.password && !formErrors.passwordConfirm) {
      if (this.state.formData.password !== this.state.formData.passwordConfirm) {
        formErrors.passwordConfirm = 'Hesla se neshodují';
      }
    }

    this.setState({
      formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  }

  handleChangeFormData(e: any) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  renderSendResetForm() {
    return (
      <React.Fragment>
        <IonRow>
          <IonCol>
            <TextField
              label="Email"
              placeholder="example@seznam.cz"
              labelPlacement="start"
              name="username"
              required
              onIonInput={this.handleChangeFormData}
              value={this.state.formData.username}
              error={this.state.formErrors.username}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton
              expand="block"
              disabled={this.state.formLoading}
              onClick={this.handleSendResetClick}
            >
              Pokračovat
            </IonButton>
          </IonCol>
        </IonRow>
      </React.Fragment>
    );
  }

  renderResetForm() {
    return (
      <React.Fragment>
        <IonRow>
          <IonCol>
            <TextField
              label="Email"
              placeholder="example@seznam.cz"
              labelPlacement="start"
              name="username"
              required
              onIonInput={this.handleChangeFormData}
              value={this.state.formData.username}
              error={this.state.formErrors.username}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <TextField
              label="Heslo"
              placeholder="Zadejte heslo"
              labelPlacement="start"
              name="password"
              type="password"
              required
              onIonInput={this.handleChangeFormData}
              value={this.state.formData.password}
              error={this.state.formErrors.password}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <TextField
              placeholder="Zopakujte heslo"
              labelPlacement="start"
              name="passwordConfirm"
              type="password"
              required
              onIonInput={this.handleChangeFormData}
              value={this.state.formData.passwordConfirm}
              error={this.state.formErrors.passwordConfirm}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton
              expand="block"
              disabled={this.state.formLoading}
              onClick={this.handleResetClick}
            >
              Potvrdit
            </IonButton>
          </IonCol>
        </IonRow>
      </React.Fragment>
    );
  }

  renderFooter() {
    return (
      <IonRow>
        <IonCol>
          <div className={styles.loginFooter}>
            <div className={styles.loginFooter}>
              <div
                className={styles.loginFooterItem}
                onClick={() => {
                  this.props.history.push('/login');
                }}
              >
                Přihlášení
              </div>
            </div>
          </div>
        </IonCol>
      </IonRow>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loading text="Probíhá ověření..." />;
    }

    return (
      <IonPage>
        <IonContent>
          <div className={styles.authContainer}>
            <div className={styles.authBox}>
              <div className={styles.logo}>
                <img
                  alt="app_logo"
                  src={Logo}
                />
              </div>
              <div className={styles.content}>
                <IonGrid>
                  {this.state.token === null ? this.renderSendResetForm() : this.renderResetForm()}
                  {this.renderFooter()}
                </IonGrid>
              </div>
            </div>
          </div>
          <IonAlert
            isOpen={this.state.alert.open}
            header={this.state.alert.header}
            message={this.state.alert.message}
            buttons={this.state.alert.buttons || ['OK']}
            onDidDismiss={this.closeAlert}
          ></IonAlert>
        </IonContent>
      </IonPage>
    );
  }
}

export default withLocation(withHistory(ResetPassword));
