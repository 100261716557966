import { DeviceComplete } from '@api/models/DeviceModel';
import Icon from '@components/Icon';
import { IonText } from '@ionic/react';
import getSensorValue, { getHiveBatteryValue, getHiveLocationValue } from '@lib/data/Device/getSensorValue';
import { INACTIVE_SENSOR_COLOR, SensorEnum } from '@lib/data/Device/typeDefs';
import * as React from 'react';
import HiveBody from './HiveBody';
import HiveBodyItem from './HiveBodyItem';
import HiveFooter from './HiveFooter';
import HiveRoof from './HiveRoof';
import styles from './styles.module.css';

interface HiveShapeProps {
  hive: DeviceComplete;
  devices: DeviceComplete[];
  telemetry: any;
  inactiveSensors: SensorEnum[];
}

interface HiveShapeState {}

export class HiveShape extends React.Component<HiveShapeProps, HiveShapeState> {
  static defaultProps = {
    inactiveSensors: [],
  };

  renderSensor(number: SensorEnum, noValue: boolean = false) {
    let sensor = getSensorValue(number, this.props.telemetry, this.props.hive.sensors || []);
    let textValue: React.ReactNode = sensor.formatValue;

    if (number === SensorEnum.HIVE_WEIGHT) {
      const weightGain = getSensorValue(SensorEnum.WEIGHT_GAIN, this.props.telemetry, this.props.hive.sensors || []);
      const weightGain2 = this.props.hive.weightGain;

      if (weightGain)
        textValue = (
          <React.Fragment>
            {textValue}{' '}
            <div style={{ fontSize: '14px' }}>
              (<span style={{ color: weightGain.color }}>{weightGain.formatValue}</span>
              {weightGain2 && <span style={{ color: weightGain2.color }}>{`, ${weightGain2.formatValue}`}</span>})
            </div>
          </React.Fragment>
        );
    }

    if (number === SensorEnum.BATTERY_VOLTAGE) {
      sensor = getHiveBatteryValue(this.props.hive, this.props.devices);
    }

    if (number === SensorEnum.MOVEMENT) {
      sensor = getHiveLocationValue(this.props.hive);
      textValue = sensor.formatValue;
    }

    if (this.props.inactiveSensors.indexOf(number) >= 0) {
      sensor.color = INACTIVE_SENSOR_COLOR;
      textValue = sensor.unit ? `- ${sensor.unit}` : '-';
    }

    return (
      <div className={styles.sensorBlock}>
        <Icon
          iconName={sensor.icon as any}
          color={sensor.color}
          size={35}
        />
        {!noValue && <IonText className={styles.sensorValue}>{textValue}</IonText>}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.hiveShape}>
        <HiveRoof>{this.renderSensor(SensorEnum.BATTERY_VOLTAGE, true)}</HiveRoof>
        <HiveBody>
          <HiveBodyItem>{this.renderSensor(SensorEnum.HIVE_WEIGHT)}</HiveBodyItem>
          <HiveBodyItem>{this.renderSensor(SensorEnum.HIVE_TEMPERATURE)}</HiveBodyItem>
          <HiveBodyItem>{this.renderSensor(SensorEnum.HIVE_HUMIDITY)}</HiveBodyItem>
          <HiveBodyItem>{this.renderSensor(SensorEnum.MOVEMENT)}</HiveBodyItem>
        </HiveBody>
        <HiveFooter>{this.renderSensor(SensorEnum.LOCATION_TEMPERATURE)}</HiveFooter>
      </div>
    );
  }
}

export default HiveShape;
