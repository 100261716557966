import apiConfig from '@api/core/config';
import request from '@api/core/request';
import { CacheType } from '@api/core/typeDefs';
import {
  AllDevicesLatestTelemetry,
  AssignDeviceByPasswdRequest,
  AssignDeviceRequest,
  CreateHiveRequest,
  Device,
  DeviceComplete,
  DevicePutRequest,
  HiveStateResponse,
  HiveStateUpdateRequest,
  Permissions,
  Sensors,
} from '@api/models/DeviceModel';
import {
  LocationJournalCreateRequest,
  LocationJournalResponse,
  LocationJournalUpdateRequest,
} from '@api/models/LocationModel';

export class DeviceService {
  /**
   * Returns a list of the user's devices. The device objects are simplified.
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Array of user's devices
   * @throws ApiError
   */
  public static devicesList(forceUpdate?: boolean): Promise<Array<Device>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/',
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Returns a list of the user's devices. The device objects are complete.
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Array of user's complete devices
   * @throws ApiError
   */
  public static devicesListComplete(forceUpdate?: boolean): Promise<Array<DeviceComplete>> {
    return request<Array<DeviceComplete>>(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/complete/',
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    }).then((devices: Array<DeviceComplete>) => {
      // Filter out hidden devices
      return devices.filter((d) => !d.frontend_settings || !d.frontend_settings.hidden);
    });
  }

  /**
   * Returns the latest telemetry of all user's devices.
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns An array of arrays.
   * The first item of the inner arrays is always a device uuid and the second is an array of telemetry.
   * So, the inner arrays are like this:
   * [uuid,[[timestamp, sensor_number, value],[timestamp2, sensor_number, value]]
   * @throws ApiError
   */
  public static devicesListLatestTelemetry(forceUpdate?: boolean): Promise<Array<AllDevicesLatestTelemetry>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/latest-telemetry/',
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Creates a Hive.
   * @param requestBody
   * @returns Completed object of created device
   * @throws ApiError
   */
  public static createHive(requestBody?: CreateHiveRequest): Promise<DeviceComplete> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/devices/create-hive/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns a device identified by its UUID. Includes sensors and the user's permissions.
   * @param uuid
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Completed object of device
   * @throws ApiError
   */
  public static deviceGet(uuid: string, forceUpdate?: boolean): Promise<DeviceComplete> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/',
      path: {
        uuid,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Updates a device identified by its UUID. Allows to update location, coordinates, user configuration and frontend settings.
   * Does not allow to update name, type, serial number, UUID, sensors settings nor permissions.
   * Permissions can be updated via the /permissions endopint.
   * @param uuid
   * @param requestBody
   * @returns Completed object of updated device
   * @throws ApiError
   */
  public static deviceUpdate(uuid: string, requestBody?: DevicePutRequest): Promise<DeviceComplete> {
    return request(apiConfig, {
      method: 'PUT',
      url: '/apiarist/api/devices/{uuid}/',
      path: {
        uuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns a list of the user's 20 latest device journal records.
   * @param uuid
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Array of journal records
   * @throws ApiError
   */
  public static journalRecordList(uuid: string, forceUpdate?: boolean): Promise<Array<LocationJournalResponse>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/journal/',
      path: {
        uuid,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Creates a new journal record. If the record is related to a hive state value, the value is also returned in the "state" attribute.
   * @param uuid
   * @param requestBody
   * @returns Journal record
   * @throws ApiError
   */
  public static deviceJournalRecordCreate(
    uuid: string,
    requestBody: LocationJournalCreateRequest,
  ): Promise<LocationJournalResponse> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/devices/{uuid}/journal/',
      path: {
        uuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Assigns a new subdevice to this device using the UUID.
   * The user has to be the owner of both devices or has to have the "control" permissions to both devices.
   * For the device to be assigned successfully, four conditions has to be met:
   * 1. This device (the target device) must be of a composite type (only the "Hive" type now).
   * 2. The assignee device must be of a non-composite type (only the "Hive Scale" type now).
   * 3. The target device must not have a subdevice of the same type (as the assignee device) already assigned.
   * Only one subdevice of the same type is allowed.
   * 4. The assignee device must not be a subdevice of some other device already.
   * @param uuid
   * @param requestBody
   * @returns string Device successfully assigned.
   * @throws ApiError
   */
  public static assignDeviceCreate(uuid: string, requestBody: AssignDeviceRequest): Promise<string> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/devices/{uuid}/assign-device/',
      path: {
        uuid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        412: `One of the conditions were not met. The respons string is in the following format:"Condition X. Additional description.", where "X" is the number of the unmet condition. The additional description is a human readable description of the unmet condition.`,
      },
    });
  }

  /**
   * Assigns a new subdevice to this device using the assign password.
   * The user has to be the owner of both devices or has to have the "control" permissions to both devices.
   * If the assignee device has no owner yet, it is assigned to the owner of the target device
   * (even if the operation is invoked by a permitted user instead of the owner themselves).
   * Therefore, this endpoint can be used by users to claim their new devices while simultaneously assigning them to hives.
   * For the device to be assigned successfully, four condetions has to be met:
   * 1. This device (the target device) must be of a composite type (only the "Hive" type now).
   * 2. The assignee device must be of a non-composite type (only the "Hive Scale" type now).
   * 3. The target device must not have a subdevice of the same type (as the assignee device) already assigned. Only one subdevice of the same type is allowed.
   * 4. The assignee device must not be a subdevice of some other device already.
   * @param uuid
   * @param requestBody
   * @returns string Device successfully assigned.
   * @throws ApiError
   */
  public static assignDevicePasswdCreate(uuid: string, requestBody: AssignDeviceByPasswdRequest): Promise<string> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/devices/{uuid}/assign-device-passwd/',
      path: {
        uuid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        412: `One of the conditions were not met. The respons string is in the following format:"Condition X. Additional description.", where "X" is the number of the unmet condition. The additional description is a human readable description of the unmet condition.`,
      },
    });
  }

  /**
   * Unassignes the assignee device indetified by UUID in the request body from the target device.
   * @param uuid
   * @param requestBody
   * @returns string Device successfully unassigned.
   * @throws ApiError
   */
  public static unassignDeviceCreate(uuid: string, requestBody: AssignDeviceRequest): Promise<string> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/devices/{uuid}/unassign-device/',
      path: {
        uuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns a list of the user's device journal records in the requested time-window. Allowed time-window is 60 days max.
   * The from_date and to_date can be eighter an integer timestamp or a floating-point timestamp, or a date-time in YYYY-MM-DDTHH:MM:SS format.
   * @param fromDate
   * @param toDate
   * @param uuid
   * @returns Array of journal records
   * @throws ApiError
   */
  public static journalRecordList2(
    fromDate: string,
    toDate: string,
    uuid: string,
  ): Promise<Array<LocationJournalResponse>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/journal/{from_date}/{to_date}/',
      path: {
        from_date: fromDate,
        to_date: toDate,
        uuid,
      },
    });
  }

  /**
   * Returns the requested user's device journal record and allows to update and delete one. The record is indentified by its timestamp.
   * The timestamp can be eighter an integer timestamp or a floating-point timestamp, or a date-time in YYYY-MM-DDTHH:MM:SS format.
   * @param timestamp
   * @param uuid
   * @returns Journal record
   * @throws ApiError
   */
  public static journalRecordGet(timestamp: string, uuid: string): Promise<LocationJournalResponse> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/journal/{timestamp}/',
      path: {
        timestamp,
        uuid,
      },
    });
  }

  /**
   * Updates a journal record. If the record is related to a hive state value, the value is also returned.
   * @param id
   * @param uuid
   * @param requestBody
   * @returns Updated journal record. If the record is related to a hive state value, the value is also returned in the "state" attribute.
   * @throws ApiError
   */
  public static deviceJournalRecordUpdate(
    id: number,
    uuid: string,
    requestBody: LocationJournalUpdateRequest,
  ): Promise<LocationJournalResponse> {
    return request(apiConfig, {
      method: 'PUT',
      url: '/apiarist/api/devices/{uuid}/journal-record/{id}/',
      path: {
        id,
        uuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Deletes a journal record. Requires the edit_journal permission.
   * @param id
   * @param uuid
   * @throws ApiError
   */
  public static journalRecordDelete(id: number, uuid: string): Promise<void> {
    return request(apiConfig, {
      method: 'DELETE',
      url: '/apiarist/api/devices/{uuid}/journal-record/{id}/',
      path: {
        id,
        uuid,
      },
    });
  }

  /**
   * Returns a list of users with access to the device and their permissions.
   *
   * List of valid permissions:
   * - view_telemetry - can see/donwload the telemetry
   * - get_notifications - can receive notifications from the device
   * - control - can change the user configuration and frontend settings
   * - read_journal - can read the journal
   * - add_to_journal - can read and add new records to the journal but cannot edit or delete existing records
   * - edit_journal - can read, add, edit and delete records from the journal
   * - all - the user is the owner of the device and has all permissions
   * @param uuid
   * @returns Permissions
   * @throws ApiError
   */
  public static devicesPermissionsList(uuid: string): Promise<Array<Permissions>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/permissions/',
      path: {
        uuid,
      },
    });
  }

  /**
   * To the owner of the device, this endpoint alows to update the permissions by sending a list of updated permissions.
   * It is allowed to send a list of multiple users with updated permissions.
   * The users that are not in the list will have their permissions left intact.
   * The affetced users will have their original set of permissions replaced by the presented new list.
   * If an empty list of permissions for any given user in the list is presented,
   * all of the user's permissions will be removed and they will lose access to the device.
   *
   * List of valid pemrissions:
   * - view_telemetry - can see/donwload the telemetry
   * - get_notifications - can receive notifications from the device
   * - control - can change the user configuration and frontend settings
   * - read_journal - can read the journal
   * - add_to_journal - can read and add new records to the journal but cannot edit or delete existing records
   * - edit_journal - can read, add, edit and delete records from the journal
   * - all - the user is the owner of the device and has all permissions
   *
   * Only one of the three journal permissions should be provided at a time. If more are provided, the highest of them is accepted.
   * @param uuid
   * @param requestBody
   * @returns Updated permissions
   * @throws ApiError
   */
  public static devicePermissionsUpdate(uuid: string, requestBody: Array<Permissions>): Promise<Array<Permissions>> {
    return request(apiConfig, {
      method: 'PUT',
      url: '/apiarist/api/devices/{uuid}/permissions/',
      path: {
        uuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns a list of sensor of the device identified by uuid. Supports GET.
   * @param uuid
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Sensors
   * @throws ApiError
   */
  public static devicesSensorsList(uuid: string, forceUpdate?: boolean): Promise<Array<Sensors>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/sensors/',
      path: {
        uuid,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Returns a sensor of the device identified by uuid and the sensor number. Supports GET.
   * @param number
   * @param uuid
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Sensors
   * @throws ApiError
   */
  public static devicesSensorsRetrieve(number: number, uuid: string, forceUpdate?: boolean): Promise<Sensors> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/sensors/{number}/',
      path: {
        number,
        uuid,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Returns the latest telemetry of a sensor identified by the device uuid and the sensor number. Supports GET.
   * @param number
   * @param uuid
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Latest telemetry of a sensor
   * @throws ApiError
   */
  public static devicesSensorsTelemetryList(number: number, uuid: string, forceUpdate?: boolean): Promise<any> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/sensors/{number}/telemetry/',
      path: {
        number,
        uuid,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Returns a time-interval of telemtry of a sensor identified by the device uuid and the sensor number.
   * The interval is identified by the two argument in the URI behind the telemetry keyword.
   * The from_date and to_date can be eighter an integer timestamp or a floating-point timestamp,
   * or a date-time in YYYY-MM-DDTHH:MM:SS format. Supports GET.
   * @param fromDate
   * @param number
   * @param toDate
   * @param uuid
   * @returns Time-interval telemetry of a sensor
   * @throws ApiError
   */
  public static devicesSensorsTelemetryList2(
    fromDate: string,
    number: number,
    toDate: string,
    uuid: string,
  ): Promise<any> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/sensors/{number}/telemetry/{from_date}/{to_date}/',
      path: {
        from_date: fromDate,
        to_date: toDate,
        number,
        uuid,
      },
    });
  }

  /**
   * Returns a time-interval of aggregated telemtry of a sensor identified by the device uuid and the sensor number.
   * The interval is identified by the two arguments in the URI behind the telemetry keyword.
   * The aggregation interval is given by a string in the URI parameter window. Supported values are: hour, day, week, month.
   * The aggregation operator is given by a string in the URI parameter operator.Supported values are: avg, count, min, max, stddev, sum, variance, median.
   * The from_date and to_date can be eighter an integer timestamp or a floating-point timestamp, or a date-time in YYYY-MM-DDTHH:MM:SS format.
   * Supports GET.
   * @param fromDate
   * @param number
   * @param toDate
   * @param uuid
   * @returns Time-interval aggregated telemetry of a sensor
   * @throws ApiError
   */
  public static devicesSensorsTelemetryAggregatedList(
    fromDate: string,
    number: number,
    toDate: string,
    uuid: string,
  ): Promise<any> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/sensors/{number}/telemetry/{from_date}/{to_date}/aggregated/',
      path: {
        from_date: fromDate,
        to_date: toDate,
        number,
        uuid,
      },
    });
  }

  /**
   * Returns timespan of the latest telemetry of a sensor identified by the device uuid and the sensor number.
   * The timespan is identified by the span argument in the URI. Supports GET.
   * @param number
   * @param span
   * @param uuid
   * @returns Latest telemetry in timespan
   * @throws ApiError
   */
  public static devicesSensorsTelemetryList3(number: number, span: string, uuid: string): Promise<any> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/sensors/{number}/telemetry/{span}/',
      path: {
        number,
        span,
        uuid,
      },
    });
  }

  /**
   * Returns a time-span of latest aggregated telemtry of a sensor identified by the device uuid and the sensor number.
   * The span is identified by the span argument in the URI.
   * The aggregation interval is given by a string in the URI parameter window. Supported values are: hour, day, week, month.
   * The aggregation operator is given by a string in the URI parameter operator.Supported values are: avg, count, min, max, stddev, sum, variance,median.
   * Supports GET.
   * @param number
   * @param span
   * @param uuid
   * @returns Latest aggregated telemetry in timespan
   * @throws ApiError
   */
  public static devicesSensorsTelemetryAggregatedList2(number: number, span: string, uuid: string): Promise<any> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/sensors/{number}/telemetry/{span}/aggregated/',
      path: {
        number,
        span,
        uuid,
      },
    });
  }

  /**
   * Returns te hive state values and related journal records.
   * @param uuid
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns
   * Hive state values.
   * Contains aggregated hive state values and last journal records related to the state values.
   * Each object "X_record" is a last journal record related to the state value "X" without the username of the user who created that record.
   * @throws ApiError
   */
  public static deviceState(uuid: string, forceUpdate?: boolean): Promise<HiveStateResponse> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/state/',
      path: {
        uuid,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Updates the hive state values by creating the particular journal records. Returns the new values and records.
   * @param uuid
   * @param requestBody
   * @returns
   * Hive state values.
   * Contains the new aggregated hive state values and last journal records related to the state values.
   * Each object "X_record" is a last journal record related to the state value "X" without the username of the user who created that record.
   * Values that did not change are not included.
   * @throws ApiError
   */
  public static deviceUpdateState(uuid: string, requestBody?: HiveStateUpdateRequest): Promise<HiveStateResponse> {
    return request(apiConfig, {
      method: 'PUT',
      url: '/apiarist/api/devices/{uuid}/state/',
      path: {
        uuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the latest telemetry of the device identified by its uuid.
   * @param uuid
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Latest telemetry of the device
   * @throws ApiError
   */
  public static devicesTelemetryList(uuid: string, forceUpdate?: boolean): Promise<any> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/telemetry/',
      path: {
        uuid,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Returns the selected time-window of telemetry of the device identified by its uuid. The from_date and to_date can be eighter
   * an integer timestamp or a floating-point timestamp, or a date-time in YYYY-MM-DDTHH:MM:SS format.
   * @param fromDate
   * @param toDate
   * @param uuid
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Telemetry of the device in selected time-window
   * @throws ApiError
   */
  public static devicesTelemetryList2(
    fromDate: string,
    toDate: string,
    uuid: string,
    forceUpdate?: boolean,
  ): Promise<any> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/telemetry/{from_date}/{to_date}/',
      path: {
        from_date: fromDate,
        to_date: toDate,
        uuid,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
        type: CacheType.DEVICE_TELEMETRY,
      },
    });
  }

  /**
   * Returns the latest telemetry in the given time-span of the device identified by its uuid.
   * @param span
   * @param uuid
   * @returns Latest telemetry in the given time-span
   * @throws ApiError
   */
  public static devicesTelemetryList3(span: string, uuid: string): Promise<any> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/telemetry/{span}/',
      path: {
        span,
        uuid,
      },
    });
  }

  public static devicesTelemetryList4(fromDate: string, toDate: string, uuid: string): Promise<any> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/telemetry/{from_date}/{to_date}/',
      path: {
        from_date: fromDate,
        to_date: toDate,
        uuid,
      },
    });
  }

  public static devicesTrajectoryList(fromDate: string, toDate: string, uuid: string): Promise<Array<Array<number>>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/devices/{uuid}/trajectory/{from_date}/{to_date}/',
      path: {
        from_date: fromDate,
        to_date: toDate,
        uuid,
      },
    });
  }
}

export default DeviceService;
