import DeviceService from '@api/services/DeviceService';
import moment from 'moment';
import { DeviceTrajectory, GetTrajectoryOpts } from './typeDefs';

export const getTrajectory = async (opts: GetTrajectoryOpts) => {
  if (!opts.uuid || !opts.startTime || !opts.endTime) {
    return;
  }

  let startTime = moment(opts.startTime);
  let endTime = moment(opts.endTime);

  if (endTime.isBefore(startTime)) {
    return;
  }

  let maxRequestTime = (opts.maxRequestTime || 60) * 24 * 60 * 60; // 60 days in seconds by default
  let t = startTime.clone();
  let tDuration = moment.duration(endTime.diff(startTime)).asSeconds();
  let interval = tDuration > maxRequestTime ? maxRequestTime : tDuration;
  let i = 0;

  while (1) {
    const result: DeviceTrajectory = [];
    const from = t.toISOString();
    const to = t.add(interval, 'seconds').toISOString();
    const data = (await DeviceService.devicesTrajectoryList(from, to, opts.uuid)) as DeviceTrajectory;

    for (let i = 0; i < data.length; i++) {
      result.push([...data[i]]);
    }

    const diff = moment.duration(endTime.diff(t)).asSeconds();

    if (opts.cb) {
      opts.cb(result.reverse(), { isLast: diff <= 0, index: i, uuid: opts.uuid });
    }

    i++;

    if (diff <= 0) {
      break;
    } else if (diff < interval) {
      interval = diff;
    }
  }
};

export default getTrajectory;
