import store from '@src/store';

export class Notification {
  static success(message: string) {
    store.dispatch({
      type: 'set',
      payload: {
        notification: { timestamp: Date.now(), message, type: 'success' },
      },
    });
  }

  static error(message: string) {
    store.dispatch({
      type: 'set',
      payload: {
        notification: { timestamp: Date.now(), message, type: 'error' },
      },
    });
  }

  static loading(message: string) {
    store.dispatch({
      type: 'set',
      payload: {
        notification: { timestamp: Date.now(), message, type: 'loading' },
      },
    });
  }

  static remove() {
    store.dispatch({
      type: 'set',
      payload: {
        notification: null,
      },
    });
  }
}

export default Notification;
