import { Drivers, Storage } from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

let storage: Storage | null = null;

export const createStorage = async (name: string) => {
  storage = new Storage({
    name,
    driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
  });

  await storage.defineDriver(CordovaSQLiteDriver);
  await storage.create();
};

export const storageSet = async (name: string, value: any) => {
  if (storage === null) {
    return;
  }

  await (storage as any).set(name, value);
};

export const storageGet = async (name: string) => {
  if (storage === null) {
    return;
  }

  const result = await (storage as any).get(name);
  return result;
};

export const storageKeys = async () => {
  if (storage === null) {
    return;
  }

  return await (storage as any).keys();
};
