import { Capacitor } from '@capacitor/core';
import React from 'react';
import { Redirect, Route } from 'react-router';
import { RouteItem } from '../typeDefs';

interface RouterProps {
  routes: Array<RouteItem>;
}

class Router extends React.Component<RouterProps> {
  render() {
    return (
      <React.Fragment>
        {this.props.routes
          .filter((route) => !route.mobileOnly || Capacitor.getPlatform() !== 'web')
          .map((route, index) => (
            <Route
              key={index}
              exact
              path={route.path}
              render={(props) => {
                if (route.render) {
                  return route.render(props);
                }

                if (route.component) {
                  return route.component;
                }

                if (route.redirect) {
                  return <Redirect to={route.redirect || '/home'}></Redirect>;
                }
              }}
            />
          ))}
      </React.Fragment>
    );
  }
}

export default Router;
