import { DeviceComplete } from '@api/models/DeviceModel';
import Icon from '@components/Icon';
import { IonAccordion, IonAccordionGroup, IonButton, IonItem, IonLabel, IonText } from '@ionic/react';
import getDeviceSignal from '@lib/data/Device/getDeviceSignal';
import getSensorValue, { getDeviceBatteryValue } from '@lib/data/Device/getSensorValue';
import { SensorEnum } from '@lib/data/Device/typeDefs';
import * as React from 'react';
import styles from './styles.module.css';
import { CustomButton } from './typeDefs';

interface DeviceListProps {
  deviceList: DeviceComplete[];
  latestTelemetry: any[];
  customButtons: CustomButton[];
}

interface DeviceListState {}

export class DeviceList extends React.Component<DeviceListProps, DeviceListState> {
  static defaultProps: DeviceListProps = {
    deviceList: [],
    latestTelemetry: [],
    customButtons: [],
  };

  constructor(props: DeviceListProps) {
    super(props);

    this.renderDeviceItem = this.renderDeviceItem.bind(this);
  }

  renderCustomButton(btn: CustomButton, index: number, device: DeviceComplete) {
    const { render, onClick: btnClickCallback, ...btnProps } = btn;

    if (render && !render(device)) {
      return;
    }

    return (
      <IonButton
        {...btnProps}
        key={index}
        ref={(el) => {
          if (!el) {
            return;
          }

          // Register element click event
          el.onclick = (e) => {
            // Stop propagation to prevent accordion from opening / closing
            e.stopPropagation();
            if (typeof btnClickCallback !== 'undefined') {
              btnClickCallback(device);
            }
          };
        }}
        size="small"
      />
    );
  }

  renderInfoRow(title: string, value: string) {
    return (
      <div className={styles.infoRowBlock}>
        <IonText className={styles.infoRowTitle}>{title}</IonText>
        <IonText className={styles.infoRowValue}>{value}</IonText>
      </div>
    );
  }

  getHiveTelemetry(hiveId: string) {
    const hiveTelemetryIndex = this.props.latestTelemetry.findIndex((item) => item.length >= 2 && item[0] === hiveId);

    if (hiveTelemetryIndex < 0) {
      return [];
    }

    return this.props.latestTelemetry[hiveTelemetryIndex][1];
  }

  renderDeviceItem(device: DeviceComplete) {
    const signal = getDeviceSignal(device);
    const deviceTelemetry = this.getHiveTelemetry(device.uuid);
    const deviceSensors = device.sensors || [];

    // Signal info
    let firmwareVersion = '-';
    let operatorName = '-';

    if (device.status && device.status.signal && device.status.signal.op_name) {
      operatorName = device.status.signal.op_name;
    }

    // Sensors
    const battery = getDeviceBatteryValue(device);
    const charging = getSensorValue(SensorEnum.CHARGING, deviceTelemetry, deviceSensors);

    if (device.firmware_version) {
      firmwareVersion = device.firmware_version;
    }

    return (
      <IonAccordion
        key={device.uuid}
        value={device.uuid}
        toggleIconSlot="start"
        className={styles.accordionRow}
      >
        <IonItem
          slot="header"
          color="light"
        >
          <IonLabel>{device.name}</IonLabel>
          <div
            slot="end"
            className={styles.accordionRowHeaderEnd}
          >
            {!!charging.value && (
              <Icon
                iconName={charging.icon as any}
                size={24}
                color={charging.color}
              />
            )}
            <div className={styles.signalIconBlock}>
              <Icon
                iconName={signal.icon as any}
                size={24}
                color={signal.color}
              />
              <Icon
                iconName={signal.backgroundIcon as any}
                size={24}
                opacity={0.3}
                className={styles.signalBackgroundIcon}
              />
            </div>
            {this.props.customButtons.map((btn: CustomButton, index: number) => {
              return this.renderCustomButton(btn, index, device);
            })}
          </div>
        </IonItem>
        <div
          slot="content"
          className={styles.accordionRowContent}
        >
          {this.renderInfoRow('Napětí:', `${battery.value ? battery.value.toFixed(3) : '-'} ${battery.unit || ''}`)}
          {this.renderInfoRow('Název operatora:', operatorName)}
          {this.renderInfoRow('Verze firmwaru:', firmwareVersion)}
        </div>
      </IonAccordion>
    );
  }

  render() {
    return <IonAccordionGroup>{this.props.deviceList.map(this.renderDeviceItem)}</IonAccordionGroup>;
  }
}

export default DeviceList;
