import { ApiConfig } from '@api/core/typeDefs';
import getToken from '@api/core/getToken';
import _config from '@config';

export const config: ApiConfig = {
  BASE: _config.apiUrl,
  VERSION: '1.0.0',
  WITH_CREDENTIALS: false,
  CREDENTIALS: 'include',
  TOKEN: getToken,
  HEADERS: undefined,
  ENCODE_PATH: undefined,
};

export default config;
