import * as React from 'react';
import styles from './styles.module.css';

interface HiveBodyProps extends React.PropsWithChildren {}
interface HiveBodyState {}

export class HiveBody extends React.Component<HiveBodyProps, HiveBodyState> {
  render() {
    return (
      <React.Fragment>
        <div className={styles.hiveBody}>
          <div className={styles.hiveBodyLeft}></div>
          <div className={styles.hiveBodyContent}>{this.props.children}</div>
          <div className={styles.hiveBodyRight}></div>
        </div>
        <div className={styles.hiveBodyBottomLine}></div>
      </React.Fragment>
    );
  }
}

export default HiveBody;
