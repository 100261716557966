import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { IonText } from '@ionic/react';
import { storageSet } from '@src/storage';
import {
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LegendElement,
  LegendItem,
  LinearScale,
  LineElement,
  PointElement,
  ScaleOptions,
  TimeScale,
  Tooltip,
} from 'chart.js';
import Zoom from 'chartjs-plugin-zoom';
import merge from 'deepmerge';
import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import store from '../../store';
import styles from './styles.module.css';

// Default options
import { Capacitor } from '@capacitor/core';
import { defaultOptions, defaultXAxisOptions, defaultYAxisOptions } from '@components/LineChart/defaultOptions';
import { ChartEvent } from 'chart.js';

ChartJS.register(LineElement, TimeScale, LinearScale, PointElement, Tooltip, Legend, Zoom);

const whiteBackgroundPlugin = {
  id: 'white-background-plugin',
  beforeDraw: (chart: any) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const verticalLinePlugin = {
  id: 'vertical-line-plugin',
  afterDraw: (chart: any) => {
    if (!chart.tooltip?._active?.length) {
      return;
    }

    let yAxisScaleKey = null;

    for (const scaleKey in chart.scales) {
      const scale = chart.scales[scaleKey];

      if (scale.axis === 'y') {
        yAxisScaleKey = scaleKey;
        break;
      }
    }

    if (yAxisScaleKey === null) {
      return;
    }

    let x = chart.tooltip._active[0].element.x;
    let yAxis = chart.scales[yAxisScaleKey];
    let ctx = chart.ctx;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, yAxis.top);
    ctx.lineTo(x, yAxis.bottom);
    ctx.lineWidth = 2;
    ctx.strokeStyle = 'rgba(153, 0, 0, 0.4)';
    ctx.stroke();
    ctx.restore();
  },
};

export interface LineChartProps {
  options: ChartOptions<'line'>;
  data: ChartData<'line'>;
  lastUpdate: number;
  fullscreen?: boolean;
  merge: boolean;
  onMergeToggleChange?: (e: any) => void;
  onClick?: (ref: any, e: any) => void;
  onDoubleClick?: () => void;
  onZoomComplete?: (chart: any) => void;
  onLineRef?: (line: ChartJS<'line'> | undefined | null) => void;
  onLegendClick?: (e: ChartEvent, legendItem: LegendItem, legend: LegendElement<'line'>) => void;

  graph_interval_last?: number;
  graph_is_custom_last?: boolean;
  graph_mode?: string;
  showScales?: boolean;
}

export interface LineChartState {
  charts: Array<any>;
  display: boolean;
  options: any;
  data: any;
  toolbarOpen: boolean;
}

class LineChart extends React.Component<LineChartProps, LineChartState> {
  static defaultProps = {
    merge: true,
  };

  private mergedLineRef: any;
  private singleLineRefs: Array<any> = [];
  private zoomDebounceTimeout?: number;
  private isLegendClicked: boolean = false;

  constructor(props: LineChartProps) {
    super(props);

    this.handleChartExport = this.handleChartExport.bind(this);
    this.handlechartFullscreen = this.handlechartFullscreen.bind(this);
    this.handleZoomComplete = this.handleZoomComplete.bind(this);
    this.toggleToolbar = this.toggleToolbar.bind(this);
    this.setLastInterval = this.setLastInterval.bind(this);
    this.handleLegendClick = this.handleLegendClick.bind(this);

    this.state = {
      charts: [],
      display: false,
      options: {},
      data: {},
      toolbarOpen: false,
    };
  }

  async setLastInterval() {
    const data: any = {
      graph_interval: this.props.graph_interval_last,
      graph_agg_func: 'none',
      graph_mode: 'realtime',
      graph_time: false,
      graph_time_start: null,
      graph_time_end: null,
    };

    if (!this.props.graph_is_custom_last) {
      data.graph_custom_interval = null;
    }

    store.dispatch({
      type: 'set',
      payload: data,
    });

    for (const dataKey in data) {
      await storageSet(dataKey, data[dataKey]);
    }
  }

  toggleToolbar() {
    this.setState({
      toolbarOpen: !this.state.toolbarOpen,
    });
  }

  handlechartFullscreen() {
    store.dispatch({
      type: 'set',
      payload: {
        fullscreen: !this.props.fullscreen,
      },
    });

    this.toggleToolbar();
  }

  async stringFromBase64(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject('method did not return a string');
        }
      };
      reader.readAsDataURL(blob);
    });
  }

  async handleChartExport() {
    const path = this.mergedLineRef.toBase64Image();
    const filename = `graph-${moment().format('DD-MM-YYY_HH-mm')}.png`;

    if (Capacitor.getPlatform() !== 'web') {
      const data = await this.stringFromBase64(path);
      await Filesystem.writeFile({
        path: filename,
        data,
        directory: Directory.Cache,
      }).then((savedFile) => {
        Share.share({ url: savedFile.uri });
      });
    } else {
      const link = document.createElement('a');
      link.download = filename;
      link.target = '_blank';
      link.href = path;
      link.click();
    }

    this.toggleToolbar();
  }

  zoomDebounce(chart: any) {
    clearTimeout(this.zoomDebounceTimeout);
    this.zoomDebounceTimeout = window.setTimeout(() => {
      if (this.props.onZoomComplete) {
        this.props.onZoomComplete(chart);
      }
    }, 500);
  }

  handleZoomComplete(args: any) {
    for (let i = 0; i < this.singleLineRefs.length; i++) {
      const lineRef = this.singleLineRefs[i];

      if (lineRef.id === args.chart.id) {
        continue;
      }

      lineRef.zoomScale('x', {
        min: args.chart.scales.x.min,
        max: args.chart.scales.x.max,
      });
    }

    this.zoomDebounce(args.chart);
  }

  handleLegendClick(
    eventContext: LegendElement<'line'>,
    e: ChartEvent,
    legendItem: LegendItem,
    legend: LegendElement<'line'>,
  ) {
    this.isLegendClicked = true;
    ChartJS.defaults.plugins.legend.onClick.call(eventContext, e, legendItem, legend);

    // Call custom click handler
    if (this.props.onLegendClick) {
      this.props.onLegendClick(e, legendItem, legend);
    }
  }

  generateCharts() {
    const { data, options } = this.props;
    const chartScales = [];
    const newCharts = [];

    // For merged chart
    let newChartOptions: any;
    const newChartScales: any = {};
    const newChartData = { ...data };

    if (!options.scales || !options.scales.x || data.datasets.length === 0) {
      return;
    }

    for (const scaleName in options.scales) {
      let scale = options.scales[scaleName];

      if (scaleName === 'x') {
        newChartScales[scaleName] = merge(defaultXAxisOptions, scale as ScaleOptions<'time'>);

        continue;
      }

      const gridOptions: ScaleOptions<'linear'> = {
        grid: {
          display: this.props.fullscreen || this.props.showScales,
        },
        ticks: {
          display: this.props.fullscreen || this.props.showScales,
        },
      };

      const newScale = merge.all([defaultYAxisOptions, scale as ScaleOptions<'linear'>, gridOptions]);

      newChartScales[scaleName] = newScale;

      chartScales.push({
        [scaleName]: newScale,
      });
    }

    const zoomEnabled = Capacitor.getPlatform() === 'web' || this.props.fullscreen;

    const zoomOptions: ChartOptions<'line'> = {
      plugins: {
        zoom: {
          zoom: {
            wheel: {
              enabled: zoomEnabled,
            },
            drag: {
              enabled: zoomEnabled,
            },
            pinch: {
              enabled: zoomEnabled,
            },
          },
        },
      },
    };

    const handleLegendClick = this.handleLegendClick;

    const legendOptions: ChartOptions<'line'> = {
      plugins: {
        legend: {
          onClick(e, item, legend) {
            handleLegendClick(this, e, item, legend);
          },
        },
      },
    };

    newChartOptions = merge.all([
      defaultOptions,
      {
        scales: {
          ...newChartScales,
        },
      },
      zoomOptions,
      legendOptions,
    ]);

    for (let i = 0; i < data.datasets.length; i++) {
      const dataset = data.datasets[i];
      let chartScale = chartScales[0];

      if (dataset.yAxisID) {
        const chartScaleIndex = chartScales.findIndex((scale) => Object.keys(scale)[0] === dataset.yAxisID);
        chartScale = chartScales[chartScaleIndex];
      }

      const chartScaleKey = Object.keys(chartScale)[0];

      const newOptions: ChartOptions<'line'> = merge(newChartOptions, {});

      newOptions.scales = {
        x: newChartOptions.scales.x,
        [chartScaleKey]: {
          ...chartScale[chartScaleKey],
          position: 'left' as const,
        },
      };

      let newDatasets = [{ ...dataset }];

      const newData: ChartData<'line'> = {
        ...data,
        datasets: newDatasets,
      };

      newCharts.push({
        options: newOptions,
        data: newData,
      });
    }

    this.setState({
      charts: newCharts,
      options: newChartOptions,
      data: newChartData,
    });
  }

  componentDidUpdate(prevProps: LineChartProps) {
    if (
      prevProps.lastUpdate !== this.props.lastUpdate ||
      prevProps.merge !== this.props.merge ||
      prevProps.fullscreen !== this.props.fullscreen ||
      prevProps.showScales !== this.props.showScales
    ) {
      this.generateCharts();
    }
  }

  componentDidMount() {
    this.generateCharts();

    setTimeout(() => {
      this.setState({
        display: true,
      });
    }, 1);
  }

  renderNoData() {
    return (
      <div className={styles.noData}>
        <IonText>Nejsou k dispozici žádná data</IonText>
      </div>
    );
  }

  renderSingleChart() {
    const isDatasetsEmpty = this.state.data.datasets.every((dataset: any) => dataset.data.length <= 0);

    if (isDatasetsEmpty) {
      return this.renderNoData();
    }

    return (
      <div className={styles.Graph}>
        <div>
          <Line
            plugins={[verticalLinePlugin, whiteBackgroundPlugin]}
            options={{
              ...this.state.options,
              plugins: {
                ...this.state.options.plugins,
                zoom: {
                  ...this.state.options.plugins?.zoom,
                  zoom: {
                    ...this.state.options.plugins?.zoom?.zoom,
                    onZoomComplete: (args) => {
                      this.zoomDebounce(args.chart);
                    },
                  },
                  pan: {
                    ...this.state.options.plugins?.zoom?.pan,
                    onPanComplete: (args) => {
                      this.zoomDebounce(args.chart);
                    },
                  },
                },
              },
            }}
            data={this.state.data}
            ref={(obj) => {
              this.mergedLineRef = obj;
              if (this.props.onLineRef) {
                this.props.onLineRef(obj);
              }
            }}
            onClick={(e) => {
              setTimeout(() => {
                if (this.isLegendClicked) {
                  this.isLegendClicked = false;
                  return;
                }

                if (e.detail === 1 && this.props.onClick) {
                  this.props.onClick(this.mergedLineRef, e);
                } else if (e.detail === 2 && this.props.onDoubleClick) {
                  this.props.onDoubleClick();
                }
              }, 1);
            }}
          />
        </div>
      </div>
    );
  }

  renderMultipleCharts() {
    const view: any[] = [];

    for (let i = 0; i < this.state.charts.length; i++) {
      const chart = this.state.charts[i];

      if (chart.data.datasets[0].data.length <= 0) {
        continue;
      }

      view.push(
        <div
          key={i}
          className={styles.Graph}
        >
          <div>
            <Line
              plugins={[verticalLinePlugin]}
              options={{
                ...chart.options,
                plugins: {
                  ...this.state.options.plugins,
                  zoom: {
                    ...chart.options.plugins?.zoom,
                    zoom: {
                      ...chart.options.plugins?.zoom?.zoom,
                      onZoomComplete: this.handleZoomComplete,
                    },
                    pan: {
                      ...chart.options.plugins?.zoom?.pan,
                      onPanComplete: this.handleZoomComplete,
                    },
                  },
                },
              }}
              data={chart.data}
              ref={(obj) => (this.singleLineRefs[i] = obj)}
            />
          </div>
        </div>,
      );
    }

    if (view.length <= 0) {
      return this.renderNoData();
    }

    return view;
  }

  render() {
    if (!this.state.display) {
      return;
    }

    return (
      <React.Fragment>
        {/* {this.renderToolbar()} */}
        {/* {this.renderUpdateButton()} */}
        {this.props.merge ? this.renderSingleChart() : this.renderMultipleCharts()}
      </React.Fragment>
    );
  }
}

export default connect((state: any) => {
  return {
    fullscreen: state.fullscreen,
    graph_mode: state.graph_mode,
    graph_interval_last: state.graph_interval_last,
    graph_is_custom_last: state.graph_is_custom_last,
    showScales: state.showScales,
  };
})(LineChart);
