import { LocationJournalResponse } from '@api/models/LocationModel';
import { Point } from 'chart.js';

export type SensorTelemetry = [timestamp: number, sensorNumber: number, sensorValue: number];
export type DeviceTelemetry = SensorTelemetry[];
export type TrajectoryPoint = [timestamp: number, latitude: number, longitude: number];
export type DeviceTrajectory = TrajectoryPoint[];

export const INACTIVE_SENSOR_COLOR = '#8a8a8a';
export const UNDEFINED_SENSOR_COLOR = '#464646';
export const MOVEMENT_DETECTED_COLOR = '#ff3419';
export const NO_SENSOR_VALUE = 'QuestionCircle';
export const NO_SIGNAL_ICON = 'WifiOff';
export const SIGNAL_OUTDATED_COLOR = '#919191';
export const SIGNAL_ACTIVE_COLOR = '#000000';
export const ACTIVE_CONNECTED_DEVICE_COLOR = '#129e00';

export enum SensorEnum {
  SEQUENCE_NUMBER = 1,
  LOCATION_TEMPERATURE,
  HIVE_TEMPERATURE,
  HIVE_HUMIDITY,
  HIVE_WEIGHT,
  CHARGING,
  BATTERY_VOLTAGE,
  MOVEMENT,
  PAIRED_WEIGHT,
  WEIGHT_GAIN,
  COORDINATES,
  ELEVATION,
  SAT,
  SIGNAL_RXLEV,
  SIGNAL_BER,
  SIGNAL_RSCP,
  SIGNAL_ECNO,
  SIGNAL_RSRQ,
  SIGNAL_RSRP,
}

export enum StateEnum {
  UPDATE_DATE = 'update_date',
  HARVEST = 'harvest',
  FEEDING = 'feeding',
  ATTACHMENTS = 'attachments',
  MOTHER = 'mother',
  SWARMING = 'swarming',
  FRAME_MEASURE = 'frame_measure',
}

export enum DeviceType {
  SCALE = 1,
  TRACKER,
  HIVE,
  WEATHER_STATION = 10,
}

export enum BatteryLevel {
  EMPTY,
  HALF,
  FULL,
}

export enum WeightGainColor {
  GAIN = '#129e00',
  LOSS = '#ff3419',
  NEUTRAL = '#545454',
}

export enum SignalStrength {
  NONE,
  LOW,
  MEDIUM,
  HIGH,
}

export enum LocationLevel {
  NOT_CHANGED,
  MOVED_5M,
  MOVED_50M,
}

export const SensorColor: Record<string, string> = {
  [SensorEnum.LOCATION_TEMPERATURE]: '#ffbb00',
  [SensorEnum.HIVE_TEMPERATURE]: '#c91e1e',
  [SensorEnum.HIVE_HUMIDITY]: '#2427ff',
  [SensorEnum.HIVE_WEIGHT]: '#6e00a1',
  [SensorEnum.CHARGING]: '#e6c300',
};

export const SensorIcon: Record<string, string> = {
  [SensorEnum.LOCATION_TEMPERATURE]: 'ThermometerSun',
  [SensorEnum.HIVE_TEMPERATURE]: 'Thermometer',
  [SensorEnum.HIVE_HUMIDITY]: 'DropletHalf',
  [SensorEnum.HIVE_WEIGHT]: 'Kettlebell',
  [SensorEnum.MOVEMENT]: 'GeoAltFill',
  [SensorEnum.CHARGING]: 'BatteryCharging',
};

export const BatteryLevelIcon: Record<string, string> = {
  [BatteryLevel.EMPTY]: 'Battery',
  [BatteryLevel.HALF]: 'BatteryHalf',
  [BatteryLevel.FULL]: 'BatteryFull',
};

export const BatteryLevelColor: Record<string, string> = {
  [BatteryLevel.EMPTY]: '#ff3419',
  [BatteryLevel.HALF]: '#e8e400',
  [BatteryLevel.FULL]: '#129e00',
};

export const LocationLevelColor: Record<string, string> = {
  [LocationLevel.NOT_CHANGED]: '#129e00',
  [LocationLevel.MOVED_5M]: '#ff7d19',
  [LocationLevel.MOVED_50M]: '#ff3419',
};

export const SignalStrengthIcon: Record<string, string> = {
  [SignalStrength.NONE]: NO_SIGNAL_ICON,
  [SignalStrength.LOW]: 'Wifi1',
  [SignalStrength.MEDIUM]: 'Wifi2',
  [SignalStrength.HIGH]: 'Wifi',
};

export const StateText: Record<string, string> = {
  [StateEnum.UPDATE_DATE]: 'Datum aktualizace',
  [StateEnum.HARVEST]: 'Výnos medu',
  [StateEnum.FEEDING]: 'Dodané množství krmení',
  [StateEnum.ATTACHMENTS]: 'Počet nástavků',
  [StateEnum.MOTHER]: 'Stáří matky (rok)',
  [StateEnum.SWARMING]: 'Datum posledního rojení',
  [StateEnum.FRAME_MEASURE]: 'Rámková míra',
};

export const LocationLevelText: Record<string, string> = {
  [LocationLevel.NOT_CHANGED]: '',
  [LocationLevel.MOVED_5M]: '5 - 50m',
  [LocationLevel.MOVED_50M]: '>50m',
};

export const ConnectedDeviceIcon: Record<string, string> = {
  [DeviceType.SCALE]: 'Kettlebell',
  [DeviceType.TRACKER]: 'GeoAltFill',
};

export interface SensorValue {
  value: number | null;
  batteryLevel?: BatteryLevel;
  fixedValue: string | null;
  formatValue: string;
  unit: string | null;
  color: string;
  icon: string;
  active: boolean;
  outdated: boolean;
}

interface GetOpts {
  uuid: string;
  startTime: string;
  endTime: string;
  maxRequestTime?: number;
}

export interface GetTelemetryOpts extends GetOpts {
  cb?: (data: GetTelemetryResult, opts: Record<string, any>) => void;
}

export interface GetJournalOpts extends GetOpts {
  cb?: (data: LocationJournalResponse[], opts: Record<string, any>) => void;
}

export interface GetTrajectoryOpts extends GetOpts {
  cb?: (data: DeviceTelemetry, opts: Record<string, any>) => void;
}

export interface GetTelemetryResult {
  [sensorNumber: string]: Point[];
}

export interface DeviceSignal {
  strength: number;
  icon: string;
  backgroundIcon: string;
  color: string;
}

export interface ConnectedDevice {
  icon: string;
  color: string;
}
