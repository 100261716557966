import { Geolocation } from '@capacitor/geolocation';

const requestGeolocationPermissions = async () => {
  let permStatus = await Geolocation.checkPermissions();

  if (permStatus.location === 'prompt') {
    try {
      permStatus = await Geolocation.requestPermissions({ permissions: ['location'] });
    } catch (e) {
      // throw new Error('Geolocation services are not available');
    }
  }

  if (permStatus.location !== 'granted') {
    // throw new Error('User denied permissions!');
  }
};

export { requestGeolocationPermissions };
