import {
  AssignDeviceByPasswdRequest,
  AssignDeviceRequest,
  DeviceComplete,
  DevicePutRequest,
} from '@api/models/DeviceModel';
import { Location } from '@api/models/LocationModel';
import DeviceService from '@api/services/DeviceService';
import { Geolocation } from '@capacitor/geolocation';
import DeviceList from '@components/DeviceList';
import { CustomButton } from '@components/DeviceList/typeDefs';
import Icon from '@components/Icon';
import NavButton from '@components/NavButton';
import Select from '@components/Select';
import { SelectOption } from '@components/Select/typeDefs';
import TextField from '@components/TextField';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { DeviceType } from '@lib/data/Device/typeDefs';
import NotificationUtil from '@util/Notification';
import Validation from '@util/Validation';
import { HttpStatusCode } from 'axios';
import {
  addOutline as AddIcon,
  closeOutline,
  createOutline,
  ellipse as EllipseIcon,
  globeOutline as GlobeIcon,
  locateOutline,
  mapOutline,
  saveOutline as SaveIcon,
  trashOutline as TrashIcon,
} from 'ionicons/icons';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import * as React from 'react';
import { CirclePicker, ColorResult } from 'react-color';
import { CircleMarker, MapContainer, Marker, TileLayer } from 'react-leaflet';
import styles from './styles.module.css';

interface SettingsTabProps {
  hive: DeviceComplete;
  location: Location;
  devices: DeviceComplete[];
  latestTelemetry: any[];
  locations: Location[];
  onHiveUpdate?: () => void;
  onHiveDelete?: () => void;
  onHiveDeviceUpdate?: () => void;
}

interface SettingsTabState {
  formData: Record<string, any>;
  formErrors: Record<string, string>;
  modalFormData: Record<string, any>;
  modalFormErrors: Record<string, string>;
  openColorDialog: boolean;
  loading: boolean;
  alert: React.ComponentProps<typeof IonAlert>;
  modalOpen: boolean;
  modalTab: string;
  mapEditMode: boolean;
  mapPosLatitude: string | null;
  mapPosLongitude: string | null;
  locationTabDisabled: boolean;
  assignDeviceList: DeviceComplete[] | null;
  mapGeolocation: any;
}

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconAnchor: [12, 41],
});

const circlePickerColors = [
  '#ff6900',
  '#fcb900',
  '#7bdcb5',
  '#00d084',
  '#8ed1fc',
  '#0693e3',
  '#abb8c3',
  '#eb144c',
  '#f78da7',
  '#9900ef',
];

export class SettingsTab extends React.Component<SettingsTabProps, SettingsTabState> {
  private mapRef: L.Map | null = null;

  constructor(props: SettingsTabProps) {
    super(props);

    this.handleChangeFormData = this.handleChangeFormData.bind(this);
    this.handleChangeModalFormData = this.handleChangeModalFormData.bind(this);
    this.handleSaveHive = this.handleSaveHive.bind(this);
    this.handleHiveDelete = this.handleHiveDelete.bind(this);
    this.handleHiveDeleteConfirm = this.handleHiveDeleteConfirm.bind(this);
    this.handleDeviceRemove = this.handleDeviceRemove.bind(this);
    this.handleDeviceRemoveConfirm = this.handleDeviceRemoveConfirm.bind(this);
    this.handleModalDismiss = this.handleModalDismiss.bind(this);
    this.handleAssignDevice = this.handleAssignDevice.bind(this);
    this.handleModalNavClick = this.handleModalNavClick.bind(this);
    this.handleCreateAssignDeviceModal = this.handleCreateAssignDeviceModal.bind(this);

    this.state = {
      formData: {},
      formErrors: {},
      modalFormData: {},
      modalFormErrors: {},
      openColorDialog: false,
      loading: false,
      alert: {
        isOpen: false,
        onDidDismiss: () => {
          this.setState({
            alert: {
              isOpen: false,
              onDidDismiss: this.state.alert.onDidDismiss,
            },
          });
        },
      },
      modalOpen: false,
      modalTab: 'form',
      mapEditMode: false,
      mapPosLatitude: null,
      mapPosLongitude: null,
      locationTabDisabled: false,
      assignDeviceList: null,
      mapGeolocation: null,
    };
  }

  componentDidMount() {
    this.setHiveFormData();
  }

  componentDidUpdate(prevProps: SettingsTabProps) {
    if (prevProps.hive.uuid !== this.props.hive.uuid) {
      this.setHiveFormData();
    }
  }

  closeAlert() {
    this.setState({
      alert: {
        isOpen: false,
        onDidDismiss: this.state.alert.onDidDismiss,
      },
    });
  }

  getHiveList() {
    return this.props.devices.filter((device: DeviceComplete) => !!device.composite);
  }

  getHiveDevices() {
    return this.props.devices.filter(
      (device) => this.props.hive.consist_of && this.props.hive.consist_of.indexOf(device.uuid) >= 0,
    );
  }

  getAssignDeviceList() {
    const deviceList: DeviceComplete[] = [];
    const currentHive = this.props.hive;

    // Get all assigned device types
    const assignedDeviceTypes: number[] = [];

    if (currentHive.consist_of) {
      for (const assignedDeviceUUID of currentHive.consist_of) {
        const assignedDeviceIndex = this.props.devices.findIndex((device) => device.uuid === assignedDeviceUUID);

        if (assignedDeviceIndex < 0) {
          continue;
        }

        const assignedDevice = this.props.devices[assignedDeviceIndex];

        if (assignedDeviceTypes.indexOf(assignedDevice.type) < 0) {
          assignedDeviceTypes.push(assignedDevice.type);
        }
      }
    }

    // Get all devices availavble for assignment
    for (const device of this.props.devices) {
      // Skip composite devices (hives)
      if (!!device.composite) {
        continue;
      }

      // Check if device is not already assigned to selected hive
      if (currentHive.consist_of && currentHive.consist_of.indexOf(device.uuid) > 0) {
        continue;
      }

      // Check if selected hive does not have device of the same type
      if (assignedDeviceTypes.indexOf(device.type) >= 0) {
        continue;
      }

      // For "scale" device type, check if device is not already assigned to hive in other location
      if (
        device.type === DeviceType.SCALE &&
        this.getHiveList().findIndex(
          (hive) =>
            (hive.location || -1) !== currentHive.location &&
            hive.consist_of &&
            hive.consist_of.indexOf(device.uuid) >= 0,
        ) >= 0
      ) {
        continue;
      }

      // For other device types, check if device is not already assigned to another hive
      if (
        device.type !== DeviceType.SCALE &&
        this.getHiveList().findIndex((hive) => hive.consist_of && hive.consist_of.indexOf(device.uuid) >= 0) >= 0
      ) {
        continue;
      }

      deviceList.push(device);
    }

    return deviceList;
  }

  getAssignDeviceOptions() {
    const options: SelectOption[] = [];

    if (this.state.assignDeviceList === null) {
      return options;
    }

    return this.state.assignDeviceList.map((device) => ({
      value: device.uuid,
      text: device.name,
    }));
  }

  getAssignTypeOptions() {
    const options: SelectOption[] = [
      {
        value: 'password',
        text: 'Podle hesla',
      },
    ];

    if (this.state.assignDeviceList && this.state.assignDeviceList.length > 0) {
      options.push({
        value: 'list',
        text: 'Vyběr ze seznamu',
      });
    }

    return options;
  }

  getDeviceCustomButtons() {
    const customButtons: CustomButton[] = [];

    // Edit button
    customButtons.push({
      size: 'small',
      onClick: () => {},
      disabled: true,
      children: <Icon iconName="QuestionCircle" />,
    });

    // Delete button
    customButtons.push({
      size: 'small',
      color: 'danger',
      disabled: this.state.loading,
      onClick: this.handleDeviceRemoveConfirm,
      children: <Icon iconName="Trash" />,
    });

    return customButtons;
  }

  setHiveFormData() {
    this.setState({
      formData: {
        name: this.props.hive.frontend_settings?.custom_name || '',
        location: this.props.hive.location || -1,
        color: this.props.hive.frontend_settings?.color || '',
        latitude: this.props.hive.coordinates?.latitude || null,
        longitude: this.props.hive.coordinates?.longitude || null,
        notifications: this.props.hive.frontend_settings?.notifications_enabled || false,
      },
      formErrors: {},
      loading: false,
    });
  }

  validateHive() {
    const formErrors: Record<string, string> = {};

    Validation.required(this.state.formData.name, 'name', formErrors);
    Validation.required(this.state.formData.location, 'location', formErrors);

    this.setState({
      formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  }

  async handleSaveHive() {
    if (!this.validateHive()) {
      return;
    }

    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const updatedHive: DevicePutRequest = {
      frontend_settings: {
        ...this.props.hive.frontend_settings,
        color: this.state.formData.color,
        custom_name: this.state.formData.name,
        notifications_enabled: this.state.formData.notifications,
      },
      location: this.state.formData.location < 0 ? null : this.state.formData.location,
    };

    if (this.state.formData.location !== -1) {
      updatedHive.location = this.state.formData.location;
    }

    if (this.state.formData.latitude !== null && this.state.formData.longitude !== null) {
      updatedHive.coordinates = {
        latitude: Number.parseFloat(this.state.formData.latitude as string),
        longitude: Number.parseFloat(this.state.formData.longitude as string),
      };
    }

    try {
      await DeviceService.deviceUpdate(this.props.hive.uuid, updatedHive);
      NotificationUtil.success(`Úl "${this.state.formData.name}" byl úspěšně aktualizován`);
      if (this.props.onHiveUpdate) {
        this.props.onHiveUpdate();
      }
    } catch (e) {
      this.setHiveFormData();
      NotificationUtil.error('Neočekávaná chyba při ukládání dat');
    }

    this.setState({
      loading: false,
    });
  }

  handleHiveDeleteConfirm() {
    let msg = 'Opravdu si přejete smazat úl?';

    if (this.props.hive.consist_of && this.props.hive.consist_of.length > 0) {
      msg = 'Úl obsahuje připojená zařízení. Opravdu si přejete odstranit všechna zařízení a smazat úl?';
    }

    this.setState({
      alert: {
        ...this.state.alert,
        isOpen: true,
        header: 'Mazání úlu',
        subHeader: this.props.hive.frontend_settings?.custom_name || '',
        message: msg,
        buttons: [
          {
            text: 'Ne',
            role: 'cancel',
          },
          {
            text: 'Ano',
            role: 'confirm',
            handler: async () => {
              await this.handleHiveDelete();
            },
          },
        ],
      },
    });
  }

  async handleHiveDelete() {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const updatedHive: DevicePutRequest = {
      frontend_settings: {
        ...this.props.hive.frontend_settings,
        hidden: true,
      },
    };

    try {
      // Unassign connected devices
      if (this.props.hive.consist_of && this.props.hive.consist_of.length > 0) {
        for (const connectedDeviceUUID of this.props.hive.consist_of) {
          await DeviceService.unassignDeviceCreate(this.props.hive.uuid, { uuid: connectedDeviceUUID });
        }
      }

      // Delete hive
      const hiveName = this.props.hive.frontend_settings?.custom_name || '';
      await DeviceService.deviceUpdate(this.props.hive.uuid, updatedHive);
      NotificationUtil.success(`Úl "${hiveName}" byl úspěšně smazán`);

      if (this.props.onHiveDelete) {
        this.props.onHiveDelete();
      }
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při smazání úlu');
    }

    this.setState({
      loading: false,
    });
  }

  handleDeviceRemoveConfirm(device: DeviceComplete) {
    this.setState({
      alert: {
        ...this.state.alert,
        isOpen: true,
        header: 'Odstranění zařízení',
        subHeader: device.name,
        message: 'Opravdu si přejete odstranit zařízení?',
        buttons: [
          {
            text: 'Ne',
            role: 'cancel',
          },
          {
            text: 'Ano',
            role: 'confirm',
            handler: async () => {
              await this.handleDeviceRemove(device);
            },
          },
        ],
      },
    });
  }

  async handleDeviceRemove(device: DeviceComplete) {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      await DeviceService.unassignDeviceCreate(this.props.hive.uuid, { uuid: device.uuid });
      NotificationUtil.success(`Zařízení "${device.name}" bylo úspěšně odstraněno`);

      if (this.props.onHiveUpdate) {
        this.props.onHiveUpdate();
      }
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při odstranění zařízení');
    }

    this.setState({
      loading: false,
    });
  }

  validateAssignDevice() {
    const formErrors: Record<string, string> = {};

    Validation.required(this.state.mapPosLatitude, 'mapPosLatitude', formErrors);
    Validation.required(this.state.mapPosLongitude, 'mapPosLongitude', formErrors);

    if (this.state.modalFormData.assignType === 'password') {
      Validation.required(this.state.modalFormData.password, 'password', formErrors);
    } else {
      Validation.required(this.state.modalFormData.device, 'device', formErrors);
    }

    this.setState({
      modalFormErrors: formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  }

  async handleAssignDevice() {
    if (!this.validateAssignDevice()) {
      return;
    }

    if (this.state.modalFormData.assignType === 'password') {
      await this.assignDeviceByPassword();
    } else {
      await this.assignDeviceByUUID();
    }
  }

  async assignDeviceByPassword() {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const assignDeviceRequest: AssignDeviceByPasswdRequest = {
      assign_password: this.state.modalFormData.password,
      coordinates: {
        latitude: Number.parseFloat(this.state.mapPosLatitude as string),
        longitude: Number.parseFloat(this.state.mapPosLongitude as string),
      },
    };

    try {
      await DeviceService.assignDevicePasswdCreate(this.props.hive.uuid, assignDeviceRequest);
      NotificationUtil.success(`Zařízení bylo úspěšně přiřazeno`);

      if (this.props.onHiveDeviceUpdate) {
        this.props.onHiveDeviceUpdate();
      }

      this.handleModalDismiss();
    } catch (e: any) {
      this.setState({
        loading: false,
      });

      let errMsg = 'Neočekávaná chyba při ukládání dat';

      if (e.response.status === HttpStatusCode.PreconditionFailed) {
        const errorCode = e.response.body.errorCode;

        switch (errorCode) {
          case 2:
            errMsg = 'Nelze přiřadit zařízení daného typu';
            break;
          case 3:
            errMsg = 'Úl už má přiřazené zařízení daného typu';
            break;
          case 4:
            errMsg = 'Zařizení už je přiřazené k jinému úlu';
            break;
        }
      }

      if (e.response.status === HttpStatusCode.NotFound) {
        errMsg = 'Zařízení s tímto heslem nebylo nalezeno';
      }

      NotificationUtil.error(errMsg);
    }
  }

  async assignDeviceByUUID() {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const assignDeviceRequest: AssignDeviceRequest = {
      uuid: this.state.modalFormData.device,
      coordinates: {
        latitude: Number.parseFloat(this.state.mapPosLatitude as string),
        longitude: Number.parseFloat(this.state.mapPosLongitude as string),
      },
    };

    try {
      await DeviceService.assignDeviceCreate(this.props.hive.uuid, assignDeviceRequest);
      NotificationUtil.success(`Zařízení bylo úspěšně přiřazeno`);

      if (this.props.onHiveDeviceUpdate) {
        this.props.onHiveDeviceUpdate();
      }

      this.handleModalDismiss();
    } catch (e: any) {
      this.setState({
        loading: false,
      });

      NotificationUtil.error('Neočekávaná chyba při ukládání dat');
    }
  }

  handleChangeFormData(e: any) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleChangeModalFormData(e: any) {
    this.setState({
      modalFormData: {
        ...this.state.modalFormData,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleModalDismiss() {
    this.setState({
      modalOpen: false,
      modalTab: 'form',
      modalFormData: {},
      modalFormErrors: {},
      mapPosLatitude: null,
      mapPosLongitude: null,
      loading: false,
      assignDeviceList: null,
    });
  }

  handleModalNavClick(e: any, key: any) {
    this.setState({
      modalTab: key,
      mapEditMode: false,
    });
  }

  setMapZoom(lat: number, lon: number, zoom?: number) {
    if (this.mapRef) {
      this.mapRef.setView([lat, lon], zoom || this.mapRef.getZoom());
    }
  }

  handleCreateAssignDeviceModal() {
    const currentHive = this.props.hive;
    const currentLocation = this.props.location;
    let longitude: string | null = null;
    let latitude: string | null = null;

    // Try to set coordinates from hive
    if (currentHive.coordinates) {
      latitude = currentHive.coordinates.latitude.toString();
      longitude = currentHive.coordinates.longitude.toString();
    }

    // Try to set coordinates from location
    if (latitude === null && longitude === null) {
      if (currentLocation.coordinates) {
        latitude = currentLocation.coordinates.latitude.toString();
        longitude = currentLocation.coordinates.longitude.toString();
      }
    }

    const locationTabDisabled = latitude !== null;

    this.setState({
      modalFormData: {
        assignType: 'password',
        password: '',
        device: null,
      },
      assignDeviceList: this.getAssignDeviceList(),
      mapPosLatitude: latitude,
      mapPosLongitude: longitude,
      modalOpen: true,
      locationTabDisabled,
    });

    if (!locationTabDisabled) {
      this.setCurrentLocation();
    }
  }

  setCurrentLocation(forceZoom: boolean = false) {
    Geolocation.getCurrentPosition()
      .then((pos) => {
        const lat = pos.coords.latitude ? pos.coords.latitude.toString() : null;
        const lon = pos.coords.longitude ? pos.coords.longitude.toString() : null;

        this.setState({
          mapGeolocation: lat !== null && lon !== null ? [lat, lon] : null,
        });

        if (forceZoom || this.state.mapPosLatitude === null || this.state.mapPosLongitude === null) {
          this.setMapZoom(pos.coords.latitude, pos.coords.longitude, 17);
        }
      })
      .catch((e) => {});
  }

  renderFabs() {
    if (this.props.hive.consist_of && this.props.hive.consist_of.length >= 2) {
      return;
    }

    return (
      <IonFab
        className={styles.fabButton}
        slot="fixed"
        vertical="bottom"
        horizontal="end"
      >
        <IonFabButton>
          <IonIcon icon={AddIcon}></IonIcon>
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton
            onClick={this.handleCreateAssignDeviceModal}
            data-desc="Připojit nové zařízení"
          >
            <Icon
              iconName="Kettlebell"
              size={20}
            />
          </IonFabButton>
        </IonFabList>
      </IonFab>
    );
  }

  renderHiveDevices() {
    const devices = this.getHiveDevices();

    return (
      <React.Fragment>
        <IonRow>
          <IonCol>
            <IonText color="medium">
              <h4 className={styles.blockTitle}>Váhy a trackery ({devices.length})</h4>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <DeviceList
              deviceList={devices}
              latestTelemetry={this.props.latestTelemetry}
              customButtons={this.getDeviceCustomButtons()}
            />
          </IonCol>
        </IonRow>
      </React.Fragment>
    );
  }

  renderHiveForm() {
    return (
      <React.Fragment>
        <IonRow className="ion-align-items-center">
          <IonCol>
            <IonText color="medium">
              <h4 className={styles.blockTitle}>Nastavení úlu</h4>
            </IonText>
          </IonCol>
          <IonCol size="auto">
            <IonButton
              size="small"
              onClick={this.handleSaveHive}
              disabled={this.state.loading}
            >
              Uložit
              <IonIcon
                slot="end"
                icon={SaveIcon}
              ></IonIcon>
            </IonButton>
          </IonCol>
          <IonCol size="auto">
            <IonButton
              color="danger"
              size="small"
              onClick={this.handleHiveDeleteConfirm}
              disabled={this.state.loading}
            >
              Smazat
              <IonIcon
                slot="end"
                icon={TrashIcon}
              ></IonIcon>
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            size="12"
            sizeLg="6"
          >
            <TextField
              label="Název úlu"
              name="name"
              required
              onIonInput={this.handleChangeFormData}
              value={this.state.formData.name}
              error={this.state.formErrors.name}
            >
              <IonButton
                fill="clear"
                slot="end"
                className="ion-align-self-center"
                onClick={() => {
                  this.setState({
                    openColorDialog: true,
                  });
                }}
              >
                <IonIcon
                  slot="icon-only"
                  icon={EllipseIcon}
                  style={{
                    fontSize: '24px',
                    color: this.state.formData.color,
                  }}
                />
              </IonButton>
            </TextField>
          </IonCol>
          <IonCol
            size="12"
            sizeLg="6"
          >
            <Select
              label="Stanoviště"
              name="location"
              required
              value={this.state.formData.location}
              error={this.state.formErrors.location}
              options={this.props.locations.map((location) => ({
                value: location.id,
                text: location.name || '',
              }))}
              onIonChange={(e) => {
                // Set location coordinates if defined
                const selectedLocationIndex = this.props.locations.findIndex(
                  (location) => location.id === e.target.value,
                );
                let longitude: string | null = null;
                let latitude: string | null = null;

                if (selectedLocationIndex >= 0) {
                  const selectedLocation = this.props.locations[selectedLocationIndex];

                  if (selectedLocation.coordinates) {
                    latitude = selectedLocation.coordinates.latitude.toString();
                    longitude = selectedLocation.coordinates.longitude.toString();
                  }
                }

                this.setState({
                  formData: {
                    ...this.state.formData,
                    latitude: latitude,
                    longitude: longitude,
                  },
                });
                this.handleChangeFormData(e);
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            size="12"
            sizeLg="6"
          >
            <TextField
              name="latitude"
              label="Pozice - šířka"
              labelPlacement="stacked"
              value={this.state.formData.latitude}
            >
              <IonButton
                color="dark"
                fill="clear"
                slot="end"
                className="ion-align-self-center"
                onClick={() => {}}
              >
                <IonIcon
                  slot="icon-only"
                  style={{ fontSize: '24px' }}
                  icon={GlobeIcon}
                />
              </IonButton>
            </TextField>
          </IonCol>
          <IonCol
            size="12"
            sizeLg="6"
          >
            <TextField
              name="longitude"
              label="Pozice - výška"
              labelPlacement="stacked"
              value={this.state.formData.longitude}
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-align-items-end">
          <IonCol
            size="12"
            sizeLg="6"
          >
            <IonItem
              lines="full"
              className={styles.checkboxItem}
            >
              <IonCheckbox
                name="notifications"
                onIonChange={(e) => {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      notifications: e.target.checked,
                    },
                  });
                }}
                checked={this.state.formData.notifications}
                justify="space-between"
              >
                Notificace
              </IonCheckbox>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonModal
          isOpen={this.state.openColorDialog}
          onDidDismiss={() => {
            this.setState({
              openColorDialog: false,
            });
          }}
          className={styles.colorDialog}
        >
          <div className={styles.colorPicker}>
            <CirclePicker
              colors={circlePickerColors}
              color={this.state.formData.color}
              onChange={(result: ColorResult) => {
                this.setState({
                  formData: {
                    ...this.state.formData,
                    color: result.hex,
                  },
                  openColorDialog: false,
                });
              }}
            />
          </div>
        </IonModal>
      </React.Fragment>
    );
  }

  renderAssignDeviceForm() {
    return (
      <div className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Pozice - šířka"
                labelPlacement="stacked"
                readonly
                required
                value={this.state.mapPosLatitude}
                error={this.state.modalFormErrors.mapPosLatitude}
              >
                <IonButton
                  color="dark"
                  fill="clear"
                  slot="end"
                  className="ion-align-self-center"
                  disabled={this.state.locationTabDisabled}
                  onClick={async () => {
                    this.setState({
                      modalTab: 'map',
                      mapEditMode: true,
                    });
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    style={{ fontSize: '24px' }}
                    icon={mapOutline}
                  />
                </IonButton>
              </TextField>
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Pozice - výška"
                labelPlacement="stacked"
                readonly
                required
                value={this.state.mapPosLongitude}
                error={this.state.modalFormErrors.mapPosLongitude}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <Select
                label="Způsob přiřazení"
                name="assignType"
                value={this.state.modalFormData.assignType}
                error={this.state.modalFormErrors.assignType}
                options={this.getAssignTypeOptions()}
                onIonChange={this.handleChangeModalFormData}
              />
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              {this.state.modalFormData.assignType === 'password' ? (
                <TextField
                  label="Heslo"
                  name="password"
                  required
                  onIonInput={this.handleChangeModalFormData}
                  value={this.state.modalFormData.password}
                  error={this.state.modalFormErrors.password}
                />
              ) : (
                <Select
                  label="Seznam zařízení"
                  name="device"
                  required
                  value={this.state.modalFormData.device}
                  error={this.state.modalFormErrors.device}
                  options={this.getAssignDeviceOptions()}
                  onIonChange={this.handleChangeModalFormData}
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }

  renderModalContent() {
    if (this.state.modalTab === 'form') {
      return this.renderAssignDeviceForm();
    }

    return this.renderMap();
  }

  renderModal() {
    return (
      <IonModal
        onDidDismiss={this.handleModalDismiss}
        isOpen={this.state.modalOpen}
      >
        <IonHeader>
          <IonToolbar className="modal-toolbar">
            <IonButtons slot="start">
              <IonButton onClick={this.handleModalDismiss}>Zrušit</IonButton>
            </IonButtons>
            <IonTitle>Přidat zařízení</IonTitle>
            <IonButtons slot="end">
              <IonButton
                strong={true}
                disabled={this.state.loading}
                onClick={this.handleAssignDevice}
              >
                OK
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="modal-content">
          <div className={styles.modalContent}>
            <IonRow>
              <IonCol className="ion-no-padding">
                <NavButton
                  btnKey="form"
                  text="Informace"
                  onClick={this.handleModalNavClick}
                  value={this.state.modalTab}
                />
              </IonCol>
              <IonCol className="ion-no-padding">
                <NavButton
                  btnKey="map"
                  text="Lokalizace"
                  disabled={this.state.locationTabDisabled}
                  onClick={this.handleModalNavClick}
                  value={this.state.modalTab}
                />
              </IonCol>
            </IonRow>
            <div className={styles.modalTabContent}>{this.renderModalContent()}</div>
          </div>
        </IonContent>
      </IonModal>
    );
  }

  renderMap() {
    var geolocationDefined = false;
    var positionDefined = false;
    var position = [49.821094376846396, 15.490835755296175];
    var zoom = 10;

    if (this.state.mapPosLatitude !== null && this.state.mapPosLongitude !== null) {
      positionDefined = true;
      const lat = Number.parseFloat(this.state.mapPosLatitude);
      const lon = Number.parseFloat(this.state.mapPosLongitude);

      if (!isNaN(lat) && !isNaN(lon)) {
        position = [lat, lon];
        zoom = 17;
      }
    }

    if (this.state.mapGeolocation !== null) {
      geolocationDefined = true;

      if (!positionDefined) {
        position = this.state.mapGeolocation;
        zoom = 17;
      }
    }

    return (
      <MapContainer
        center={position as any}
        zoom={zoom}
        className={styles.mapContainer}
        ref={(map) => {
          this.mapRef = map;

          if (map == null) {
            return;
          }

          (this.mapRef as L.Map).on('click', (e) => {
            if ((e.originalEvent.target as any).tagName.toLowerCase() === 'ion-button') {
              return;
            }

            if (!this.state.mapEditMode) {
              return;
            }

            this.setState({
              mapPosLatitude: e.latlng.lat.toString(),
              mapPosLongitude: e.latlng.lng.toString(),
              mapEditMode: false,
            });
          });
        }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {positionDefined && (
          <Marker
            position={position as any}
            icon={DefaultIcon}
          />
        )}

        {geolocationDefined && (
          <CircleMarker
            center={this.state.mapGeolocation as any}
            fillColor="#269aff"
            fillOpacity={1.0}
            color="white"
            stroke={true}
            radius={7}
          />
        )}
        <div className={styles.mapToolbar}>
          <IonButton
            color="light"
            onClick={(e) => {
              this.setState({
                mapEditMode: !this.state.mapEditMode,
              });
            }}
          >
            <IonIcon
              slot="icon-only"
              icon={this.state.mapEditMode ? closeOutline : createOutline}
            ></IonIcon>
          </IonButton>
          <IonButton
            color="light"
            onClick={() => this.setCurrentLocation(true)}
          >
            <IonIcon
              slot="icon-only"
              icon={locateOutline}
            ></IonIcon>
          </IonButton>
        </div>
      </MapContainer>
    );
  }

  renderAlert() {
    return <IonAlert {...this.state.alert} />;
  }

  render() {
    return (
      <div className={styles.settingsTab}>
        {this.renderHiveForm()}
        {this.renderHiveDevices()}
        {this.renderFabs()}
        {this.renderAlert()}
        {this.renderModal()}
      </div>
    );
  }
}

export default SettingsTab;
