import { useParams } from 'react-router';

const withParams = (Component: any) => {
  return (props: any) => {
    const params = useParams();
    return (
      <Component
        params={params}
        {...props}
      />
    );
  };
};

export default withParams;
