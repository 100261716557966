type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
type Headers = Record<string, string>;

export enum CacheType {
  DEFAULT,
  DEVICE_TELEMETRY,
}

export interface CacheOptions {
  enabled: boolean;
  forceUpdate?: boolean;
  type?: CacheType;
}

export interface ApiConfig {
  BASE: string;
  VERSION: string;
  WITH_CREDENTIALS: boolean;
  CREDENTIALS: 'include' | 'omit' | 'same-origin';
  TOKEN?: string | Resolver<string | undefined>;
  HEADERS?: Headers | Resolver<Headers>;
  ENCODE_PATH?: (path: string) => string;
}

export interface ApiRequestOptions {
  method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'PATCH';
  url: string;
  path?: Record<string, any>;
  headers?: Record<string, any>;
  query?: Record<string, any>;
  body?: any;
  mediaType?: string;
  errors?: Record<number, string>;
  cache?: CacheOptions;
}

export interface ApiResult {
  url: string;
  ok: boolean;
  status: number;
  statusText: string;
  body: any;
}
