import store from '../../store';
import { storageGet } from '../../storage';

export const getToken = async () => {
  let token = await storageGet('accessToken');

  if (!token) {
    token = store.getState().accessToken;
  }

  return token !== null ? token : undefined;
};

export default getToken;
