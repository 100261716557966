import * as React from 'react';
import styles from './styles.module.css';

interface HiveRoofProps extends React.PropsWithChildren {}
interface HiveRoofState {}

export class HiveRoof extends React.Component<HiveRoofProps, HiveRoofState> {
  render() {
    return (
      <div className={styles.hiveRoof}>
        <div className={styles.hiveRoofLeft}></div>
        <div className={styles.hiveRoofContent}>{this.props.children}</div>
        <div className={styles.hiveRoofRight}></div>
      </div>
    );
  }
}

export default HiveRoof;
