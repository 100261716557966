import { IonSpinner, IonText } from '@ionic/react';
import React from 'react';
import styles from './styles.module.css';

export interface LoadingProps {
  text?: string;
}

class Loading extends React.Component<LoadingProps> {
  render() {
    return (
      <div className={styles.loadingContainer}>
        <IonSpinner
          color="primary"
          name="crescent"
        ></IonSpinner>
        {!!this.props.text && <IonText>{this.props.text}</IonText>}
      </div>
    );
  }
}

export default Loading;
