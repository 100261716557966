import styles from '@components/DateModal/styles.module.css';
import { IonDatetime, IonModal } from '@ionic/react';
import React from 'react';

type IonDateTimeProps = React.ComponentProps<typeof IonDatetime>;

interface DateModalProps {
  open: boolean;
  datetimeProps?: IonDateTimeProps;
  onWillDismiss?: () => void;
}

interface DateModalState {}

class DateModal extends React.Component<DateModalProps, DateModalState> {
  render() {
    return (
      <React.Fragment>
        <IonModal
          isOpen={this.props.open}
          className={styles.dateModal}
          onIonModalDidDismiss={this.props.onWillDismiss}
        >
          <IonDatetime
            locale="cs-CZ"
            {...this.props.datetimeProps}
          />
        </IonModal>
      </React.Fragment>
    );
  }
}

export default DateModal;
