import React from 'react';
import * as icons from 'react-bootstrap-icons';
import customIconsPaths, { BootstrapIconsType, CustomIcons, CustomIconsType } from './typeDefs';

interface IconProps extends icons.IconProps {
  iconName: BootstrapIconsType | CustomIconsType;
  size?: number;
}

class Icon extends React.Component<IconProps> {
  static defaultProps = {
    size: 16,
  };

  renderCustomIcon() {
    const pathElements: Array<JSX.Element> = [];
    let paths = customIconsPaths[this.props.iconName as CustomIcons];

    if (typeof paths === 'string') {
      paths = [paths];
    }

    for (let i = 0; i < paths.length; i++) {
      pathElements.push(
        <path
          key={i}
          d={paths[i]}
        ></path>,
      );
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill={this.props.color}
        className={this.props.className}
        style={{
          ...this.props.style,
          minWidth: this.props.size,
          maxWidth: this.props.size,
          minHeight: this.props.size,
          maxHeight: this.props.size,
        }}
      >
        {this.props.title && <title>{this.props.title}</title>}
        {pathElements}
      </svg>
    );
  }

  render() {
    if (Object.values(CustomIcons).includes(this.props.iconName as CustomIcons)) {
      return this.renderCustomIcon();
    }

    const BootstrapIcon = icons[this.props.iconName as BootstrapIconsType];
    const { iconName, ...bootstrapIconProps } = this.props;
    return (
      <BootstrapIcon
        {...bootstrapIconProps}
        style={{
          ...bootstrapIconProps.style,
          minWidth: this.props.size,
          maxWidth: this.props.size,
          minHeight: this.props.size,
          maxHeight: this.props.size,
        }}
      />
    );
  }
}

export default Icon;
