import { ApiRequestOptions, CacheOptions, CacheType } from '@api/core/typeDefs';
import { Drivers, Storage } from '@ionic/storage';
import getTelemetry from '@lib/getTelemetry';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import moment from 'moment';

export interface CacheValue {
  validUntil: number;
  [x: string]: any;
}

export interface TelemetryCacheValue extends CacheValue {
  dateStart: number;
  dateEnd: number;
}

// Default name of cache storage
const DEFAULT_STORAGE_NAME = '__cache_db';

// Data expiration time (in seconds)
const EXPIRATION_TIME = 3600;

// Time interval to cache telemetry (in seconds)
const TELEMETRY_CACHE_INTERVAL = 2592000;

let storage: Storage | null = null;

export const init = async (name: string = DEFAULT_STORAGE_NAME) => {
  storage = new Storage({
    name,
    driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
  });

  await storage.defineDriver(CordovaSQLiteDriver);
  await storage.create();
};

export const setRequestData = async (requestUrl: string, data: Record<string, any>): Promise<any> => {
  if (storage === null) {
    return;
  }

  const validUntil = moment().utc().valueOf() + EXPIRATION_TIME * 1000;
  const newValue: CacheValue = {
    validUntil,
    data,
  };

  return storage.set(requestUrl, newValue);
};

export const getCachedTelemetry = async (options: ApiRequestOptions, cacheOptions: CacheOptions) => {
  if (storage === null) {
    return [];
  }

  const urlPath = options.path as Record<string, any>;
  const storageKey = `telemetry/${urlPath.uuid}`;
  const currentTimeObj = moment().utc();
  const currentTime = currentTimeObj.valueOf();
  const cacheTelemetryStart = currentTimeObj.clone().subtract(TELEMETRY_CACHE_INTERVAL, 'seconds').valueOf();
  const validUntil = currentTimeObj.add(EXPIRATION_TIME, 'seconds').valueOf();
  let storedTelemetryValue: TelemetryCacheValue = await storage.get(storageKey);

  if (
    !storedTelemetryValue ||
    (storedTelemetryValue.validUntil < currentTime && storedTelemetryValue.dateEnd < cacheTelemetryStart)
  ) {
    const t = await getTelemetry({
      uuid: urlPath.uuid,
      dateStart: cacheTelemetryStart,
      dateEnd: currentTime,
    });

    const data: TelemetryCacheValue = {
      validUntil,
      dateStart: cacheTelemetryStart,
      dateEnd: currentTime,
      data: t,
    };

    await storage.set(storageKey, data);
  } else if (storedTelemetryValue.validUntil < currentTime) {
    const cacheDateEnd = storedTelemetryValue.dateEnd;
    const cacheData = storedTelemetryValue.data;

    const t = await getTelemetry({
      uuid: urlPath.uuid,
      dateStart: cacheDateEnd,
      dateEnd: currentTime,
    });

    const tCached = cacheData.filter(([timestamp, sensor, val]: any) => timestamp >= cacheTelemetryStart);

    const data: TelemetryCacheValue = {
      validUntil,
      dateStart: cacheTelemetryStart,
      dateEnd: currentTime,
      data: [...tCached, ...t],
    };

    await storage.set(storageKey, data);
  }

  storedTelemetryValue = await storage.get(storageKey);
  const dateStart = moment(urlPath.from_date).valueOf();
  const dateEnd = moment(urlPath.to_date).valueOf();

  if (!storedTelemetryValue || storedTelemetryValue.dateStart > dateEnd || !!cacheOptions.forceUpdate) {
    return await getTelemetry({
      uuid: urlPath.uuid,
      dateStart: dateStart,
      dateEnd: dateEnd,
    });
  } else if (dateStart > storedTelemetryValue.dateStart) {
    return storedTelemetryValue.data.filter(
      ([timestamp, sensor, val]: any) => timestamp >= dateStart && timestamp <= dateEnd,
    );
  } else {
    const t = await getTelemetry({
      uuid: urlPath.uuid,
      dateStart: dateStart,
      dateEnd: storedTelemetryValue.dateStart,
    });

    const tCached = storedTelemetryValue.data.filter(([timestamp, sensor, val]: any) => timestamp <= dateEnd);

    return [...t, ...tCached];
  }
};

export const getCachedData = async (
  requestUrl: string,
  options: ApiRequestOptions,
  cacheOptions: CacheOptions,
): Promise<any> => {
  const cacheType = cacheOptions.type || CacheType.DEFAULT;

  if (cacheType === CacheType.DEVICE_TELEMETRY) {
    return getCachedTelemetry(options, cacheOptions);
  }

  if (storage === null || !!cacheOptions.forceUpdate) {
    return null;
  }

  const currentTime = moment().utc().valueOf();
  const storedValue: CacheValue = await storage.get(requestUrl);

  if (!storedValue) {
    return null;
  } else if (storedValue.validUntil < currentTime) {
    await storage.remove(requestUrl);
    return null;
  } else {
    return storedValue.data;
  }
};

export const clearCachedData = async () => {
  if (storage === null) {
    return;
  }

  const keys = await storage.keys();

  for (const key of keys) {
    await storage.remove(key);
  }
};

const cache = {
  init,
  setRequestData,
  getCachedData,
  clearCachedData,
};

export default cache;
