import Router from '@components/Router';
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { homeOutline, homeSharp, menuOutline, menuSharp, personOutline, personSharp } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { MenuItem, RouteItem } from '../typeDefs';

interface TabMenuProps {
  routes: Array<RouteItem>;
  fullscreen?: boolean;
  isPublic?: boolean;
  accessToken?: string;
  refreshToken?: string;
}

const tabItems: Array<MenuItem> = [
  {
    title: 'Home',
    url: '/home',
    icons: {
      iosIcon: homeOutline,
      mdIcon: homeSharp,
    },
  },
  {
    title: 'Profil',
    url: '/profile',
    icons: {
      iosIcon: personOutline,
      mdIcon: personSharp,
    },
  },
  {
    title: 'Ostatní',
    url: '/menu',
    icons: {
      iosIcon: menuOutline,
      mdIcon: menuSharp,
    },
  },
];

class TabMenu extends React.Component<TabMenuProps> {
  isAuthorized() {
    return this.props.accessToken && this.props.refreshToken;
  }

  render() {
    return (
      <IonTabs>
        <IonRouterOutlet id="main">
          <Router routes={this.props.routes} />
        </IonRouterOutlet>
        <IonTabBar
          style={this.props.fullscreen || !this.isAuthorized() ? { display: 'none' } : undefined}
          slot="bottom"
        >
          {tabItems
            .filter((tabItem) => tabItem.url !== '/profile' || !this.props.isPublic)
            .map((tabItem, index) => (
              <IonTabButton
                disabled={!!tabItem.disabled}
                key={index}
                tab={tabItem.url.slice(1)}
                href={tabItem.url}
              >
                {tabItem.icons && (
                  <IonIcon
                    ios={tabItem.icons.iosIcon}
                    md={tabItem.icons.mdIcon}
                  />
                )}
                <IonLabel>{tabItem.title}</IonLabel>
              </IonTabButton>
            ))}
        </IonTabBar>
      </IonTabs>
    );
  }
}

export default connect((state: any) => {
  return {
    fullscreen: state.fullscreen,
    isPublic: state.isPublic,
    accessToken: state.accessToken,
    refreshToken: state.refreshToken,
  };
})(TabMenu);
