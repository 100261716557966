import { DeviceComplete } from '@api/models/DeviceModel';
import moment from 'moment';
import {
  DeviceSignal,
  SignalStrength,
  SignalStrengthIcon,
  SIGNAL_ACTIVE_COLOR,
  SIGNAL_OUTDATED_COLOR,
} from './typeDefs';

const ACTIVE_DAYS = 3;

export const getDeviceSignal = (device: DeviceComplete) => {
  const result: DeviceSignal = {
    strength: SignalStrength.NONE,
    icon: SignalStrengthIcon[SignalStrength.NONE],
    backgroundIcon: SignalStrengthIcon[SignalStrength.HIGH],
    color: SIGNAL_OUTDATED_COLOR,
  };

  if (!device.status || !device.status.signal) {
    return result;
  }

  const signal = device.status.signal;

  let updated = moment(signal.updated);
  const diff = moment().diff(updated, 'days');

  if (diff > ACTIVE_DAYS) {
    return result;
  }

  if (signal.strength) {
    result.strength = signal.strength;
    result.icon = SignalStrengthIcon[result.strength];
    result.color = SIGNAL_ACTIVE_COLOR;
  }

  return result;
};

export default getDeviceSignal;
