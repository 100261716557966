import { Capacitor } from '@capacitor/core';
import { registerFilesystem } from '@native/filesystem';
import { requestGeolocationPermissions } from '@native/location';
import * as pushNotifications from '@native/pushNotifications';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import store from './store';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// Push notifications
const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

if (isPushNotificationsAvailable) {
  pushNotifications.registerNotifications();
}

// Filesystem
const isFilesystemAvailable = Capacitor.isPluginAvailable('Filesystem');

if (isFilesystemAvailable) {
  registerFilesystem();
}

// Geolocation
const isGeolocationAvailable = Capacitor.isPluginAvailable('Geolocation');

if (isGeolocationAvailable) {
  requestGeolocationPermissions();
}
