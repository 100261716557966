import { IonSelect, IonSelectOption, IonText } from '@ionic/react';
import * as React from 'react';
import styles from './styles.module.css';
import { SelectOption } from './typeDefs';

type SelectProps = React.ComponentProps<typeof IonSelect> & {
  error?: string;
  required?: boolean;
  options?: SelectOption[];
};

interface SelectState {}

export class Select extends React.Component<SelectProps, SelectState> {
  render() {
    const { error, required, options, ...ionSelectProps } = this.props;

    return (
      <IonSelect
        fill="outline"
        labelPlacement="floating"
        {...ionSelectProps}
        mode="md"
        label={undefined}
        className={`${styles.select} ${error && 'ion-invalid ion-touched'}`}
      >
        {this.props.children}
        {typeof ionSelectProps.label !== 'undefined' && (
          <div slot="label">
            {ionSelectProps.label} {!!required && <IonText color="danger">*</IonText>}
          </div>
        )}
        {options &&
          options.map((option) => (
            <IonSelectOption
              key={option.value}
              value={option.value}
            >
              {option.text}
            </IonSelectOption>
          ))}
      </IonSelect>
    );
  }
}

export default Select;
