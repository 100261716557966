import { DeviceComplete } from '@api/models/DeviceModel';
import { Location } from '@api/models/LocationModel';
import withHistory from '@components/HOC/withHistory';
import LocationList from '@components/LocationList';
import { IonSearchbar } from '@ionic/react';
import { History } from 'history';
import * as React from 'react';
import styles from './styles.module.css';

interface RightSidebarProps {
  locationList: Location[];
  latestTelemetry: any;
  hiveList: DeviceComplete[];
  history: History;
  devices: DeviceComplete[];
  hive: DeviceComplete;
}

interface RightSidebarState {
  search: string;
}

class RightSidebar extends React.Component<RightSidebarProps, RightSidebarState> {
  constructor(props: RightSidebarProps) {
    super(props);

    this.onHiveClick = this.onHiveClick.bind(this);
    this.onSearchValueChange = this.onSearchValueChange.bind(this);

    this.state = {
      search: '',
    };
  }

  onHiveClick(hive: DeviceComplete) {
    this.props.history.replace(`/hive/${hive.uuid}`);
  }

  onSearchValueChange(e: Event) {
    const target = e.target as HTMLIonSearchbarElement;

    this.setState({
      search: target?.value || '',
    });
  }

  render() {
    return (
      <div className={styles.rightSidebar}>
        <IonSearchbar
          placeholder="Vyhledat úl podle názvu..."
          debounce={1000}
          onIonInput={this.onSearchValueChange}
          value={this.state.search}
        />
        <div className={styles.locationListContainer}>
          <LocationList
            devices={this.props.devices}
            locationList={this.props.locationList}
            hiveList={this.props.hiveList}
            latestTelemetry={this.props.latestTelemetry}
            onHiveClick={this.onHiveClick}
            search={this.state.search}
            showEmpty={false}
            selectedHive={this.props.hive.uuid}
          />
        </div>
      </div>
    );
  }
}

export default withHistory(RightSidebar);
