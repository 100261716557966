import { TextFieldTypes } from '@ionic/core';
import { IonButton, IonIcon, IonInput, IonText } from '@ionic/react';
import { eyeOffOutline as EyeOffIcon, eyeOutline as EyeIcon } from 'ionicons/icons';
import * as React from 'react';
import styles from './styles.module.css';

type TextFieldProps = React.ComponentProps<typeof IonInput> & {
  error?: string;
  showErrorText?: boolean;
};

interface TextFieldState {
  type?: TextFieldTypes;
}

export class TextField extends React.Component<TextFieldProps, TextFieldState> {
  static defaultProps = {
    showErrorText: true,
  };

  constructor(props: TextFieldProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.type === 'password') {
      this.setState({
        type: 'password',
      });
    }
  }

  render() {
    const { error, showErrorText, ...ionInputProps } = this.props;

    return (
      <IonInput
        fill="outline"
        labelPlacement="floating"
        {...ionInputProps}
        mode="md"
        autocomplete={ionInputProps.type === 'password' ? 'new-password' : 'off'}
        type={this.state.type || ionInputProps.type}
        label={undefined}
        required={undefined}
        className={`${styles.textField} ${error && 'ion-invalid ion-touched'}`}
        {...(!!this.props.showErrorText && { errorText: error })}
      >
        {this.props.children}
        {typeof ionInputProps.label !== 'undefined' && (
          <div slot="label">
            {ionInputProps.label} {!!ionInputProps.required && <IonText color="danger">*</IonText>}
          </div>
        )}
        {ionInputProps.type === 'password' && (
          <IonButton
            fill="clear"
            slot="end"
            color="dark"
            onClick={() => {
              this.setState({
                type: this.state.type === 'password' ? 'text' : 'password',
              });
            }}
          >
            <IonIcon
              slot="icon-only"
              icon={this.state.type === 'password' ? EyeIcon : EyeOffIcon}
              style={{
                fontSize: '24px',
              }}
            />
          </IonButton>
        )}
      </IonInput>
    );
  }
}

export default TextField;
