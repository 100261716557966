import ApiError from '@api/core/ApiError';
import { ApiConfig, ApiRequestOptions, ApiResult, CacheOptions } from '@api/core/typeDefs';
import TokenService from '@api/services/TokenService';
import { Capacitor } from '@capacitor/core';
import cache, { clearCachedData } from '@lib/cache';
import { storageGet, storageSet } from '@src/storage';
import store from '@src/store';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, HttpStatusCode } from 'axios';
import * as pushNotifications from '../../native/pushNotifications';

const isDefined = <T>(value: T | null | undefined): value is Exclude<T, null | undefined> => {
  return value !== undefined && value !== null;
};

const isString = (value: any): value is string => {
  return typeof value === 'string';
};

const isStringWithValue = (value: any): value is string => {
  return isString(value) && value !== '';
};

const isBlob = (value: any): value is Blob => {
  return (
    typeof value === 'object' &&
    typeof value.type === 'string' &&
    typeof value.stream === 'function' &&
    typeof value.arrayBuffer === 'function' &&
    typeof value.constructor === 'function' &&
    typeof value.constructor.name === 'string' &&
    /^(Blob|File)$/.test(value.constructor.name) &&
    /^(Blob|File)$/.test(value[Symbol.toStringTag])
  );
};

const isSuccess = (status: number): boolean => {
  return status >= 200 && status < 300;
};

const getQueryString = (params: Record<string, any>): string => {
  const qs: string[] = [];

  const append = (key: string, value: any) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
  };

  const process = (key: string, value: any) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          process(key, v);
        });
      } else if (typeof value === 'object') {
        Object.entries(value).forEach(([k, v]) => {
          process(`${key}[${k}]`, v);
        });
      } else {
        append(key, value);
      }
    }
  };

  Object.entries(params).forEach(([key, value]) => {
    process(key, value);
  });

  if (qs.length > 0) {
    return `?${qs.join('&')}`;
  }

  return '';
};

const getUrl = (config: ApiConfig, options: ApiRequestOptions): string => {
  const encoder = config.ENCODE_PATH || encodeURI;

  const path = options.url
    .replace('{api-version}', config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      if (options.path?.hasOwnProperty(group)) {
        return encoder(String(options.path[group]));
      }
      return substring;
    });

  const url = path;
  if (options.query) {
    return `${url}${getQueryString(options.query)}`;
  }
  return url;
};

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;

const resolve = async <T>(options: ApiRequestOptions, resolver?: T | Resolver<T>): Promise<T | undefined> => {
  if (typeof resolver === 'function') {
    return (resolver as Resolver<T>)(options);
  }
  return resolver;
};

const getHeaders = async (config: ApiConfig, options: ApiRequestOptions): Promise<Record<string, string>> => {
  const token = await resolve(options, config.TOKEN);
  const additionalHeaders = await resolve(options, config.HEADERS);

  // Merge all headers
  const headers = Object.entries({
    Accept: 'application/json',
    ...additionalHeaders,
    ...options.headers,
  })
    // Filter undefined
    .filter(([_, value]) => isDefined(value))
    // Remove duplicates
    .reduce(
      (headers, [key, value]) => ({
        ...headers,
        [key]: String(value),
      }),
      {} as Record<string, string>,
    );

  if (isStringWithValue(token)) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  if (options.body) {
    if (options.mediaType) {
      headers['Content-Type'] = options.mediaType;
    } else if (isBlob(options.body)) {
      headers['Content-Type'] = options.body.type || 'application/octet-stream';
    } else if (isString(options.body)) {
      headers['Content-Type'] = 'text/plain';
    }
  }

  return headers;
};

const getRequestBody = (options: ApiRequestOptions): any => {
  if (options.body) {
    return options.body;
  }
  return undefined;
};

const sendRequest = async <T>(
  config: ApiConfig,
  options: ApiRequestOptions,
  url: string,
  body: any,
  headers: Record<string, string>,
): Promise<AxiosResponse<T>> => {
  const requestConfig: AxiosRequestConfig = {
    url,
    headers,
    data: body,
    method: options.method,
    withCredentials: config.WITH_CREDENTIALS,
  };

  try {
    return await axios.request(requestConfig);
  } catch (error) {
    const axiosError = error as AxiosError<T>;
    const axiosResponse = axiosError.response;
    // let response: ApiResult | undefined;

    if (axiosResponse) {
      return axiosResponse;

      // response = {
      //     url,
      //     ok: false,
      //     status: axiosResponse.status,
      //     statusText: axiosResponse.statusText,
      //     body: getResponseBody(axiosResponse)
      // };
    }

    throw new ApiError({
      request: options,
      message: 'AxiosError',
      response: undefined,
      error,
    });
  }
};

const getResponseBody = (response: AxiosResponse<any>): any => {
  if (response.status !== HttpStatusCode.NoContent) {
    return response.data;
  }

  return undefined;
};

const logout = async () => {
  store.dispatch({
    type: 'set',
    payload: {
      refreshToken: null,
      accessToken: null,
      isPublic: false,
    },
  });

  if (Capacitor.isPluginAvailable('PushNotifications')) {
    await pushNotifications.removeListeners();
  }

  await storageSet('refreshToken', null);
  await storageSet('accessToken', null);
  await storageSet('isPublic', false);
  await clearCachedData();
};

/**
 * Request method
 * @param config The OpenAPI configuration object
 * @param options The request options from the service
 * @returns Promise<T>
 * @throws ApiError
 */
export const request = <T>(config: ApiConfig, options: ApiRequestOptions): Promise<T> => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = getUrl(config, options);
      const baseUrl = `${config.BASE}${url}`;
      const body = getRequestBody(options);
      const headers = await getHeaders(config, options);
      const useCache = !!options.cache && options.cache.enabled;

      if (useCache) {
        const cacheOptions = options.cache as CacheOptions;

        // Try to get cached data
        const cachedData = await cache.getCachedData(url, options, cacheOptions);

        if (cachedData !== null) {
          return resolve(cachedData);
        }
      }

      // Fetch data
      const response = await sendRequest<T>(config, options, baseUrl, body, headers);
      const responseBody = getResponseBody(response);

      const result: ApiResult = {
        url: baseUrl,
        ok: isSuccess(response.status),
        status: response.status,
        statusText: response.statusText,
        body: responseBody,
      };

      if (url.indexOf('api/send-reset-passwd') >= 0 || url.indexOf('api/signin') >= 0) {
        result.body = {
          ...(result.body && { ...result.body }),
          status: result.status,
        };
      }

      if (result.status === HttpStatusCode.Unauthorized && url.indexOf('api/token/refresh') < 0) {
        if (Capacitor.getPlatform() !== 'web') {
          let accessToken = null;

          // Try to update access token and resend request
          try {
            accessToken = await TokenService.tokenRefreshCreate({
              refresh: await storageGet('refreshToken'),
            });
          } catch (e: any) {}

          if (accessToken && accessToken.access) {
            store.dispatch({
              type: 'set',
              payload: {
                accessToken: accessToken.access,
              },
            });

            await storageSet('accessToken', accessToken.access);

            try {
              const r: any = await request(config, options);
              resolve(r);
            } catch (e: any) {
              // console.log(e);
              reject(e);
              return;
            }
          } else {
            await logout();
            resolve(response as any);
            return;
          }
        } else {
          await logout();
          resolve(response as any);
          return;
        }
      }

      // Catch errors
      if (!result.ok) {
        const customErrors = {
          ...options.errors,
        };
        const message = customErrors[result.status] || result.statusText;

        throw new ApiError({
          request: options,
          response: result,
          message,
        });
      }

      if (useCache) {
        // Set cache data
        await cache.setRequestData(url, result.body);
      }

      resolve(result.body);
    } catch (e) {
      // console.log(e);
      // resolve(e);
      reject(e);
    }
  });
};

export default request;
