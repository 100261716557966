import { ApiRequestOptions, ApiResult } from '@api/core/typeDefs';

interface ResponseData {
  url?: string;
  status?: number;
  statusText?: string;
  body: any;
}

interface ConstructorParams {
  request: ApiRequestOptions;
  response?: ApiResult;
  message: string;
  error?: any;
}

export class ApiError extends Error {
  public response: ResponseData | undefined;
  public request: ApiRequestOptions;
  public error: any;

  constructor(params: ConstructorParams) {
    super(params.message);

    this.name = 'ApiError';
    this.request = params.request;
    this.error = params.error;

    const response = params.response;

    if (response) {
      this.response = {
        url: response.url,
        status: response.status,
        statusText: response.statusText,
        body: response.body,
      };
    }
  }
}

export default ApiError;
