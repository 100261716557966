import { AddressRequest, User } from '@api/models/UserModel';
import UserService from '@api/services/UserService';
import Select from '@components/Select';
import { SelectOption } from '@components/Select/typeDefs';
import TextField from '@components/TextField';
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonText } from '@ionic/react';
import NotificationUtil from '@util/Notification';
import Validation from '@util/Validation';
import equal from 'fast-deep-equal';
import { saveOutline as SaveIcon } from 'ionicons/icons';
import React from 'react';
import Header from '../../components/Header';
import styles from './styles.module.css';

const pageTitle = 'Profil';

interface ProfileProps {}

interface ProfileState {
  user: User | null;
  address: AddressRequest | null;
  loading: boolean;
  _formData: Record<string, any>;
  formData: Record<string, any>;
  formErrors: Record<string, string>;
}

const notificationOptions: SelectOption[] = [
  {
    value: 'none',
    text: 'Vypnuto',
  },
  {
    value: 'push',
    text: 'Push notifikace',
  },
  {
    value: 'email',
    text: 'Email notifikace',
  },
  {
    value: 'all',
    text: 'Vše',
  },
];

const languageOptions: SelectOption[] = [
  {
    value: 'en',
    text: 'English',
  },
  {
    value: 'cs',
    text: 'Čeština',
  },
];

const callCodeOptions: SelectOption[] = [
  {
    value: '+420',
    text: '+420',
  },
  {
    value: '+421',
    text: '+421',
  },
];

const initialData = {
  user: {
    username: '',
    firstName: '',
    lastName: '',
    language: 'cs',
    notification: 'none',
  },
  address: {
    street: '',
    district: '',
    city: '',
    country: '',
    zip: '',
    countryCallCode: null,
    phone: '',
  },
};

class Profile extends React.Component<ProfileProps, ProfileState> {
  constructor(props: ProfileProps) {
    super(props);

    this.handleSave = this.handleSave.bind(this);
    this.handleChangeUserFormData = this.handleChangeUserFormData.bind(this);
    this.handleChangeAddressFormData = this.handleChangeAddressFormData.bind(this);

    this.state = {
      user: null,
      address: null,
      loading: false,
      _formData: initialData,
      formData: initialData,
      formErrors: {},
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  isFormChanged() {
    return !equal(this.state.formData, this.state._formData);
  }

  validateUser() {
    const formErrors: Record<string, string> = {};

    Validation.required(this.state.formData.user.username, 'username', formErrors);
    Validation.required(this.state.formData.user.language, 'language', formErrors);
    Validation.required(this.state.formData.user.notification, 'notification', formErrors);

    if (!formErrors.username) {
      Validation.email(this.state.formData.user.username, 'username', formErrors);
    }

    this.setState({
      formErrors: formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  }

  async handleSave() {
    if (!this.validateUser()) {
      return;
    }

    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      // Save user if changed
      if (!equal(this.state.formData.user, this.state._formData.user)) {
        await this.saveUser();
      }

      // Save address if changed
      if (!equal(this.state.formData.address, this.state._formData.address)) {
        await this.saveAddress();
      }

      NotificationUtil.success(`Uživatelské údaje byly úspěšně změněny`);

      // Disable save button, then fetch new data
      this.setState(
        {
          _formData: this.state.formData,
        },
        async () => {
          await this.fetchData(true);
        },
      );
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při ukládání dat');
    }

    this.setState({
      loading: false,
    });
  }

  async saveUser() {
    let user: User | null = this.state.user;
    const frontend_settings: Record<string, any> | undefined = user ? user.frontend_settings : {};

    await UserService.userUpdate({
      username: this.state.formData.user.username,
      first_name: this.state.formData.user.firstName,
      last_name: this.state.formData.user.lastName,
      language: this.state.formData.user.language,
      frontend_settings: {
        ...frontend_settings,
        notifications_delivery: this.state.formData.user.notification,
      },
    });
  }

  async saveAddress() {
    await UserService.updateUserAddress({
      street: this.state.formData.address.street,
      city: this.state.formData.address.city,
      district: this.state.formData.address.district,
      zip: this.state.formData.address.zip,
      country: this.state.formData.address.country,
      country_call_code: this.state.formData.address.countryCallCode,
      phone: this.state.formData.address.phone,
    });
  }

  getUserFormData(user: User) {
    return {
      username: user.username,
      firstName: user.first_name || '',
      lastName: user.last_name || '',
      language: user.language,
      notification: user.frontend_settings?.notifications_delivery || 'none',
    };
  }

  getAddressFormData(address: AddressRequest) {
    return {
      street: address.street,
      district: address.district,
      city: address.city,
      country: address.country,
      zip: address.zip,
      countryCallCode: address.country_call_code,
      phone: address.phone,
    };
  }

  async fetchData(forceUpdate = false) {
    try {
      const user = await UserService.userRetrieve(forceUpdate);
      const address = await UserService.userAddress(forceUpdate);

      const newFormData = {
        user: this.getUserFormData(user),
        address: this.getAddressFormData(address),
      };

      this.setState({
        user: user,
        address: address,
        formData: newFormData,
        _formData: newFormData,
      });
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při načítání dat');
    }
  }

  handleChangeUserFormData(e: any) {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          user: {
            ...this.state.formData.user,
            [e.target.name]: e.target.value,
          },
        },
      },
      () => {
        this.validateUser();
      },
    );
  }

  handleChangeAddressFormData(e: any) {
    this.setState({
      formData: {
        ...this.state.formData,
        address: {
          ...this.state.formData.address,
          [e.target.name]: e.target.value,
        },
      },
    });
  }

  renderUserForm() {
    return (
      <div className={styles.content}>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol>
              <IonText color="medium">
                <h4 className={styles.blockTitle}>Základní informace</h4>
              </IonText>
            </IonCol>
            <IonCol size="auto">
              <IonButton
                size="small"
                onClick={this.handleSave}
                disabled={!this.isFormChanged() || this.state.loading}
              >
                Uložit
                <IonIcon
                  slot="end"
                  icon={SaveIcon}
                ></IonIcon>
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TextField
                label="Jméno"
                name="firstName"
                onIonInput={this.handleChangeUserFormData}
                value={this.state.formData.user.firstName}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TextField
                label="Príjmení"
                name="lastName"
                onIonInput={this.handleChangeUserFormData}
                value={this.state.formData.user.lastName}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TextField
                label="Email"
                name="username"
                required
                onIonInput={this.handleChangeUserFormData}
                value={this.state.formData.user.username}
                error={this.state.formErrors.username}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Select
                label="Jazyk"
                name="language"
                required
                disabled={true}
                value={this.state.formData.user.language}
                error={this.state.formErrors.language}
                options={languageOptions}
                onIonChange={this.handleChangeUserFormData}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Select
                label="Doručování notifikací"
                name="notification"
                required
                value={this.state.formData.user.notification}
                error={this.state.formErrors.notification}
                options={notificationOptions}
                onIonChange={this.handleChangeUserFormData}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }

  renderAddressForm() {
    return (
      <div className={styles.content}>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol>
              <IonText color="medium">
                <h4 className={styles.blockTitle}>Adresa</h4>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TextField
                label="Ulice"
                name="street"
                onIonInput={this.handleChangeAddressFormData}
                value={this.state.formData.address.street}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TextField
                label="Město"
                name="city"
                onIonInput={this.handleChangeAddressFormData}
                value={this.state.formData.address.city}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TextField
                label="Městská část"
                name="district"
                onIonInput={this.handleChangeAddressFormData}
                value={this.state.formData.address.district}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size="12"
              sizeMd="8"
            >
              <TextField
                label="Stát"
                name="country"
                onIonInput={this.handleChangeAddressFormData}
                value={this.state.formData.address.country}
              />
            </IonCol>
            <IonCol
              size="12"
              sizeMd="4"
            >
              <TextField
                label="PSČ"
                name="zip"
                onIonInput={this.handleChangeAddressFormData}
                value={this.state.formData.address.zip}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size="12"
              sizeMd="5"
              sizeLg="4"
              sizeXl="3"
            >
              <Select
                label="Předvolba"
                name="countryCallCode"
                value={this.state.formData.address.countryCallCode}
                options={callCodeOptions}
                onIonChange={this.handleChangeAddressFormData}
              />
            </IonCol>
            <IonCol
              size="12"
              sizeMd="7"
              sizeLg="8"
              sizeXl="9"
            >
              <TextField
                label="Telefonní číslo"
                name="phone"
                onIonInput={this.handleChangeAddressFormData}
                value={this.state.formData.address.phone}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }

  render() {
    return (
      <IonPage>
        <Header title={pageTitle} />
        <IonContent>
          <div className={styles.container}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  sizeMd="6"
                >
                  {this.renderUserForm()}
                </IonCol>
                <IonCol
                  size="12"
                  sizeMd="6"
                >
                  {this.renderAddressForm()}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default Profile;
