import { createStore } from 'redux';

export interface Notification {
  timestamp: number;
  message: string;
  type: 'success' | 'error' | 'loading';
}

export interface StoreState {
  graph_interval: number;
  graph_interval_last: number;
  graph_custom_interval: any;
  graph_is_custom_last: boolean;
  graph_interval_from: string | null;
  graph_agg_func: string;
  fullscreen: boolean;
  graph_mode: string;
  graph_time: boolean;
  graph_time_start: string | null;
  graph_time_end: string | null;
  logged_in: boolean;
  refreshToken: string | null;
  accessToken: string | null;
  devicesCache: any;
  isPublic: boolean;
  notification: Notification | null;
  showScales: boolean;
}

const initialState: StoreState = {
  graph_interval: 1000 * 60 * 60 * 24 * 7,
  graph_interval_last: 1000 * 60 * 60 * 24 * 7,
  graph_custom_interval: null,
  graph_is_custom_last: false,
  graph_interval_from: null,
  graph_agg_func: 'none',
  graph_mode: 'realtime',
  graph_time: false,
  graph_time_start: null,
  graph_time_end: null,
  fullscreen: false,
  logged_in: false,
  refreshToken: null,
  accessToken: null,
  devicesCache: null,
  isPublic: false,
  notification: null,
  showScales: false,
};

const mainReducer = (state = initialState, action: any) => {
  if (action.type === 'set') {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

const store = createStore(mainReducer);

export default store;
