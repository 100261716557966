import { IonIcon } from '@ionic/react';
import React from 'react';
import './styles.css';

interface NavButtonProps {
  btnKey: any;
  icon?: string;
  text?: string;
  onClick?: (e: any, key: any) => void;
  value?: any;
  styles?: Record<string, any>;
  disabled?: boolean;
  className?: string;
}

class NavButton extends React.Component<NavButtonProps> {
  render() {
    const isActive = this.props.value === this.props.btnKey;

    return (
      <div
        className={`nav-button ${this.props.className || ''} ${isActive ? 'nav-button-active' : ''} ${this.props.disabled ? 'nav-button-disabled' : ''}`}
        onClick={(e: any) => {
          if (!!this.props.disabled) {
            return;
          }

          if (!this.props.onClick) {
            return;
          }

          this.props.onClick(e, this.props.btnKey);
        }}
        style={this.props.styles}
      >
        {this.props.text && (
          <div className={isActive ? 'nav-button-text nav-button-text-active' : 'nav-button-text'}>
            {this.props.text}
          </div>
        )}
        {this.props.icon && (
          <IonIcon
            className="nav-button-icon"
            icon={this.props.icon}
          />
        )}
      </div>
    );
  }
}

export default NavButton;
