import { DeviceComplete } from '@api/models/DeviceModel';
import { LocationJournalCreateRequest, LocationJournalResponse } from '@api/models/LocationModel';
import DeviceService from '@api/services/DeviceService';
import DateModal from '@components/DateModal';
import Icon from '@components/Icon';
import Select from '@components/Select';
import { SelectOption } from '@components/Select/typeDefs';
import TextField from '@components/TextField';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import NotificationUtil from '@util/Notification';
import Validation from '@util/Validation';
import { addOutline as AddOutlineIcon, calendarOutline as CalendarIcon } from 'ionicons/icons';
import moment from 'moment';
import * as React from 'react';
import styles from './styles.module.css';

interface JournalProps {
  hive: DeviceComplete;
  journal: LocationJournalResponse[];
  date?: number;
  onJournalUpdate?: () => void;
}

interface JournalState {
  dateModalOpen: boolean;
  addModalOpen: boolean;
  selectedDate: any;
  formData: Record<string, any>;
  formErrors: Record<string, string>;
  loading: boolean;
  deleteAlertOpen: boolean;
  deleteJournalItem: LocationJournalResponse | null;
}

const initialData = {
  date: '',
  action: '',
  value: '',
  note: '',
};

const actionOptions: SelectOption[] = [
  {
    value: 'hive_check_out',
    text: 'Prohlídka úlu',
  },
  {
    value: 'attachment_added',
    text: 'Přidán nástavek',
  },
  {
    value: 'attachment_removed',
    text: 'Odebrán nástavek',
  },
  {
    value: 'swarming',
    text: 'Rojení',
  },
  {
    value: 'honey_harvesting',
    text: 'Medobraní',
  },
  {
    value: 'feeding',
    text: 'Krmeni',
  },
  {
    value: 'healing',
    text: 'Léčení',
  },
  {
    value: 'habitat_change',
    text: 'Změna stanoviště',
  },
  {
    value: 'evasion',
    text: 'Úhyn',
  },
  {
    value: 'infection',
    text: 'Nákaza',
  },
  {
    value: 'detachmnent',
    text: 'Oddělek',
  },
  {
    value: 'modifying_frames',
    text: 'Úprava rámků',
  },
  {
    value: 'new_mother',
    text: 'Výměna matky',
  },
];

const actionsWithState = [
  'attachment_added',
  'attachment_removed',
  'swarming',
  'honey_harvesting',
  'feeding',
  'new_mother',
];

class Journal extends React.Component<JournalProps, JournalState> {
  constructor(props: JournalProps) {
    super(props);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.isDateEnabled = this.isDateEnabled.bind(this);
    this.handleChangeFormData = this.handleChangeFormData.bind(this);
    this.handleJournalItemCreate = this.handleJournalItemCreate.bind(this);
    this.handleJournalItemDelete = this.handleJournalItemDelete.bind(this);
    this.renderJournalRow = this.renderJournalRow.bind(this);
    this.handleModalDismiss = this.handleModalDismiss.bind(this);
    this.handleCreateAddModal = this.handleCreateAddModal.bind(this);

    const currentDate = moment().format('YYYY-MM-DDTHH:mm:ss');

    this.state = {
      dateModalOpen: false,
      addModalOpen: false,
      selectedDate: currentDate,
      formData: {
        ...initialData,
        date: moment(currentDate).format('DD.MM.YYYY HH:mm'),
      },
      formErrors: {},
      loading: false,
      deleteAlertOpen: false,
      deleteJournalItem: null,
    };
  }

  componentDidUpdate(prevProps: JournalProps) {
    if (prevProps.date !== this.props.date) {
      if (!this.props.date) {
        return;
      }

      const selectedDate = moment(this.props.date).format('YYYY-MM-DDTHH:mm:ss');
      this.setState({
        selectedDate,
        formData: {
          ...this.state.formData,
          date: moment(selectedDate).format('DD.MM.YYYY HH:mm'),
        },
      });
    }
  }

  validateJournalItem() {
    const formErrors: Record<string, string> = {};

    Validation.required(this.state.formData.date, 'date', formErrors);
    Validation.required(this.state.formData.action, 'action', formErrors);

    if (this.state.formData.action && actionsWithState.indexOf(this.state.formData.action) >= 0) {
      Validation.required(this.state.formData.value, 'value', formErrors);
    }

    this.setState({
      formErrors,
    });

    return Object.keys(formErrors).length <= 0;
  }

  async handleJournalItemCreate() {
    if (!this.validateJournalItem()) {
      return;
    }

    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      let value = parseFloat(this.state.formData.value);

      if (isNaN(value)) {
        value = this.state.formData.action === 'new_mother' ? moment().year() : 0;
      }

      const selectedDate = moment(this.state.formData.date, 'DD.MM.YYYY HH:mm').unix();

      const requestBody: LocationJournalCreateRequest = {
        timestamp: selectedDate,
        type: this.state.formData.action,
        record: {
          note: this.state.formData.note,
        },
        value,
      };

      await DeviceService.deviceJournalRecordCreate(this.props.hive.uuid, requestBody);
      NotificationUtil.success(`Nový záznam byl úspěšně vytvořen`);

      const currentDate = moment().format('YYYY-MM-DDTHH:mm:ss');

      this.setState({
        formData: {
          ...initialData,
          date: moment(currentDate).format('DD.MM.YYYY HH:mm'),
        },
      });

      if (this.props.onJournalUpdate) {
        this.props.onJournalUpdate();
      }

      this.handleModalDismiss();
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při ukládání dat');
    }

    this.setState({
      loading: false,
    });
  }

  async handleJournalItemDelete() {
    if (!this.state.deleteJournalItem || typeof this.state.deleteJournalItem.id !== 'number') {
      return;
    }

    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      await DeviceService.journalRecordDelete(this.state.deleteJournalItem.id, this.props.hive.uuid);

      if (this.props.onJournalUpdate) {
        this.props.onJournalUpdate();
      }
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při mazání záznamů z deníku');
    }

    this.setState({
      deleteJournalItem: null,
      loading: false,
    });
  }

  handleChangeFormData(e: any) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleDateChange(e: any) {
    let val = e.target.value;

    this.setState({
      selectedDate: val,
      formData: {
        ...this.state.formData,
        date: moment(val).format('DD.MM.YYYY HH:mm'),
      },
    });
  }

  isDateEnabled(dateString: string) {
    const date = moment(dateString);
    const now = moment().endOf('day');

    if (date.isAfter(now)) {
      return false;
    }

    return true;
  }

  handleCreateAddModal() {
    this.setState({
      addModalOpen: true,
    });
  }

  handleModalDismiss() {
    this.setState({
      addModalOpen: false,
      formData: {
        ...initialData,
        date: this.state.formData.date,
      },
      formErrors: {},
    });
  }

  renderJournalValueField() {
    let label = 'Hodnota';

    switch (this.state.formData.action) {
      case 'attachment_added':
      case 'attachment_removed':
        label = 'Počet';
        break;
      case 'swarming':
        label = 'Hmotnost roje (kg)';
        break;
      case 'honey_harvesting':
        label = 'Výnos (kg)';
        break;
      case 'feeding':
        label = 'Množství (kg)';
        break;
      case 'new_mother':
        label = 'Rok vylíhnutí';
        break;
    }

    const disabled = !this.state.formData.action || actionsWithState.indexOf(this.state.formData.action) < 0;

    return (
      <TextField
        label={label}
        name="value"
        value={this.state.formData.value}
        error={this.state.formErrors.value}
        showErrorText={false}
        disabled={disabled}
        required={!disabled}
        onIonInput={this.handleChangeFormData}
      />
    );
  }

  renderJournalRow(item: LocationJournalResponse, index: number) {
    let actionText = '-';
    let valueText = '-';
    let note = '-';
    const actionIndex = actionOptions.findIndex((option) => option.value === item.type);

    if (actionIndex >= 0) {
      actionText = actionOptions[actionIndex].text;

      if (actionsWithState.indexOf(item.type) >= 0 && typeof item.value === 'number') {
        valueText = item.value.toString();
      }
    }

    if (item.record && item.record.note) {
      note = item.record.note;
    }

    return (
      <IonRow
        key={index}
        className={styles.journalRow}
      >
        <IonCol size="auto">{moment(item.timestamp * 1000).format('DD.MM.YYYY - HH:mm')}</IonCol>
        <IonCol>{actionText}</IonCol>
        <IonCol>{valueText}</IonCol>
        <IonCol>{note}</IonCol>
        <IonCol size="auto">
          <IonButton
            color="danger"
            size="small"
            disabled={this.state.loading}
            onClick={() => {
              this.setState({
                deleteAlertOpen: true,
                deleteJournalItem: item,
              });
            }}
          >
            <Icon iconName="Trash" />
          </IonButton>
        </IonCol>
      </IonRow>
    );
  }

  renderJournalAddModal() {
    return (
      <IonModal
        onDidDismiss={this.handleModalDismiss}
        isOpen={this.state.addModalOpen}
      >
        <IonHeader>
          <IonToolbar className="modal-toolbar">
            <IonButtons slot="start">
              <IonButton onClick={this.handleModalDismiss}>Zrušit</IonButton>
            </IonButtons>
            <IonTitle>Nový záznam v deníku</IonTitle>
            <IonButtons slot="end">
              <IonButton
                strong={true}
                disabled={this.state.loading}
                onClick={this.handleJournalItemCreate}
              >
                OK
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="modal-content">
          <div className={styles.modalContent}>
            <div className={styles.modalTabContent}>{this.renderJournalForm()}</div>
          </div>
        </IonContent>
      </IonModal>
    );
  }

  renderJournalForm() {
    return (
      <div className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Datum a čas"
                name="date"
                value={this.state.formData.date}
                error={this.state.formErrors.date}
                showErrorText={false}
                required
                readonly
              >
                <IonButton
                  fill="clear"
                  slot="end"
                  color="dark"
                  className="ion-align-self-center"
                  onClick={() => {
                    this.setState({
                      dateModalOpen: true,
                    });
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    icon={CalendarIcon}
                    style={{
                      fontSize: '24px',
                    }}
                  />
                </IonButton>
              </TextField>
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <Select
                label="Úkon"
                name="action"
                required
                value={this.state.formData.action}
                error={this.state.formErrors.action}
                options={actionOptions}
                onIonChange={(e: any) => {
                  const { value, ...otherErrors } = this.state.formErrors;

                  this.setState({
                    formErrors: otherErrors,
                  });

                  this.handleChangeFormData(e);
                }}
              />
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              {this.renderJournalValueField()}
            </IonCol>
            <IonCol
              size="12"
              sizeLg="6"
            >
              <TextField
                label="Poznámka"
                name="note"
                value={this.state.formData.note}
                onIonInput={this.handleChangeFormData}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.journalHeaderContainer}>
          <IonText className={styles.journalTitle}>Včelařský deník</IonText>
          <div className={styles.journalAddContainer}>
            <IonText
              style={{ flex: 1 }}
              className={styles.journalDate}
            >
              {this.state.formData.date}
            </IonText>
            <IonButton
              className={styles.journalAddButton}
              size="small"
              fill="clear"
              color="success"
              shape="round"
              onClick={this.handleCreateAddModal}
            >
              <IonIcon
                slot="icon-only"
                icon={AddOutlineIcon}
              ></IonIcon>
            </IonButton>
          </div>
        </div>
        <div className={styles.journal}>
          {/* {this.renderJournalForm()} */}
          <div className={styles.journalItems}>
            {this.props.journal.length <= 0 && (
              <IonRow className={styles.journalEmpty}>
                <IonText>Nebyly nalezeny žádné záznamy</IonText>
              </IonRow>
            )}
            {this.props.journal.map(this.renderJournalRow)}
          </div>
        </div>
        <DateModal
          open={this.state.dateModalOpen}
          onWillDismiss={() => {
            this.setState({
              dateModalOpen: false,
            });
          }}
          datetimeProps={{
            onIonChange: this.handleDateChange,
            isDateEnabled: this.isDateEnabled,
            value: this.state.selectedDate,
          }}
        />

        <IonAlert
          isOpen={this.state.deleteAlertOpen}
          header="Mazání záznamu"
          subHeader={
            this.state.deleteJournalItem
              ? moment(this.state.deleteJournalItem.timestamp * 1000).format('DD.MM.YYYY - HH:mm')
              : ''
          }
          message="Opravdu si přejete smazat daný záznam?"
          buttons={[
            {
              text: 'Ne',
              role: 'cancel',
            },
            {
              text: 'Ano',
              role: 'confirm',
              handler: this.handleJournalItemDelete,
            },
          ]}
          onDidDismiss={() => {
            this.setState({
              deleteAlertOpen: false,
            });
          }}
        ></IonAlert>
        {this.renderJournalAddModal()}
      </React.Fragment>
    );
  }
}

export default Journal;
