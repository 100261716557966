import { BreachedFence, DeviceComplete } from '@api/models/DeviceModel';
import { Location } from '@api/models/LocationModel';
import Icon from '@components/Icon';
import { IonFab, IonFabButton, IonFabList, IonIcon } from '@ionic/react';
import getSensorValue from '@lib/data/Device/getSensorValue';
import getTrajectory from '@lib/data/Device/getTrajectory';
import {
  DeviceTelemetry,
  DeviceTrajectory,
  LocationLevel,
  LocationLevelColor,
  SensorEnum,
} from '@lib/data/Device/typeDefs';
import { StoreState } from '@src/store';
import NotificationUtil from '@util/Notification';
import { ellipsisVertical as EllipsisVerticalIcon } from 'ionicons/icons';
import L from 'leaflet';
import moment from 'moment';
import * as React from 'react';
import { Circle, CircleMarker, MapContainer, Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import { connect } from 'react-redux';
import styles from './styles.module.css';

let HiveMarker = L.icon({
  iconUrl: '/assets/map/hive-marker.png',
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

let HiveMovingMarker = L.icon({
  iconUrl: '/assets/map/hive-moving-marker-open.png',
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

interface MapTabProps {
  graph_interval?: number;
  graph_mode?: string;
  graph_time_start?: string | null;
  graph_time_end?: string | null;
  graph_interval_from?: string | null;
  graph_time?: boolean;
  hive: DeviceComplete;
  location: Location;
  telemetry: any;
}
interface MapTabState {
  trajectory: DeviceTrajectory;
}

export class MapTab extends React.Component<MapTabProps, MapTabState> {
  private mapRef: React.RefObject<L.Map> = React.createRef();

  constructor(props: MapTabProps) {
    super(props);

    this.enableTrajectory = this.enableTrajectory.bind(this);

    this.state = {
      trajectory: [],
    };
  }

  componentDidMount() {
    if (this.isHiveMoved()) {
      this.enableTrajectory();
    }
  }

  componentDidUpdate(prevProps: MapTabProps) {
    if (
      prevProps.hive.uuid !== this.props.hive.uuid ||
      prevProps.graph_interval !== this.props.graph_interval ||
      prevProps.graph_mode !== this.props.graph_mode ||
      prevProps.graph_time !== this.props.graph_time ||
      prevProps.graph_time_start !== this.props.graph_time_start ||
      prevProps.graph_time_end !== this.props.graph_time_end ||
      prevProps.graph_interval_from !== this.props.graph_interval_from
    ) {
      const hiveMoved = this.isHiveMoved();

      if (hiveMoved) {
        this.enableTrajectory();
      } else {
        this.setState({
          trajectory: [],
        });

        if (!this.props.hive.coordinates) {
          this.setMapZoom(49.821094376846396, 15.490835755296175, 10);
          return;
        }

        this.setMapZoom(this.props.hive.coordinates.latitude, this.props.hive.coordinates.longitude, 17);
      }
    }
  }

  fetchTrajectory(opts?: Record<string, any>) {
    let startTime: any = this.props.graph_time_start;
    let endTime: any = this.props.graph_time_end;

    if (opts && opts.startTime && opts.endTime) {
      startTime = opts.startTime;
      endTime = opts.endTime;
    }

    if (this.props.graph_mode !== 'history') {
      const currentTime = moment();
      endTime = currentTime.toISOString();

      if (this.props.graph_interval_from) {
        startTime = this.props.graph_interval_from;
      } else {
        startTime = currentTime.subtract(this.props.graph_interval!! / 1000, 'seconds').toISOString();
      }
    }

    getTrajectory({
      uuid: this.props.hive.uuid,
      startTime: startTime,
      endTime: endTime,
      cb: (result: DeviceTelemetry, opts: Record<string, any>) => {
        if (opts.uuid !== this.props.hive.uuid) {
          return;
        }

        const oldTrajectory = opts.index > 0 ? this.state.trajectory : [];
        const newTrajectory = [...oldTrajectory, ...result.reverse()];

        if (opts.isLast) {
          this.setFitBounds(newTrajectory.map((item) => [item[2], item[1]]));
        }

        this.setState({
          trajectory: newTrajectory,
        });
      },
    }).catch((e) => {
      NotificationUtil.error('Neočekávaná chyba při načítání dat');
    });
  }

  isHiveMoved() {
    return (
      this.props.hive.status &&
      this.props.hive.status.tracking &&
      this.props.hive.status.tracking.geofencing &&
      this.props.hive.status.tracking.geofencing.breached_fences
    );
  }

  enableTrajectory = () => {
    this.fetchTrajectory();
  };

  setFitBounds(bounds: [lat: number, lng: number][]) {
    if (!this.mapRef || !this.mapRef.current) {
      return;
    }

    if (bounds.length <= 0) {
      if (!this.props.hive.coordinates) {
        this.setMapZoom(49.821094376846396, 15.490835755296175, 10);
        return;
      }

      this.setMapZoom(this.props.hive.coordinates.latitude, this.props.hive.coordinates.longitude, 17);
      return;
    }

    this.mapRef.current.fitBounds(bounds);
  }

  setMapZoom(lat: number, lon: number, zoom?: number) {
    if (this.mapRef && this.mapRef.current) {
      this.mapRef.current.setView([lat, lon], zoom || this.mapRef.current.getZoom());
    }
  }

  renderPopup() {
    const locationTemp = getSensorValue(
      SensorEnum.LOCATION_TEMPERATURE,
      this.props.telemetry,
      this.props.hive.sensors || [],
    );
    const battery = getSensorValue(SensorEnum.BATTERY_VOLTAGE, this.props.telemetry, this.props.hive.sensors || []);
    const weight = getSensorValue(SensorEnum.HIVE_WEIGHT, this.props.telemetry, this.props.hive.sensors || []);
    const weightGain = getSensorValue(SensorEnum.WEIGHT_GAIN, this.props.telemetry, this.props.hive.sensors || []);
    const weightGain2 = this.props.hive.weightGain;

    return (
      <Popup className={styles.markerPopup}>
        <div className={styles.popupTitle}>
          <strong>{this.props.hive.frontend_settings?.custom_name || ''}</strong>
        </div>
        <div className={styles.popupRow}>
          <strong>Teplota na stanovišti:</strong>
          <span>{locationTemp.formatValue}</span>
        </div>
        <div className={styles.popupRow}>
          <strong>Hmotnost úlu:</strong>
          <div>
            {weight.formatValue}{' '}
            <span style={{ fontSize: '14px' }}>
              (<span style={{ color: weightGain.color }}>{weightGain.formatValue}</span>
              {weightGain2 && <span style={{ color: weightGain2.color }}>{`, ${weightGain2.formatValue}`}</span>})
            </span>
          </div>
        </div>
        <div className={styles.popupRow}>
          <strong>Baterie:</strong>
          <Icon
            iconName={battery.icon as any}
            size={20}
            color={battery.color}
          />
        </div>
      </Popup>
    );
  }

  renderFabs() {
    return (
      <IonFab
        className={styles.fabButton}
        slot="fixed"
        vertical="bottom"
        horizontal="end"
      >
        <IonFabButton>
          <IonIcon icon={EllipsisVerticalIcon}></IonIcon>
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton
            onClick={this.enableTrajectory}
            data-desc="Zobrazit trajektorii"
          >
            <Icon
              iconName="Geo"
              color="dark"
            />
          </IonFabButton>
        </IonFabList>
      </IonFab>
    );
  }

  getBreachedFence(): BreachedFence | null {
    if (
      !this.props.hive.status ||
      !this.props.hive.status.tracking ||
      !this.props.hive.status.tracking.geofencing ||
      !this.props.hive.status.tracking.geofencing.breached_fences
    ) {
      return null;
    }

    const maxBreachLevel = this.props.hive.status.tracking.geofencing.max_breach_level;
    const breachedFences = this.props.hive.status.tracking.geofencing.breached_fences;
    const lastBreachedFenceIndex = breachedFences.findIndex((fence) => fence.breach_level === maxBreachLevel);

    if (lastBreachedFenceIndex < 0) {
      return null;
    }

    const lastBreachedFence = breachedFences[lastBreachedFenceIndex];

    if ([LocationLevel.MOVED_5M, LocationLevel.MOVED_50M].indexOf(lastBreachedFence.breach_level) < 0) {
      return null;
    }

    if (typeof lastBreachedFence.point === 'undefined') {
      return null;
    }

    if (typeof lastBreachedFence.radius === 'undefined') {
      lastBreachedFence.radius = lastBreachedFence.breach_level === LocationLevel.MOVED_5M ? 5 : 50;
    }

    return lastBreachedFence;
  }

  renderTrajectory() {
    if (this.state.trajectory.length <= 0) {
      return;
    }

    let hiveCoordinates: number[] | null = null;

    if (typeof this.props.hive.coordinates !== 'undefined') {
      hiveCoordinates = [this.props.hive.coordinates.latitude, this.props.hive.coordinates.longitude];
    }

    const trajectoryStart = this.state.trajectory[0];
    const trajectoryEnd = this.state.trajectory[this.state.trajectory.length - 1];
    let breachedFence: BreachedFence | null = this.getBreachedFence();

    return (
      <React.Fragment>
        <Polyline
          pathOptions={{ color: 'red' }}
          positions={this.state.trajectory.map((item) => [item[2], item[1]])}
        />
        {hiveCoordinates !== null && (
          <React.Fragment>
            {breachedFence !== null && (
              <Circle
                center={[breachedFence.point.latitude, breachedFence.point.longitude]}
                radius={breachedFence.radius}
                // Show previous level color
                color={LocationLevelColor[breachedFence.breach_level - 1]}
                pathOptions={{
                  weight: 2,
                  ...(breachedFence.breach_level === LocationLevel.MOVED_50M && { dashArray: '5, 10' }),
                }}
              />
            )}
          </React.Fragment>
        )}
        <CircleMarker
          center={[trajectoryStart[2], trajectoryStart[1]] as any}
          fillColor="#2AAD27"
          fillOpacity={1.0}
          color="white"
          stroke={true}
          radius={7}
        />
        <Marker
          position={[trajectoryEnd[2], trajectoryEnd[1]] as any}
          icon={HiveMovingMarker}
        ></Marker>
      </React.Fragment>
    );
  }

  renderMap() {
    const currentHive = this.props.hive;
    const currentLocation = this.props.location;
    var positionDefined = false;
    var position = [49.821094376846396, 15.490835755296175];
    var zoom = 10;

    // Try to set coordinates from hive
    if (currentHive.coordinates) {
      positionDefined = true;
      position = [currentHive.coordinates.latitude, currentHive.coordinates.longitude];
      zoom = 17;
    }

    // Try to set coordinates from location
    if (!positionDefined) {
      if (currentLocation.coordinates) {
        positionDefined = true;
        position = [currentLocation.coordinates.latitude, currentLocation.coordinates.longitude];
        zoom = 17;
      }
    }

    return (
      <MapContainer
        center={position as any}
        zoom={zoom}
        className={styles.mapContainer}
        ref={this.mapRef}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {positionDefined && (
          <Marker
            position={position as any}
            icon={HiveMarker}
            eventHandlers={{
              mouseover: (event) => event.target.openPopup(),
              mouseout: (event) => event.target.closePopup(),
            }}
          >
            {this.renderPopup()}
          </Marker>
        )}
        {this.renderTrajectory()}
      </MapContainer>
    );
  }

  render() {
    return (
      <div className={styles.mapTab}>
        {this.renderMap()}
        {this.renderFabs()}
      </div>
    );
  }
}

export default connect((state: StoreState) => ({
  graph_interval: state.graph_interval,
  graph_mode: state.graph_mode,
  graph_time_start: state.graph_time_start,
  graph_time_end: state.graph_time_end,
  graph_interval_from: state.graph_interval_from,
  graph_time: state.graph_time,
}))(MapTab);
