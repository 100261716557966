import { Capacitor } from '@capacitor/core';
import { IonContent, IonItem, IonLabel, IonList, IonPage } from '@ionic/react';
import { clearCachedData } from '@lib/cache';
import { History } from 'history';
import React from 'react';
import Header from '../../components/Header';
import withHistory from '../../components/HOC/withHistory';
import { MenuItem } from '../../components/typeDefs';
import * as pushNotifications from '../../native/pushNotifications';
import { storageSet } from '../../storage';
import store from '../../store';

const pageTitle = 'Home';

const menuItems: Array<MenuItem> = [
  // {
  //   title: 'Podpora',
  //   url: '/tickets'
  // },
  // {
  //   title: 'Nastavení',
  //   url: '/settings'
  // },
  // {
  //   title: 'FAQ',
  //   url: '/faq'
  // }
];

export interface MobileMenuProps {
  history?: History;
}

class MobileMenu extends React.Component<MobileMenuProps> {
  constructor(props: MobileMenuProps) {
    super(props);

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
  }

  async handleLogoutClick() {
    // Set to store
    store.dispatch({
      type: 'set',
      payload: {
        accessToken: null,
        refreshToken: null,
        isPublic: false,
      },
    });

    // Set to storage
    await storageSet('refreshToken', null);
    await storageSet('accessToken', null);
    await storageSet('isPublic', false);

    if (Capacitor.isPluginAvailable('PushNotifications')) {
      await pushNotifications.removeListeners();
    }

    await clearCachedData();

    this.props.history?.push('/login');
  }

  render() {
    return (
      <IonPage>
        <Header title={pageTitle} />
        <IonContent>
          <IonList lines="full">
            {menuItems.map((menuItem) => {
              return (
                <IonItem routerLink={menuItem.url}>
                  <IonLabel>{menuItem.title}</IonLabel>
                </IonItem>
              );
            })}
            <IonItem onClick={this.handleLogoutClick}>
              <IonLabel>Odhlásit se</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}

export default withHistory(MobileMenu);
