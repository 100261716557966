import * as React from 'react';
import styles from './styles.module.css';

interface HiveBodyItemProps extends React.PropsWithChildren {}
interface HiveBodyItemState {}

export class HiveBodyItem extends React.Component<HiveBodyItemProps, HiveBodyItemState> {
  render() {
    return <div className={styles.hiveBodyItem}>{this.props.children}</div>;
  }
}

export default HiveBodyItem;
