import config from '@config';
import { ChartOptions, ChartType, ScaleOptions, Tooltip, TooltipPosition, TooltipPositionerFunction } from 'chart.js';
import { cs } from 'date-fns/locale';
import moment from 'moment-timezone';

// Custom tooltip positioner, which sets tooltip position to cursor position
const cursor: TooltipPositionerFunction<ChartType> = function (_items, eventPosition): TooltipPosition | false {
  return {
    x: eventPosition.x,
    y: eventPosition.y,
  };
};

(Tooltip.positioners as any).cursor = cursor;

export const defaultOptions: ChartOptions<'line'> = {
  locale: config.locale,
  spanGaps: config.chart.spanGaps * 1000,
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0,
      hitRadius: 4,
    },
  },
  interaction: {
    mode: 'x' as const,
    intersect: false,
  },
  plugins: {
    tooltip: {
      mode: 'nearest' as const,
      axis: 'x' as const,
      position: 'cursor' as any,
    },
    zoom: {
      zoom: {
        // Zoom by mouse wheel
        wheel: {
          enabled: true,
          modifierKey: 'ctrl' as const, // Hold CTRL key to zoom by wheel
        },
        // Drag to zoom
        drag: {
          enabled: true,
          threshold: 10,
          backgroundColor: 'rgba(150, 150, 150, 0.3)',
          borderColor: 'rgba(150, 150, 150)',
          borderWidth: 1,
        },
        // Pinch to zoom
        pinch: {
          enabled: true,
        },
        mode: 'x' as const,
      },
      pan: {
        enabled: false,
        mode: 'x' as const,
      },
      limits: {
        x: {
          max: moment().valueOf(),
        },
      },
    },
  },
  animation: {
    duration: 0, // Animation time (0 = animation disabled)
  },
};

export const defaultXAxisOptions: ScaleOptions<'time'> = {
  type: 'time' as const,
  time: {
    tooltipFormat: 'dd.MM.yyyy HH:mm:ss',
    minUnit: 'minute' as const,
    displayFormats: {
      millisecond: 'HH:mm',
      second: 'HH:mm',
      minute: 'HH:mm',
      hour: 'd MMM HH:mm',
      day: 'd MMM',
      week: 'MMM yyyy',
      month: 'MMM yyyy',
      quarter: 'MMM yyyy',
      year: 'MMM yyyy',
    },
  },
  adapters: {
    date: {
      locale: cs,
    },
  },
  ticks: {
    source: 'auto' as const,
    autoSkip: false,
    maxTicksLimit: 10,
  },
};

export const defaultYAxisOptions: ScaleOptions<'linear'> = {
  display: 'auto' as const,
  grace: '10%' as const,
  type: 'linear' as const,
  grid: {
    drawOnChartArea: false,
  },
  ticks: {
    precision: 2,
  },
};
