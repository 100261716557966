import { Capacitor } from '@capacitor/core';
import withHistory from '@components/HOC/withHistory';
import { MenuItem } from '@components/typeDefs';
import {
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonToolbar,
} from '@ionic/react';
import { clearCachedData } from '@lib/cache';
import { History, Location } from 'history';
import {
  chevronBackOutline,
  homeOutline,
  homeSharp,
  logOutOutline,
  logOutSharp,
  personOutline,
  personSharp,
} from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as pushNotifications from '../../native/pushNotifications';
import { storageSet } from '../../storage';
import store, { StoreState } from '../../store';
import withLocation from '../HOC/withLocation';
import './styles.css';

interface SideMenuProps {
  location: Location;
  history?: History;
  isPublic?: boolean;
}

const headerMenuItems: Array<MenuItem> = [
  {
    title: 'Přehled',
    url: '/home',
    icons: {
      iosIcon: homeOutline,
      mdIcon: homeSharp,
    },
  },
  // {
  //     title: 'Notifikace',
  //     url: '/notifications',
  //     icons: {
  //         iosIcon: notificationsOutline,
  //         mdIcon: notificationsSharp
  //     }
  // },
  // {
  //     title: 'Podpora',
  //     url: '/tickets',
  //     icons: {
  //         iosIcon: chatbubblesOutline,
  //         mdIcon: chatbubblesSharp
  //     }
  // },
  {
    title: 'Profil',
    url: '/profile',
    icons: {
      iosIcon: personOutline,
      mdIcon: personSharp,
    },
  },
];

const footerMenuItems: Array<MenuItem> = [
  // {
  //     title: 'Stáhnout pro Android',
  //     url: '#',
  //     icons: {
  //         iosIcon: logoAndroid,
  //         mdIcon: logoAndroid
  //     },
  //     download: true,
  // },
  // {
  //     title: 'Nastavení',
  //     url: '/settings',
  //     icons: {
  //         iosIcon: settingsOutline,
  //         mdIcon: settingsSharp
  //     }
  // },
  // {
  //     title: 'FAQ',
  //     url: '/faq',
  //     icons: {
  //         iosIcon: helpCircleOutline,
  //         mdIcon: helpCircleSharp
  //     }
  // }
];

class SideMenu extends React.Component<SideMenuProps> {
  constructor(props: SideMenuProps) {
    super(props);

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
  }

  async handleLogoutClick() {
    // Set to store
    store.dispatch({
      type: 'set',
      payload: {
        accessToken: null,
        refreshToken: null,
        isPublic: false,
      },
    });

    // Set to storage
    await storageSet('refreshToken', null);
    await storageSet('accessToken', null);
    await storageSet('isPublic', false);

    if (Capacitor.isPluginAvailable('PushNotifications')) {
      await pushNotifications.removeListeners();
    }

    await clearCachedData();

    this.props.history?.push('/login');
  }

  render() {
    return (
      <IonMenu
        contentId="main"
        swipeGesture={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonMenuButton>
                <IonIcon icon={chevronBackOutline} />
              </IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {headerMenuItems
              .filter(
                (menuItem) => (menuItem.url !== '/profile' && menuItem.url === '/tickets') || !this.props.isPublic,
              )
              .map((menuItem, index) => (
                <IonMenuToggle
                  key={index}
                  autoHide={false}
                >
                  <IonItem
                    className={this.props.location.pathname === menuItem.url ? 'selected' : ''}
                    routerLink={menuItem.url}
                    lines="none"
                  >
                    {menuItem.icons && (
                      <IonIcon
                        slot="start"
                        ios={menuItem.icons.iosIcon}
                        md={menuItem.icons.mdIcon}
                      />
                    )}
                    <IonLabel>{menuItem.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              ))}
          </IonList>
        </IonContent>
        <IonFooter>
          <IonList>
            {footerMenuItems.map((menuItem, index) => {
              if (!!menuItem.download) {
                return (
                  <IonMenuToggle
                    key={index}
                    autoHide={false}
                  >
                    <IonItem
                      href="https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png"
                      download="apivcelar"
                      target="_blank"
                      lines="none"
                    >
                      {menuItem.icons && (
                        <IonIcon
                          slot="start"
                          ios={menuItem.icons.iosIcon}
                          md={menuItem.icons.mdIcon}
                        />
                      )}
                      <IonLabel>{menuItem.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              }

              return (
                <IonMenuToggle
                  key={index}
                  autoHide={false}
                >
                  <IonItem
                    className={this.props.location.pathname === menuItem.url ? 'selected' : ''}
                    routerLink={menuItem.url}
                    lines="none"
                  >
                    {menuItem.icons && (
                      <IonIcon
                        slot="start"
                        ios={menuItem.icons.iosIcon}
                        md={menuItem.icons.mdIcon}
                      />
                    )}
                    <IonLabel>{menuItem.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
            <IonMenuToggle
              autoHide={false}
              onClick={this.handleLogoutClick}
            >
              <IonItem lines="none">
                <IonIcon
                  slot="start"
                  ios={logOutOutline}
                  md={logOutSharp}
                />
                <IonLabel>Odhlásit se</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        </IonFooter>
      </IonMenu>
    );
  }
}

export default compose(
  withHistory,
  withLocation,
  connect((state: StoreState) => ({
    isPublic: state.isPublic,
  })),
)(SideMenu);
