import { DeviceComplete } from '@api/models/DeviceModel';
import { Location, LocationJournalResponse } from '@api/models/LocationModel';
import { User } from '@api/models/UserModel';
import NavButton from '@components/NavButton';
import getJournal from '@lib/data/Device/getJournal';
import getTelemetry from '@lib/data/Device/getTelemetry';
import { GetTelemetryResult } from '@lib/data/Device/typeDefs';
import store, { StoreState } from '@src/store';
import NotificationUtil from '@util/Notification';
import { Chart as ChartJS } from 'chart.js';
import {
  browsersOutline as BrowsersIcon,
  mapOutline as MapIcon,
  settings as SettingsIcon,
  statsChartOutline as ChartIcon,
} from 'ionicons/icons';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import ChartTab from './ChartTab';
import Journal from './Journal';
import MapTab from './MapTab';
import SettingsTab from './SettingsTab';
import styles from './styles.module.css';
import TableTab from './TableTab';
import { MainContentTabs } from './typeDefs';

interface MainContentProps {
  graph_time?: boolean;
  graph_interval?: number;
  graph_mode?: string;
  graph_time_start?: string | null;
  graph_time_end?: string | null;
  graph_interval_from?: string | null;
  hive: DeviceComplete;
  location: Location;
  user: User;
  devices: DeviceComplete[];
  locations: Location[];
  telemetry: any;
  latestTelemetry: any[];
  onHiveUpdate?: () => void;
  onHiveDelete?: () => void;
  onHiveDeviceUpdate?: () => void;
  onJournalUpdate?: () => void;
  onUserUpdate?: () => void;
}

interface MainContentState {
  sensorTelemetry: any;
  sensorTelemetryUpdate: number;
  selectedTab: MainContentTabs;
  journal: LocationJournalResponse[];
  journalDate?: number;
}

class MainContent extends React.Component<MainContentProps, MainContentState> {
  private chart: ChartJS<'line'> | undefined | null = null;

  constructor(props: MainContentProps) {
    super(props);

    this.handleNavButtonClick = this.handleNavButtonClick.bind(this);
    this.handleZoomComplete = this.handleZoomComplete.bind(this);
    this.handleJournalUpdate = this.handleJournalUpdate.bind(this);
    this.handleChartClick = this.handleChartClick.bind(this);

    this.state = {
      sensorTelemetry: {},
      sensorTelemetryUpdate: Date.now(),
      selectedTab: MainContentTabs.GRAPH,
      journal: [],
    };
  }

  componentDidMount() {
    this.fetchTelemetry();
    this.fetchJournal();
  }

  componentDidUpdate(prevProps: MainContentProps) {
    if (
      prevProps.hive.uuid !== this.props.hive.uuid ||
      prevProps.graph_interval !== this.props.graph_interval ||
      prevProps.graph_mode !== this.props.graph_mode ||
      prevProps.graph_time !== this.props.graph_time ||
      prevProps.graph_time_start !== this.props.graph_time_start ||
      prevProps.graph_time_end !== this.props.graph_time_end ||
      prevProps.graph_interval_from !== this.props.graph_interval_from
    ) {
      this.fetchTelemetry();
      this.fetchJournal();
    }
  }

  handleJournalUpdate() {
    this.fetchJournal();

    if (this.props.onJournalUpdate) {
      this.props.onJournalUpdate();
    }
  }

  fetchJournal(opts?: Record<string, any>) {
    let startTime: any = this.props.graph_time_start;
    let endTime: any = this.props.graph_time_end;

    if (opts && opts.startTime && opts.endTime) {
      startTime = opts.startTime;
      endTime = opts.endTime;
    }

    if (this.props.graph_mode !== 'history') {
      const currentTime = moment();
      endTime = currentTime.toISOString();

      if (this.props.graph_interval_from) {
        startTime = this.props.graph_interval_from;
      } else {
        startTime = currentTime.subtract(this.props.graph_interval!! / 1000, 'seconds').toISOString();
      }
    }

    getJournal({
      uuid: this.props.hive.uuid,
      startTime: startTime,
      endTime: endTime,
      cb: (result: LocationJournalResponse[], opts: Record<string, any>) => {
        if (opts.uuid !== this.props.hive.uuid) {
          return;
        }

        const oldJournal = opts.index > 0 ? this.state.journal : [];

        this.setState({
          journal: [...result, ...oldJournal],
        });
      },
    }).catch((e) => {
      NotificationUtil.error('Neočekávaná chyba při načítání dat');
    });
  }

  fetchTelemetry(opts?: Record<string, any>) {
    let startTime: any = this.props.graph_time_start;
    let endTime: any = this.props.graph_time_end;

    if (opts && opts.startTime && opts.endTime) {
      startTime = opts.startTime;
      endTime = opts.endTime;
    }

    if (this.props.graph_mode !== 'history') {
      const currentTime = moment();
      endTime = currentTime.toISOString();

      if (this.props.graph_interval_from) {
        startTime = this.props.graph_interval_from;
      } else {
        startTime = currentTime.subtract(this.props.graph_interval!! / 1000, 'seconds').toISOString();
      }
    }

    getTelemetry({
      uuid: this.props.hive.uuid,
      startTime: startTime,
      endTime: endTime,
      maxRequestTime: this.props.hive.frontend_settings?.max_req_timewindow,
      cb: (result: GetTelemetryResult, opts: Record<string, any>) => {
        if (opts.uuid !== this.props.hive.uuid) {
          return;
        }

        const oldSensorTelemetry = opts.index > 0 ? this.state.sensorTelemetry : {};
        const newSensorTelemetry: any = oldSensorTelemetry;

        for (const sensorNumber in result) {
          const newData = result[sensorNumber];
          const sensorData = [];

          if (oldSensorTelemetry[sensorNumber]) {
            sensorData.push(...oldSensorTelemetry[sensorNumber]);
          }

          sensorData.push(...newData);
          newSensorTelemetry[sensorNumber] = sensorData;
        }

        this.setState({
          sensorTelemetry: newSensorTelemetry,
          sensorTelemetryUpdate: Date.now(),
        });
      },
    }).catch((e) => {
      NotificationUtil.error('Neočekávaná chyba při načítání dat');
    });
  }

  handleZoomComplete(chart: any) {
    const startTime = moment(chart.scales.x.min).toISOString();
    const endTime = moment(chart.scales.x.max).toISOString();

    const data: any = {
      graph_mode: 'history',
      graph_time: true,
      graph_time_start: startTime,
      graph_time_end: endTime,
    };

    store.dispatch({
      type: 'set',
      payload: data,
    });
  }

  handleChartClick(data: any) {
    this.setState({ journalDate: data.x });
  }

  handleNavButtonClick(e: any, key: any) {
    this.setState({
      selectedTab: key,
    });
  }

  renderNavbar() {
    return (
      <div className={styles.navbar}>
        <NavButton
          btnKey={MainContentTabs.GRAPH}
          icon={ChartIcon}
          text="Graf"
          onClick={this.handleNavButtonClick}
          value={this.state.selectedTab}
        />
        <NavButton
          btnKey={MainContentTabs.TABLE}
          icon={BrowsersIcon}
          text="Tabulka"
          onClick={this.handleNavButtonClick}
          value={this.state.selectedTab}
        />
        <NavButton
          btnKey={MainContentTabs.MAP}
          icon={MapIcon}
          text="Mapa"
          onClick={this.handleNavButtonClick}
          value={this.state.selectedTab}
        />
        <NavButton
          btnKey={MainContentTabs.SETTINGS}
          icon={SettingsIcon}
          onClick={this.handleNavButtonClick}
          value={this.state.selectedTab}
          className={styles.settingsNavButton}
        />
      </div>
    );
  }

  renderContent() {
    switch (this.state.selectedTab) {
      case MainContentTabs.GRAPH:
        return (
          <ChartTab
            hive={this.props.hive}
            user={this.props.user}
            sensorTelemetry={this.state.sensorTelemetry}
            sensorTelemetryUpdate={this.state.sensorTelemetryUpdate}
            onZoomComplete={this.handleZoomComplete}
            onChartClick={this.handleChartClick}
            onUserUpdate={this.props.onUserUpdate}
          />
        );
      case MainContentTabs.TABLE:
        return (
          <TableTab
            hive={this.props.hive}
            location={this.props.location}
            sensorTelemetry={this.state.sensorTelemetry}
            user={this.props.user}
          />
        );
      case MainContentTabs.MAP:
        return (
          <MapTab
            hive={this.props.hive}
            location={this.props.location}
            telemetry={this.props.telemetry}
          />
        );
      case MainContentTabs.SETTINGS:
        return (
          <SettingsTab
            hive={this.props.hive}
            location={this.props.location}
            devices={this.props.devices}
            locations={this.props.locations}
            latestTelemetry={this.props.latestTelemetry}
            onHiveUpdate={this.props.onHiveUpdate}
            onHiveDelete={this.props.onHiveDelete}
            onHiveDeviceUpdate={this.props.onHiveDeviceUpdate}
          />
        );
    }
  }

  renderJournal() {
    return (
      <Journal
        hive={this.props.hive}
        journal={this.state.journal}
        date={this.state.journalDate}
        onJournalUpdate={this.handleJournalUpdate}
      />
    );
  }

  render() {
    return (
      <div className={styles.mainContent}>
        {this.renderNavbar()}
        {this.renderContent()}
        {this.renderJournal()}
      </div>
    );
  }
}

export default connect((state: StoreState) => ({
  graph_time: state.graph_time,
  graph_interval: state.graph_interval,
  graph_mode: state.graph_mode,
  graph_time_start: state.graph_time_start,
  graph_time_end: state.graph_time_end,
  graph_interval_from: state.graph_interval_from,
}))(MainContent);
