import {
  Location,
  LocationRequest,
  LocationJournalResponse,
  LocationJournalCreateRequest,
  LocationJournalUpdateRequest,
} from '@api/models/LocationModel';
import { Permissions } from '@api/models/DeviceModel';
import apiConfig from '@api/core/config';
import request from '@api/core/request';

export class LocationService {
  /**
   * Returns a list of the user's locations and allows to create a new one. Supprots GET and POST.
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Array of locations
   * @throws ApiError
   */
  public static locationsList(forceUpdate?: boolean): Promise<Array<Location>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/locations/',
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Returns a list of the user's locations and allows to create a new one. Supprots GET and POST.
   * @param requestBody
   * @returns Location
   * @throws ApiError
   */
  public static locationsCreate(requestBody?: LocationRequest): Promise<Location> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/locations/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns a list of the user's locations and allows to create a new one. Supprots GET and POST.
   * @param id
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Location
   * @throws ApiError
   */
  public static locationsRetrieve(id: number, forceUpdate?: boolean): Promise<Location> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/locations/{id}/',
      path: {
        id,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Returns a list of the user's locations and allows to create a new one. Supprots GET and POST.
   * @param id
   * @param requestBody
   * @returns Location
   * @throws ApiError
   */
  public static locationsUpdate(id: number, requestBody?: LocationRequest): Promise<Location> {
    return request(apiConfig, {
      method: 'PUT',
      url: '/apiarist/api/locations/{id}/',
      path: {
        id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns a list of the user's locations and allows to create a new one. Supprots GET and POST.
   * @param id
   * @throws ApiError
   */
  public static locationsDestroy(id: number): Promise<void> {
    return request(apiConfig, {
      method: 'DELETE',
      url: '/apiarist/api/locations/{id}/',
      path: {
        id,
      },
    });
  }

  /**
   * Returns a list of the user's 20 latest location journal records.
   * @param id
   * @param forceUpdate fetch data from network (ignore cache)
   * @returns Array of journal records
   * @throws ApiError
   */
  public static journalRecordList3(id: number, forceUpdate?: boolean): Promise<Array<LocationJournalResponse>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/locations/{id}/journal/',
      path: {
        id,
      },
      cache: {
        enabled: true,
        forceUpdate: !!forceUpdate,
      },
    });
  }

  /**
   * Creates a new journal record.
   * @param id
   * @param requestBody
   * @returns Created journal record
   * @throws ApiError
   */
  public static journalRecordCreate(
    id: number,
    requestBody: LocationJournalCreateRequest,
  ): Promise<LocationJournalResponse> {
    return request(apiConfig, {
      method: 'POST',
      url: '/apiarist/api/locations/{id}/journal/',
      path: {
        id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the requested user's location journal record and allows to update and delete one.
   * The record is indentified by its timestamp. The timestamp can be eighter an integer timestamp or a floating-point timestamp,
   * or a date-time in YYYY-MM-DDTHH:MM:SS format.
   * @param id
   * @param timestamp
   * @returns Journal record
   * @throws ApiError
   */
  public static journalRecordGet2(id: number, timestamp: string): Promise<LocationJournalResponse> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/locations/{id}/journal/{timestamp}/',
      path: {
        id,
        timestamp,
      },
    });
  }

  /**
   * Updates a journal record.
   * @param id
   * @param timestamp
   * @param requestBody
   * @returns Updated journal record
   * @throws ApiError
   */
  public static journalRecordUpdate(
    id: number,
    timestamp: string,
    requestBody: LocationJournalUpdateRequest,
  ): Promise<LocationJournalResponse> {
    return request(apiConfig, {
      method: 'PUT',
      url: '/apiarist/api/locations/{id}/journal/{timestamp}/',
      path: {
        id,
        timestamp,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Deletes a journal record. Requires the edit_journal permission.
   * @param id
   * @param timestamp
   * @throws ApiError
   */
  public static journalRecordDelete2(id: number, timestamp: string): Promise<void> {
    return request(apiConfig, {
      method: 'DELETE',
      url: '/apiarist/api/locations/{id}/journal/{timestamp}/',
      path: {
        id,
        timestamp,
      },
    });
  }

  /**
   * Returns a list of users with access to the location and their permissions.
   *
   * List of valid permissions:
   * - view_telemetry - can see/donwload the telemetry
   * - get_notifications - can receive notifications from the devices at the location
   * - control - can change the user configuration and frontend settings
   * - read_journal - can read the journal
   * - add_to_journal - can read and add new records to the journal but cannot edit or delete existing records
   * - edit_journal - can read, add, edit and delete records from the journal
   * - all - the user is the owner of the location and has all permissions
   * @param id
   * @returns Permissions
   * @throws ApiError
   */
  public static locationsPermissionsList(id: number): Promise<Array<Permissions>> {
    return request(apiConfig, {
      method: 'GET',
      url: '/apiarist/api/locations/{id}/permissions/',
      path: {
        id,
      },
    });
  }

  /**
   * To the owner of the location, this endpoint alows to update the permissions by sending a list of updated permissions.
   * It is allowed to send a list of multiple users with updated permissions.
   * The users that are not in the list will have their permissions left intact.
   * The affetced users will have their original set of permissions replaced by the presented new list.
   * If an empty list of permissions for any given user in the list is presented,
   * all of the user's permissions will be removed and they will lose access to the location.
   *
   * List of valid pemrissions:
   * - view_telemetry - can see/donwload the telemetry
   * - get_notifications - can receive notifications from the devices at the location
   * - control - can change the user configuration and frontend settings
   * - read_journal - can read the journal
   * - add_to_journal - can read and add new records to the journal but cannot edit or delete existing records
   * - edit_journal - can read, add, edit and delete records from the journal
   * - all - the user is the owner of the device and has all permissions
   *
   * Only one of the three journal permissions should be provided at a time. If more are provided, the highest of them is accepted.
   * @param id
   * @param requestBody
   * @returns Updated permissions
   * @throws ApiError
   */
  public static locationPermissionsUpdate(id: number, requestBody: Array<Permissions>): Promise<Array<Permissions>> {
    return request(apiConfig, {
      method: 'PUT',
      url: '/apiarist/api/locations/{id}/permissions/',
      path: {
        id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}

export default LocationService;
