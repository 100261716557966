import { DeviceComplete } from '@api/models/DeviceModel';
import { Location } from '@api/models/LocationModel';
import { User } from '@api/models/UserModel';
import DeviceService from '@api/services/DeviceService';
import LocationService from '@api/services/LocationService';
import UserService from '@api/services/UserService';
import Header from '@components/Header';
import withHistory from '@components/HOC/withHistory';
import withParams from '@components/HOC/withParams';
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import getWeightGain from '@lib/data/Device/getWeightGain';
import NotificationUtil from '@util/Notification';
import { History } from 'history';
import { closeOutline as CloseIcon } from 'ionicons/icons';
import * as React from 'react';
import { compose } from 'redux';
import LeftSidebar from './LeftSidebar';
import MainContent from './MainContent';
import RightSidebar from './RightSidebar';
import styles from './styles.module.css';

interface HiveDetailProps {
  history: History;
  params: Record<string, any>;
}

interface HiveDetailState {
  hive: DeviceComplete | null;
  location: Location | null;
  user: User | null;
  devices: DeviceComplete[];
  locations: Location[];
  latestTelemetry: any[];
}

export class HiveDetail extends React.Component<HiveDetailProps, HiveDetailState> {
  constructor(props: HiveDetailProps) {
    super(props);

    this.handleHiveUpdate = this.handleHiveUpdate.bind(this);
    this.handleHiveDelete = this.handleHiveDelete.bind(this);
    this.handleHiveDeviceUpdate = this.handleHiveDeviceUpdate.bind(this);
    this.handleJournalUpdate = this.handleJournalUpdate.bind(this);
    this.handleUserUpdate = this.handleUserUpdate.bind(this);

    this.state = {
      hive: null,
      location: null,
      user: null,
      devices: [],
      locations: [],
      latestTelemetry: [],
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async componentDidUpdate(prevProps: HiveDetailProps) {
    if (this.props.params.uuid !== prevProps.params.uuid) {
      await this.fetchData({ update: true });
    }
  }

  isGroupItem(hive: DeviceComplete, devices: DeviceComplete[]) {
    if (!hive.consist_of) {
      return false;
    }

    for (const connectedId of hive.consist_of) {
      if (
        devices.findIndex(
          (device) => device.consist_of && device.consist_of.indexOf(connectedId) >= 0 && hive.uuid !== device.uuid,
        ) >= 0
      ) {
        return true;
      }
    }

    return false;
  }

  async handleUserUpdate() {
    try {
      const user = await UserService.userRetrieve(true);

      this.setState({
        user,
      });
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při načítání dat');
    }
  }

  async handleJournalUpdate() {
    try {
      const devices = await DeviceService.devicesListComplete(true);
      this.fetchWeightGain(devices);

      // Set current hive
      let currentHive: DeviceComplete | null = null;
      const currentHiveIndex = devices.findIndex((device) => device.uuid === this.props.params.uuid);

      if (currentHiveIndex >= 0) {
        currentHive = devices[currentHiveIndex];
        currentHive.isGroupItem = this.isGroupItem(currentHive, devices);
      }

      this.setState({
        hive: currentHive,
        devices,
      });
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při načítání dat');
    }
  }

  async handleHiveUpdate() {
    try {
      const devices = await DeviceService.devicesListComplete(true);
      const locations = this.state.locations;
      this.fetchWeightGain(devices);

      // Set current hive
      let currentHive: DeviceComplete | null = null;
      let currentLocation: Location | null = null;
      const currentHiveIndex = devices.findIndex((device) => device.uuid === this.props.params.uuid);

      if (currentHiveIndex >= 0) {
        currentHive = devices[currentHiveIndex];
        currentHive.isGroupItem = this.isGroupItem(currentHive, devices);

        // Set current location
        const currentLocationIndex = locations.findIndex(
          (location) => location.id === ((currentHive as DeviceComplete).location || -1),
        );

        if (currentLocationIndex >= 0) {
          currentLocation = locations[currentLocationIndex];
        }
      }

      this.setState({
        hive: currentHive,
        location: currentLocation,
        devices,
      });
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při načítání dat');
    }
  }

  async handleHiveDelete() {
    try {
      await DeviceService.devicesListComplete(true);
      this.props.history.push('/');
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při načítání dat');
    }
  }

  async handleHiveDeviceUpdate() {
    try {
      const devices = await DeviceService.devicesListComplete(true);
      const latestTelemetry = await DeviceService.devicesListLatestTelemetry(true);
      this.fetchWeightGain(devices);

      // Set current hive
      let currentHive: DeviceComplete | null = null;
      const currentHiveIndex = devices.findIndex((device) => device.uuid === this.props.params.uuid);

      if (currentHiveIndex >= 0) {
        currentHive = devices[currentHiveIndex];
        currentHive.isGroupItem = this.isGroupItem(currentHive, devices);
      }

      this.setState({
        hive: currentHive,
        latestTelemetry,
        devices,
      });
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při načítání dat');
    }
  }

  async getLocations(force: boolean = false) {
    const locations = await LocationService.locationsList(force);

    // Add location for HiveDetails without any location
    locations.push({
      id: -1,
      type: '',
      name: 'Bez stanoviště',
    });

    return locations;
  }

  getHiveList() {
    return this.state.devices.filter((device: DeviceComplete) => !!device.composite);
  }

  getHiveTelemetry(hiveId: string) {
    const hiveTelemetryIndex = this.state.latestTelemetry.findIndex((item) => item.length >= 2 && item[0] === hiveId);

    if (hiveTelemetryIndex < 0) {
      return [];
    }

    return this.state.latestTelemetry[hiveTelemetryIndex][1];
  }

  async fetchWeightGain(devices: DeviceComplete[]) {
    return;
    for (const device of devices) {
      device.weightGain = await getWeightGain(device);
    }

    this.setState({
      devices,
    });
  }

  async fetchData({ update = false } = {}) {
    try {
      const devices = await DeviceService.devicesListComplete();
      const latestTelemetry = await DeviceService.devicesListLatestTelemetry();
      const locations = await this.getLocations();
      const user = await UserService.userRetrieve(true);

      if (!update) {
        this.fetchWeightGain(devices);
      } else {
        for (const device of devices) {
          const deviceIndex = this.state.devices.findIndex((d) => d.uuid === device.uuid);

          if (deviceIndex >= 0) {
            devices[deviceIndex].weightGain = this.state.devices[deviceIndex].weightGain;
          }
        }
      }

      // Set current hive
      let currentHive: DeviceComplete | null = null;
      let currentLocation: Location | null = null;
      const currentHiveIndex = devices.findIndex((device) => device.uuid === this.props.params.uuid);

      // Redirect to home page if hive not found
      if (currentHiveIndex < 0) {
        this.props.history.push('/');
        return;
      }

      currentHive = devices[currentHiveIndex];
      currentHive.isGroupItem = this.isGroupItem(currentHive, devices);

      // Set current location
      const currentLocationIndex = locations.findIndex(
        (location) => location.id === ((currentHive as DeviceComplete).location || -1),
      );

      if (currentLocationIndex >= 0) {
        currentLocation = locations[currentLocationIndex];
      }

      this.setState({
        hive: currentHive,
        location: currentLocation,
        user,
        locations,
        latestTelemetry,
        devices,
      });
    } catch (e) {
      NotificationUtil.error('Neočekávaná chyba při načítání dat');
    }
  }

  render() {
    return (
      <IonPage>
        <Header
          title="Detaily úlu"
          customButton={{
            icon: CloseIcon,
            onClick: () => {
              this.props.history.push('/');
            },
          }}
        />
        <IonContent>
          <div className={styles.container}>
            <IonGrid className={styles.gridContainer}>
              <IonRow className={styles.rowContainer}>
                <IonCol
                  size="12"
                  sizeMd="5"
                  sizeXl="3"
                  className={styles.colContainer}
                >
                  {this.state.hive && this.state.location && (
                    <LeftSidebar
                      hive={this.state.hive}
                      devices={this.state.devices}
                      location={this.state.location}
                      telemetry={this.getHiveTelemetry(this.state.hive.uuid)}
                      onStateUpdate={this.handleHiveUpdate}
                    />
                  )}
                </IonCol>
                <IonCol
                  size="12"
                  sizeMd="7"
                  sizeXl="6"
                  className={styles.colContainer}
                >
                  {this.state.hive && this.state.location && this.state.user && (
                    <MainContent
                      hive={this.state.hive}
                      location={this.state.location}
                      user={this.state.user}
                      devices={this.state.devices}
                      locations={this.state.locations}
                      telemetry={this.getHiveTelemetry(this.state.hive.uuid)}
                      latestTelemetry={this.state.latestTelemetry}
                      onHiveUpdate={this.handleHiveUpdate}
                      onHiveDelete={this.handleHiveDelete}
                      onHiveDeviceUpdate={this.handleHiveDeviceUpdate}
                      onJournalUpdate={this.handleJournalUpdate}
                      onUserUpdate={this.handleUserUpdate}
                    />
                  )}
                </IonCol>
                <IonCol
                  size="3"
                  className={`${styles.colContainer} ion-hide-xl-down`}
                >
                  {this.state.hive && (
                    <RightSidebar
                      locationList={this.state.locations}
                      latestTelemetry={this.state.latestTelemetry}
                      hiveList={this.getHiveList()}
                      devices={this.state.devices}
                      hive={this.state.hive}
                    />
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default compose(withHistory, withParams)(HiveDetail);
